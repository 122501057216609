/**
 * A centralized class that provides useful conversions for a various amount of data
 */
export namespace Conversions {
  /**
   * Base conversion math functions
   */
  class Base {
    static divide(val1: number, val2: number) {
      // TODO: Handle floating point precision issues?
      return val1 / val2;
    }

    static multiply(val1: number, val2: number) {
      // TODO: Handle floating point precision issues?
      return val1 * val2;
    }
  }

  /** Trig related conversions */
  export class Trigonometry {
    /** Converts the given degrees value to radians */
    static degreesToRadians(degrees: number) {
      return degrees * (Math.PI / 180);
    }

    /** Converts the given radians value to degrees */
    static radiansToDegrees(degrees: number) {
      return degrees * (180 / Math.PI);
    }
  }

  /**
   * Computing related conversions
   */
  export class Computing {
    static gigabyteToByte(gb: number) {
      return Base.multiply(gb, 1000000000);
    }

    static byteToMegabyte(byte: number) {
      return Base.divide(byte, 1000000);
    }

    static megabyteToByte(megabyte: number) {
      return Base.multiply(megabyte, 1000000);
    }

    static megabyteToGigabyte(megabyte: number) {
      return Base.divide(megabyte, 1000);
    }

    static megabyteToTerabyte(megabyte: number) {
      return Base.divide(megabyte, 1000000);
    }

    static megabyteToPetabyte(megabyte: number) {
      return Base.divide(megabyte, 1000000000);
    }
  }
}
