import { Injectable } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Store } from "@ngrx/store";
import {
  AudioConfig,
  AudioTopics,
  Bookmark,
  BookmarkType,
  DataStoreFile,
  DataStoreFileTypes,
  DataStoreUploadStatus,
  Session,
  TDMSWebSocketMessage,
  Transcription,
  TranscriptionEdit,
  TranscriptionRefresh,
  WebSocketCommunication,
} from "@tdms/common";
import { BackgroundAudioComponent } from "@tdms/frontend/modules/audio/components/background-audio/background-audio.component";
import { selectAllTranscriptionsFromState } from "@tdms/frontend/modules/audio/store/transcription.selector";
import { TranscriptionState } from "@tdms/frontend/modules/audio/store/transcription.state";
import { ChartJSDrawingHelper } from "@tdms/frontend/modules/charts/shared/plugins/drawing.helper";
import { WebSocketService } from "@tdms/frontend/modules/communication/services/websocket.service";
import { selectFilesForSession } from "@tdms/frontend/modules/data-store/models/store/data.store.selector";
import { DownloadService } from "@tdms/frontend/modules/data-store/services/download.service";
import { SessionService } from "@tdms/frontend/modules/session/services/session.service";
import { ConfigService } from "@tdms/frontend/modules/settings/services/config.service";
import { AudioPlayerComponent } from "@tdms/frontend/modules/shared/components";
import { Service } from "@tdms/frontend/modules/shared/services/base.service";
import { BehaviorSubject, firstValueFrom, from, map, Observable } from "rxjs";
import { TranscriptionActions } from "../store/transcription.adapter";

/**
 * Service for transcriptions and other audio related handling
 */
@Injectable({ providedIn: "root" })
export default class AudioService extends Service {
  /**
   * The name of the audio plugin in the backend so we can check if we have any audio files
   */
  static readonly AUDIO_PLUGIN_OPTION = "Audio";

  /**
   * An array of any current data store audio files available for the current session
   */
  currentSessionAudioFiles: DataStoreFile[] = [];

  /** A subject to track when a new audio blob is loaded */
  audioBlob = new BehaviorSubject<{ blob: Blob; url: SafeResourceUrl; file: DataStoreFile } | undefined>(undefined);

  /**
   * A boolean to track if our last request caused a failure during transcription generation.
   */
  failureToGenerate = false;

  /** Tracks the last message we might have gotten during a failure to load a playback file */
  loadFailureMessage: string | undefined = undefined;
  /** Similar to loadFailureMessage but has a much more engineer-y failure message */
  extendedFailureMessage: string | undefined = undefined;

  /** This behavior subject tracks status updates when they occur for refresh requests */
  refreshStatus = new BehaviorSubject<DataStoreUploadStatus | undefined>(undefined);

  /** A boolean that tracks if the current session has transcription data */
  hasTranscript: boolean = false;

  constructor(
    private store: Store<TranscriptionState>,
    private sessionService: SessionService,
    private downloadService: DownloadService,
    private wsService: WebSocketService,
    private configService: ConfigService,
    private domSanitizer: DomSanitizer
  ) {
    super();
  }

  /**
   * Returns the audio file of this session that can be used for playback. This means you will only get audio files
   *  from the audio plugin that match the `Audio` {@link DataStoreFileTypes}
   */
  get audioFile(): DataStoreFile | undefined {
    // Attempt to locate a speech mixed and use that if available
    const mixed = this.currentSessionAudioFiles.find(
      (x) => x.pluginType === AudioConfig.SPEECH_MIXED_UPLOAD_OPTION_NAME
    );
    // If we have a mix down, return that
    if (mixed) return mixed;
    // Else fallback to trying to determine a audio file that can work
    return this.currentSessionAudioFiles.find((x) =>
      DataStoreFile.verifyFileExtension(x.uncompressedFileName, DataStoreFileTypes.typeToStringArray("Audio"))
    );
  }

  override async initialize() {
    // Keep files up to date
    this.store
      .select(selectFilesForSession)
      .subscribe((files) => (this.currentSessionAudioFiles = this.getAudioFiles(files)));
    this.store
      .select(selectAllTranscriptionsFromState)
      .subscribe((transcript) => (this.hasTranscript = transcript.length > 0));
  }

  override async onSessionChanged(session: Session) {
    this.refreshStatus.next(undefined);
    // This default case is hit early in the event we don't have an audio file, so we can still attempt to post load it
    if (session) {
      // Else, confirm that we don't have transcripts related to the session that could have been created without linking to the audio file
      this.loadFailureMessage = "No audio file available";
      // This is only ever shown on the communication tab and if no audio file is loaded the only way you can end up there is if you have transcriptions only
      this.extendedFailureMessage = "This session was created with a transcription text file";
      // Grab any available transcripts
      await this.getSessionBasedTranscription(session);
    }
  }

  override async onSessionChangedPost(session?: Session): Promise<void> {
    if (this.configService.pluginIsEnabled("Audio") && this.configService.pluginIsEnabled("DataStore")) {
      if (session) {
        // Request transcription content based on available files
        const filesForSession = await firstValueFrom(this.store.select(selectFilesForSession));
        this.currentSessionAudioFiles = this.getAudioFiles(filesForSession);
        // If we have an audio file, grab the standard data
        if (this.audioFile != null) {
          this.getAudioAnalysis(session);
          this.populateCurrentAudioBlob();
        }
      } else {
        this.audioBlob.next(undefined);
        this.store.dispatch(TranscriptionActions.empty({}));
        this.failureToGenerate = false;
        this.loadFailureMessage = undefined;
        this.extendedFailureMessage = undefined;
      }
    }
  }

  /**
   * Handles populating the current audio file for usage in displays. Be careful as this can cause
   *  slowdowns during the download.
   * @param downloadReasoning The reason for populating the audio blob. You should be good to use the default here.
   */
  async populateCurrentAudioBlob(downloadReasoning = "Waveform display") {
    try {
      if (this.audioFile == null) return;
      const { blob, url } = await this.getAudioFileBlob(this.audioFile.httpPath, downloadReasoning);
      this.audioBlob.next({ blob: blob!, url: url!, file: this.audioFile });
      this.loadFailureMessage = undefined;
    } catch (e) {
      this.loadFailureMessage = "Failed to download audio file";
      this.extendedFailureMessage = `${(e as Error).message}`;
    }
  }

  /** Looks up the transcript in the backend just by the session. Will not request a regeneration in the event a transcript doesn't exist. */
  async getSessionBasedTranscription(session: Session) {
    this.store.dispatch(TranscriptionActions.empty({}));
    const message = new TDMSWebSocketMessage(AudioTopics.getForSession, session.id);
    const response = await this.wsService.sendAndReceive<Transcription[]>(message);
    this.store.dispatch(TranscriptionActions.add({ data: Transcription.fromPlainArray(response.payload) }));
  }

  /**
   * Given the data store file ID for the audio file, grabs the analyzed audio information from the backend
   */
  async getAudioAnalysis(session: Session) {
    let audioFile = this.audioFile;
    // We need the non mixed audio file as the backend will do it's own comparison of each
    if (this.audioFile?.pluginType === AudioConfig.SPEECH_MIXED_UPLOAD_OPTION_NAME)
      audioFile = this.currentSessionAudioFiles[0];
    if (audioFile == null) {
      console.error("No Audio file found");
      return;
    }
    this.store.dispatch(TranscriptionActions.empty({}));
    const message = new TDMSWebSocketMessage(AudioTopics.getForFile, session.id, audioFile.id);
    const response = await this.wsService.sendAndReceive<Transcription[]>(message);
    const updatedTranscriptions = response.payload as Transcription[];
    this.store.dispatch(TranscriptionActions.add({ data: updatedTranscriptions }));
  }

  /**
   * Requests a refresh of the audio analysis for the current audio file
   */
  async refreshAnalysis() {
    const audioFile = this.audioFile;
    // Don't process if we don't have an audio file
    if (audioFile == null) return;
    this.failureToGenerate = false;
    // Send the request. It'll use the endpoint to add the data because this could be a long running task
    const result = await this.wsService.sendAndReceive<TranscriptionRefresh>(
      new TDMSWebSocketMessage(
        AudioTopics.refreshAnalysis,
        this.sessionService.currentSession?.id,
        TranscriptionRefresh.fromPlain({ audioFileId: audioFile.id })
      )
    );
    if (result.success) this.store.dispatch(TranscriptionActions.empty({}));
  }

  /**
   * Requests a refresh of the audio analysis for the current audio file
   */
  async manuallyChangeTranscriptions(transcriptions: Transcription[]) {
    await this.wsService.sendAndReceive<TranscriptionEdit>(
      new TDMSWebSocketMessage(
        AudioTopics.manuallyChangeTranscriptions,
        this.sessionService.currentSession?.id,
        TranscriptionEdit.fromPlain({ transcriptions })
      )
    );
  }

  /** Listens for updates in transcriptions */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<TranscriptionEdit>>(
    AudioTopics.manuallyChangeTranscriptions
  )
  protected async manuallyChangeTranscriptionsReceived(data: TDMSWebSocketMessage<TranscriptionEdit>) {
    if (!data.success) return; // Ignore errors
    // Update our transcriptions
    const transcriptions = Transcription.fromPlainArray(data.payload.transcriptions);
    transcriptions.forEach((x) => this.store.dispatch(TranscriptionActions.update({ id: x.id, changes: x })));
  }

  /**
   * Given an audio file and a list of data store files, determines the available audio files for that session
   * @param session The session to check against
   * @param files The array of files to process against
   */
  getAudioFiles(files: DataStoreFile[]) {
    return files.filter((x) => x.matchingPlugin === AudioService.AUDIO_PLUGIN_OPTION);
  }

  /**
   * Listens for new audio analysis updates and handles inserting that data where it needs to be
   */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<Transcription[]>>(AudioTopics.analyzeResults)
  async newAnalysisReceived(data: TDMSWebSocketMessage<Transcription[]>) {
    if (data.sessionId != null && data.sessionId === this.sessionService.currentSession?.id) {
      this.refreshStatus.next(undefined);
      this.store.dispatch(TranscriptionActions.empty({}));
      this.store.dispatch(TranscriptionActions.add({ data: data.payload }));
    }
  }

  /** Listen for failures to generate to indicate the failureToGenerate happened */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<string>>(AudioTopics.failed)
  protected async failedReceived(data: TDMSWebSocketMessage<string>) {
    if (data.sessionId === this.sessionService.currentSession?.id) {
      this.failureToGenerate = true;
      this.refreshStatus.next(undefined);
    }
  }

  /** Listens for the regeneration status */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<DataStoreUploadStatus>>(AudioTopics.refreshAnalysisStatus)
  protected async refreshStatusReceived(data: TDMSWebSocketMessage<DataStoreUploadStatus>) {
    const status = DataStoreUploadStatus.fromPlain(data.payload);
    if (status.correspondingSession === this.sessionService.currentSession?.id) {
      if (status.clear) this.refreshStatus.next(undefined);
      else {
        status.setEstimatedTime(this.refreshStatus.value);
        this.refreshStatus.next(status);
      }
    }
  }

  /** Given the audio file http path from the data store, downloads it into a blob and returns it. */
  async getAudioFileBlob(path: string, downloadReasoning = "playback") {
    const blob = await this.downloadService.downloadFile(path, downloadReasoning);
    // Sanitize the URL since its safely stored on this machine anyways
    return {
      blob,
      url: blob == null ? undefined : this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob)),
    };
  }

  /** Returns an observable of a bookmark that tracks the current playback time for the given player. */
  getPlaybackBookmark(
    player: AudioPlayerComponent | BackgroundAudioComponent | undefined,
    session: Session = this.sessionService.currentSession!
  ): Observable<Bookmark[]> {
    if (!player) return from([]);
    const type = new BookmarkType(
      0,
      "Playback",
      ChartJSDrawingHelper.defaultColor,
      "vertical",
      false,
      true,
      undefined,
      false,
      true
    );
    const playbackBmk = new Bookmark(0, false, "Current Playback Time", type, session.startDate);
    return player.currentPlayerTime.pipe(
      map((currentTime) => {
        const currentDate = new Date(session.startDate.getTime() + currentTime * 1000);
        let startTime = currentDate; // The start time to use for the playback bookmark
        // Adjust time of bookmark so it's always visible within the range
        if (currentTime === 0) startTime = new Date(currentDate.getTime() + 10);
        else if (player && player.duration === currentTime) startTime = session.endDate;
        // Set the time into the bookmark
        playbackBmk.startTime = startTime;
        return [playbackBmk];
      })
    );
  }
}
