import { Component, ElementRef, Input, OnChanges, ViewChild } from "@angular/core";
import { MatSliderChange } from "@angular/material/slider";
import { SafeResourceUrl } from "@angular/platform-browser";
import { SHORT_TIME_FORMAT, TIME_FORMAT } from "@tdms/common";
import { AngularCustomTypes } from "@tdms/frontend/modules/shared/models/angular.custom.types";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import autoBind from "auto-bind";
import { addSeconds } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { BehaviorSubject, interval } from "rxjs";

/**
 * A generic audio player component with pre configured styling
 */
@Component({
  selector: "shared-audio-player",
  templateUrl: "./audio-player.component.html",
  styleUrls: ["./audio-player.component.scss"],
})
export class AudioPlayerComponent extends SubscribingComponent implements OnChanges {
  /** Rendered audio controller component */
  private audioController: HTMLAudioElement | undefined;

  /** If given, this external controller will be used in place of rendering our own internal audio controller. Useful for background rendering. */
  @Input() audioControllerExternal: AudioPlayerComponent | undefined;

  /** Set the rendered audio controller on initialize */
  @ViewChild("audioController") set playerRef(ref: ElementRef<HTMLAudioElement> | undefined) {
    // If we don't have external override, set that
    if (this.audioControllerExternal == null) this.audioController = ref?.nativeElement;
  }

  /** How often we should poll the audio element to update the players current time */
  protected pollingTime = 50;

  /**
   * What type of display we should have for this element.
   *
   * - `small`: Will render a condensed version of the player intended to be used horizontally in display.
   * - `expanded`: Will use the larger style player where it's inteded to be displayed vertically.
   * - `player-only`: Only renders the audio player, no actual control.
   */
  @Input() displayType: "small" | "expanded" | "player-only" = "small";

  /** If we should render the playback numbers as the file plays. */
  @Input() displayPlaybackState: boolean = true;

  /** The source path for the audio player */
  @Input() src: string | SafeResourceUrl | undefined;

  /** Tracks if we are dragging the mat slider or not */
  sliderDragging = false;

  /** The player's current time as a subject, separated so we can perform more constant updates */
  currentPlayerTime = new BehaviorSubject(0);

  constructor() {
    super();
    autoBind(this);
    // Poll for player time
    this.addSubscription(
      interval(this.pollingTime).subscribe(() => {
        if (this.audioController && this.audioController.currentTime !== this.currentPlayerTime.value)
          this.currentPlayerTime.next(this.audioController.currentTime);
      })
    );
  }

  async ngOnChanges(changes: AngularCustomTypes.BaseChangeTracker<AudioPlayerComponent>): Promise<void> {
    if (changes.audioControllerExternal?.currentValue)
      this.audioController = changes.audioControllerExternal.currentValue.audioController;
    /** If we detect changes to the src path, auto re load the playback component */
    if (changes.src && !changes.src.firstChange && changes.src.currentValue) this.audioController?.load();
  }

  /** Returns an observable that will update when the current play time changes */
  get currentTimeObservable() {
    return this.currentPlayerTime;
  }

  /** Checks if the controller is ready to use. If not, returns false. */
  validateController(controller = this.audioController) {
    if (!controller) return false;
    else return true;
  }

  get isPaused() {
    return this.audioController?.paused ?? true;
  }

  /** Pauses current playback */
  async pause() {
    if (!this.validateController(this.audioController)) return;
    this.audioController!.pause();
  }

  /** Starts playing or pauses audio playback based on current state */
  async playPause() {
    if (!this.validateController(this.audioController)) return;
    if (this.isPaused) await this.audioController!.play();
    else this.audioController!.pause();
  }

  get currentTime() {
    return this.currentPlayerTime.value;
  }

  /** Returns the time display for the slider */
  get timeDisplay() {
    if (this.audioController) return (this.currentTime / this.duration) * 100;
    else return 0;
  }

  /**
   * Allows setting the time display with relevant locations with the slider
   *
   * @param val Percentage of how far into the audio track we should be going to
   */
  set timeDisplay(val: number) {
    if (!this.validateController(this.audioController)) return;
    this.audioController!.currentTime = this.duration * (val / 100);
  }

  get duration() {
    return this.audioController?.duration || 1;
  }

  /**
   * Returns a pretty string for the current time and duration for audio playback capability
   * @param currentTime The current time, in seconds, of how far into the duration we are
   * @param duration The total audio file duration, in seconds
   * @returns A pretty string
   */
  static getAudioPlaybackState(currentTime: number, duration: number) {
    const dateCurrent = addSeconds(new Date(0), currentTime);
    const dateDuration = addSeconds(new Date(0), duration);
    // If we have more than an hour of time, we should include the extended time format
    const format = duration >= 3600 ? TIME_FORMAT : SHORT_TIME_FORMAT;
    return `${formatInTimeZone(dateCurrent, "UTC", format)} / ${formatInTimeZone(dateDuration, "UTC", format)}`;
  }

  /** Returns a pretty format for the duration versus elapsed for audio playback */
  get playbackState() {
    return AudioPlayerComponent.getAudioPlaybackState(this.currentTime, this.duration);
  }

  /** Tracks dragging ***starting*** for the mat slider */
  dragStart(event: MatSliderChange) {
    this.timeDisplay = event.value || 0;
    this.sliderDragging = true;
  }

  /** Tracks dragging ***stopping*** for the mat slider */
  dragStop() {
    this.sliderDragging = false;
  }

  /** Given a time, seeks directly to it */
  seekTo(time: number, shouldPlay = false) {
    if (!this.validateController(this.audioController)) return;
    this.audioController!.currentTime = time;
    if (shouldPlay) this.audioController!.play();
  }

  /**
   * Resets the audio playback to the beginning. Does not start playing.
   */
  async resetAudioPlayback() {
    if (!this.validateController(this.audioController)) return;
    this.audioController!.pause();
    this.audioController!.currentTime = 0;
    this.currentPlayerTime.next(0);
  }

  /** Returns the ready state of the audio controller. Anything >= 1 should signify as ready. */
  get readyState() {
    return this.audioController?.readyState ?? 0;
  }
}
