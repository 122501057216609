import { Injectable } from "@angular/core";
import {
  BaseSettings,
  CommonColorTheme,
  GetColorThemesRequest,
  TDMSWebSocketMessage,
  User,
  UserTopics,
} from "@tdms/common";
import { WebSocketService } from "@tdms/frontend/modules/communication/services/websocket.service";
import { FrontendSupportedMetricType } from "@tdms/frontend/modules/metrics/components/metric-card/models/metric.configuration";
import { PluginSettingsService } from "@tdms/frontend/modules/settings/services/settings.service";
import { TDMSTheme } from "@tdms/frontend/modules/shared/components";
import { Service } from "@tdms/frontend/modules/shared/services/base.service";
import { BehaviorSubject, first, firstValueFrom, fromEvent, map, Observable, timeout } from "rxjs";

export interface ColorMapping {
  name: string;
  value: string;
}

export interface NamedDataSet {
  name: string;
}

export type ColorMap = Array<ColorMapping>;

/**
 * This service provides overarching color configuration to the entire frontend. This includes color information about
 *  the currently selected theme as well as providing a palette of color options for any charts.
 */
@Injectable({
  providedIn: "root",
})
export class ColorThemeService extends Service {
  /**
   * A behavior subject to track what theme is currently loaded overarching the app
   */
  applicationLevelTheme = new BehaviorSubject<TDMSTheme>(this.currentApplicationTheme);

  /** The current list of available color themes as provided by the backend. */
  colorThemes = new BehaviorSubject<Array<CommonColorTheme>>([]);

  /**
   * The color mapping that contains the actual color to value data
   */
  private currentColorMap: ColorMap = [];

  /** The current color theme we intend to display for our color mapping */
  currentColorTheme = new BehaviorSubject<CommonColorTheme | undefined>(undefined);

  /**
   * The current index we are on for mapping colors so we can determine
   *  what the next color to pick from is
   */
  private currentColorIndex: number = 0;

  constructor(private wsService: WebSocketService, private settingsService: PluginSettingsService) {
    super();
  }

  /**
   * Returns the users "preferred" color scheme determined by the browser
   */
  private get prefersColorSchemeObservable() {
    const colorQuery = window.matchMedia("(prefers-color-scheme: dark)");
    return fromEvent<MediaQueryList>(colorQuery, "change").pipe(
      map((list: MediaQueryList): TDMSTheme => (list.matches ? "dark" : "light"))
    );
  }

  /**
   * Returns the current app theme set into the DOM
   */
  private get currentApplicationTheme(): TDMSTheme {
    return window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
  }

  override async onUserChanged(_?: User | undefined): Promise<void> {
    await this.loadColorThemes();
    this.settingsService
      // Subscribe to when settings change to see if our color theme needs to change
      .observeSettingValue<string>(BaseSettings.CORE_SETTINGS_PLUGIN_NAME, BaseSettings.Names.colorTheme)
      .subscribe(this.updateCurrentColorTheme.bind(this));
    this.prefersColorSchemeObservable.subscribe((x) => this.applicationLevelTheme.next(x));
    // await this.updateCurrentColorTheme();
  }

  /**
   * Requests the available color themes from the backend and populates the holder value
   */
  async loadColorThemes() {
    const response = await this.wsService.sendAndReceive<CommonColorTheme[]>(
      new TDMSWebSocketMessage(UserTopics.getColorThemes, undefined, new GetColorThemesRequest())
    );
    // Error handling
    if (!response.success)
      throw new Error(`Error occurred loading available color themes from backend: ${response.failureMessage}`);
    if (response.payload == undefined) throw new Error(`No color themes provided by backend!`);
    // Inform subject of new content
    this.colorThemes.next(response.payload);
  }

  /**
   * Sets up the color theme and resets mappings as need be
   */
  protected async updateCurrentColorTheme(newTheme: string) {
    const currentTheme = await this.getCurrentColorTheme(newTheme);
    // If they have changed, reset our theme setup
    if (currentTheme.name !== this.currentColorTheme.value?.name) {
      // Reset color mapping
      this.resetColorMap();
      this.currentColorTheme.next(currentTheme);
    }
  }

  /**
   * Returns the current color theme for the charts as configured by the user
   */
  protected async getCurrentColorTheme(newTheme: string) {
    // We have a theme setting so attempt to find the matching theme
    const colorThemes = await firstValueFrom(this.colorThemes.pipe(first((x) => x.length > 0)).pipe(timeout(30000)));
    const matchingBackendTheme = colorThemes.find((x) => x.name === newTheme);
    if (matchingBackendTheme == null)
      // Handle non existent themes
      throw new Error(`Could not lookup available colors for current theme value of ${newTheme}`);
    else return matchingBackendTheme;
  }

  /**
   * Call this when our color map should be reset.
   * This should happen whenever a new session is loaded to ensure consistent color mapping to roles/data sets.
   */
  resetColorMap() {
    this.currentColorIndex = 0;
    this.currentColorMap = [];
  }

  /**
   * Map the given data set name to a new color based on our current color theme.
   *
   * Note: This utilizes the current color theme value of the behavior subject. You should check this is set before calling this function.
   * @param dataSet The name of the data set to map.
   * @returns A ColorMapping instance with the newly mapped color and data set name.
   * @throws RuntimeError if no color theme is loaded, or if settings for the color themes plugin could not be found!
   */
  private getColorFor(dataSet: string): ColorMapping {
    const currentColorTheme = this.currentColorTheme.value;
    if (currentColorTheme == null) throw new Error("Cannot lookup color when no theme is selected");

    // Find if we already have a mapping for this data
    let mapping = this.currentColorMap.find((mapping) => mapping.name.toLowerCase() === dataSet.toLowerCase());

    // Since we haven't mapped this metric name yet, map it to a new color in the theme and push it to our map.
    if (!mapping) {
      const newColorForMapping = currentColorTheme.colors[this.currentColorIndex++];

      mapping = {
        name: dataSet,
        value: newColorForMapping,
      };
      this.currentColorMap.push(mapping);

      // Wrap back around if we reach the end of the current color theme.
      if (this.currentColorIndex > currentColorTheme.colors.length - 1) this.currentColorIndex = 0;
    }

    return mapping;
  }

  /**
   * Returns a color for the given name to utilize
   */
  getColorForString(name: string) {
    return this.getColorFor(name).value;
  }

  /**
   * Map the given data set names to a new color map based on the current color theme.
   * The mapping here will behave similarly to the a d3 Ordinal scale in which the same value will be mapped to the same color regardless of how many times it is mapped.
   * @param dataSets The list of data set names to be mapped.
   * @returns A ColorMap instance with all the provided data sets mapped to colors.
   */
  getColorsFor(data: FrontendSupportedMetricType | undefined): ColorMap {
    if (data == null) return [];
    else if (!Array.isArray(data)) return [this.getColorFor(data.name)];
    else return data?.map((dataSet) => this.getColorFor(dataSet.name)) || [];
  }

  /**
   * Create an observable for the given data set that will automatically create a color map for that data set whenever the color theme changes.
   * @param dataSets The data set names to be mapped.
   * @returns An observable that maintains an updated ColorMap for the currently latest color theme.
   */
  observeColorsFor(dataSets: FrontendSupportedMetricType | undefined): Observable<ColorMap> {
    const subject = new BehaviorSubject<ColorMap>([]);
    const obs = this.currentColorTheme.pipe(
      map((x) => {
        // If we have a theme, determine colors for said theme
        if (x != null) return this.getColorsFor(dataSets);
        // If we don't have a theme, return blank color set
        else return [];
      })
    );
    // Align behavior subject to custom observable
    obs.subscribe(subject);
    return subject;
  }
}
