import { Component, ElementRef, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { CustomTypes, Role, Session, SessionSummary } from "@tdms/common";
import { ColorThemeService } from "@tdms/frontend/modules/material/services/themes.service";
import { MetricGridDataStore } from "@tdms/frontend/modules/metrics/components/metric-grid/models/metric-grid.configuration";
import {
  FeedbackComponent,
  FeedbackComponentProps,
} from "@tdms/frontend/modules/session-summary/components/feedback/feedback.component";
import SessionSummaryService from "@tdms/frontend/modules/session-summary/services/session.summary.service";
import { selectSessionSummaryForCurrentSession } from "@tdms/frontend/modules/session-summary/store/summary.selector";
import { SessionSummaryState } from "@tdms/frontend/modules/session-summary/store/summary.state";
import { selectCurrentSession } from "@tdms/frontend/modules/session/store/session.selector";
import { DialogWrapperComponent } from "@tdms/frontend/modules/shared/components";
import { ScrollTrackingComponent } from "@tdms/frontend/modules/shared/utils/scroll.tracking.component";

@Component({
  selector: "app-overview",
  templateUrl: "./overview.component.html",
  styleUrls: ["./overview.component.scss"],
})
export class OverviewComponent extends ScrollTrackingComponent {
  @ViewChild("overviewContainer") containerElement: ElementRef<HTMLElement> | undefined;
  /** The current session summary data for the current session */
  currentSessionSummary: SessionSummary | undefined;
  /** Feedback that each metric contains team feedback for. Placed in this array to make rendering easier. */
  teamFeedback: string[] | undefined;
  /** The feedback for each individual user from each metric */
  individualFeedback: { [speakerIdentifier: string]: string[] } = {};
  overallPros: string[] = [];
  overallCons: string[] = [];

  /** This is the metrics that should be displayed in our grid */
  currentMetricDataStore: MetricGridDataStore | undefined;
  currentSession: Session | undefined;

  /** Metadata of the current session so we can display extra identifiers. */
  sessionMetadata:
    | CustomTypes.ExtractObservableType<ReturnType<Awaited<SessionSummaryService["getSessionMetadataObservable"]>>>
    | undefined;

  constructor(
    private store: Store<SessionSummaryState>,
    private sessionSummaryService: SessionSummaryService,
    public themeService: ColorThemeService,
    private dialog: MatDialog
  ) {
    super();
    this.addSubscription(
      this.store.select(selectCurrentSession).subscribe(async (session) => {
        this.currentSession = session;
        await this.updateMetricDataStore();
      })
    );
    this.addSubscription(
      this.store.select(selectSessionSummaryForCurrentSession).subscribe(async (summary) => {
        // Track the session summary data
        this.currentSessionSummary = summary;
        // Grab metrics we care about
        const relevantMetrics = summary?.advancedBreakdown.flatMap((x) => x.metrics!).filter((x) => x != null);
        if (relevantMetrics && this.currentSession) {
          // Grab all team feedback
          this.teamFeedback = relevantMetrics.flatMap((x) => x.teamFeedbackScoreBased).filter((x) => x);
          // Grab all feedback for each specific user
          for (let role of this.currentSession.roles)
            this.individualFeedback[role.name] = relevantMetrics.flatMap((metric) =>
              metric.getFeedbackForRole(role.name)
            );
          // Populate overall pros/cons
          this.overallPros = relevantMetrics.flatMap((x) => x.prosOverallScoreBased);
          this.overallCons = relevantMetrics.flatMap((x) => x.consOverallScoreBased);
        }
        await this.updateMetricDataStore();
      })
    );
    this.addSubscription(
      this.sessionSummaryService.getSessionMetadataObservable().subscribe((x) => (this.sessionMetadata = x))
    );
  }

  /** Updates the metric grid data store to display the overarching summary score */
  async updateMetricDataStore() {
    this.currentMetricDataStore = await this.sessionSummaryService.getMetricGridDataStore();
  }

  /** Given a role, opens a dialog that provides more metadata for this specific role */
  openRoleDialog(role: Role) {
    this.dialog.open(FeedbackComponent, {
      data: { role: role } as FeedbackComponentProps,
      ...DialogWrapperComponent.getDefaultOptions(),
    });
  }
}
