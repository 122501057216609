import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  ChartConfiguration,
  Configuration,
  MetricPluginReply,
  MetricTopics,
  Session,
  TDMSWebSocketMessage,
  TimeSeriesChartBase,
} from "@tdms/common";
import {
  ZoomDomainContent,
  ZoomDomainUpdateEmitter,
} from "@tdms/frontend/modules/charts/shared/timeline/timeline.selection.base";
import { WebSocketService } from "@tdms/frontend/modules/communication/services/websocket.service";
import { MetricServiceDataStore } from "@tdms/frontend/modules/metrics/components/metric-card/models/metric.configuration";
import { MetricActions } from "@tdms/frontend/modules/metrics/store/metric.action";
import {
  selectLoadedMetricSessions,
  selectMetricConfiguration,
} from "@tdms/frontend/modules/metrics/store/metric.selector";
import { MetricState } from "@tdms/frontend/modules/metrics/store/metric.state";
import { Route_URLs } from "@tdms/frontend/modules/routing/models/url";
import { RouterService } from "@tdms/frontend/modules/routing/services/router.service";
import { selectCurrentSession } from "@tdms/frontend/modules/session/store/session.selector";
import { Service } from "@tdms/frontend/modules/shared/services/base.service";
import { isEqual } from "lodash-es";
import { BehaviorSubject, debounceTime, firstValueFrom } from "rxjs";

/**
 * This service is used to request speaking time metrics for display from the backend. This offloads
 *  processing to the backend so we don't have to keep recalculating in the frontend display.
 */
@Injectable({
  providedIn: "root",
})
export class MetricService extends Service {
  /**
   * The current zoomed chart domain, if set
   */
  currentDomain: [Date, Date] | undefined;

  /**
   * A base level event emitter to expand into multiple charts so the "zoom" domain will be maintained
   *  across all of them.
   */
  zoomDomainUpdate: ZoomDomainUpdateEmitter = new BehaviorSubject<ZoomDomainContent>({
    domain: undefined,
    source: "external",
  });

  constructor(
    private store: Store<MetricState>,
    private wsService: WebSocketService,
    private routerService: RouterService
  ) {
    super();
  }

  override async initialize() {
    // Listen for domain changes for bar charts
    this.zoomDomainUpdate
      .pipe(debounceTime(Configuration.CHART_DOMAIN_DEBOUNCE_TIME))
      .subscribe((x) => this.updateDisplayData(x.domain));
  }

  override async onBackendDisconnected(): Promise<void> {
    // Wipe out metrics
    this.wipeMetricData();
  }

  override async onUserChanged(): Promise<void> {
    // Request new metric configuration if none are registered
    const metricConfigs = await firstValueFrom(this.store.select(selectMetricConfiguration));
    if (metricConfigs.length === 0) return this.requestMetricConfig();
  }

  override async onSessionChanged(newSession?: Session | undefined): Promise<void> {
    this.zoomDomainUpdate.next({ domain: undefined, source: "external" });
    // Request new metric data for new session selected, only if we don't already have that data
    const loadedMetricData = await firstValueFrom(this.store.select(selectLoadedMetricSessions));
    if (newSession != null && !loadedMetricData.includes(newSession.id.toString()))
      this.requestMetricDataForSession(newSession);
  }

  override async onSessionDataRefresh(session: Session) {
    this.requestMetricDataForSession(session);
  }

  /**
   * Requests the metric config from the backend so we know what metrics we can display
   */
  private async requestMetricConfig() {
    const response = await this.wsService.sendAndReceive<Array<ChartConfiguration>>(
      new TDMSWebSocketMessage(MetricTopics.getConfig)
    );
    const configs = ChartConfiguration.fromPlainArray(response.payload);
    // Add configs to store
    this.store.dispatch(MetricActions.addChartConfiguration({ configs: configs }));
  }

  /**
   * Given a session, requests the metric data for it so we can make sure it is loaded into the store
   * @param session
   */
  public async requestMetricDataForSession(session: Session) {
    await Promise.all(
      (
        await firstValueFrom(this.store.select(selectMetricConfiguration))
      ).map(async (x) => await this.requestNewMetricData(x, session))
    );
  }

  /**
   * Given a configuration and a domain, requests new data for the chart and assigns it to the datastore as it sees fit
   */
  private async requestNewMetricData(config: ChartConfiguration, session: Session, domain?: [Date, Date]) {
    // Setup some default data including calculation status
    this.store.dispatch(
      MetricActions.updateMetricData({
        sessionId: session.id,
        metricName: config.metricName,
        data: { isCalculating: true },
      })
    );
    // Ask the backend for data sync
    const response = await this.wsService.sendAndReceive<Object, MetricPluginReply>(
      new TDMSWebSocketMessage(config.getBySessionIdQueue, session.id, { domain: domain })
    );
    const sessionId = response.sessionId!;
    // Handle no data available
    if (response.payload?.data == null) {
      this.store.dispatch(
        MetricActions.updateMetricData({
          sessionId: sessionId,
          metricName: config.metricName,
          data: MetricServiceDataStore.fromPlain({
            isCalculating: false,
            data: undefined,
          }),
        })
      );
    } else {
      // Convert data typing
      const returnedData = MetricServiceDataStore.convertDataTyping(config, response);
      const bookmarks = config.isTimeSeriesChart
        ? (returnedData as TimeSeriesChartBase<any>[]).flatMap((x) => x.seriesSpecificBookmarks)
        : [];
      // Get partial data we intend to update
      const partialData = MetricServiceDataStore.fromPlain({
        isCalculating: false,
        data: returnedData,
        bookmarks: bookmarks,
      });
      // Dispatch the updates
      this.store.dispatch(
        MetricActions.updateMetricData({
          sessionId: sessionId,
          metricName: config.metricName,
          data: partialData,
        })
      );
    }
  }

  /**
   * Given a domain, requests new metric data for metrics that require backend communication
   *  for generating new metric information based on domain.
   * @param domain The optional domain to update to
   */
  private async updateMetricsForDomain(domain?: [Date, Date]) {
    const currentSession = await firstValueFrom(this.store.select(selectCurrentSession));
    if (currentSession == null) throw new Error("Can't update metrics without a session");
    const metricConfigs = await firstValueFrom(this.store.select(selectMetricConfiguration));
    // Only request specific data from the backend if they support metric updates
    metricConfigs
      .filter((x) => x.shouldRequestDataOnDomainChange)
      .map((config) => this.requestNewMetricData(config, currentSession, domain));
  }

  /**
   * Empties all configured metrics. Also empties the metric configuration
   */
  private wipeMetricData() {
    this.store.dispatch(MetricActions.wipeMetrics({ sessionId: undefined }));
    this.store.dispatch(MetricActions.wipeConfiguration({}));
    this.zoomDomainUpdate.next({ domain: undefined, source: "external" });
  }

  /**
   * Sets the chart data to be displayed based on current session and domain information
   * @param data The domain to update to
   */
  private async updateDisplayData(domain?: [Date, Date]) {
    // Ignore requests when not on dashboard
    if (!this.routerService.isActiveRoute(Route_URLs.dashboard)) return;
    const currentSession = await firstValueFrom(this.store.select(selectCurrentSession));
    // Update stored domain
    const domainHasChanged = !isEqual(this.currentDomain, domain);
    // If the domain has changed...
    if (domainHasChanged) {
      // If times equal session time, reset domain range
      if (
        currentSession &&
        domain &&
        currentSession.startDate.getTime() === domain[0].getTime() &&
        currentSession.endDate.getTime() === domain[1].getTime()
      )
        this.currentDomain = undefined;
      else this.currentDomain = domain;
      this.updateMetricsForDomain(domain);
    }
  }

  /**
   * Makes sure we have valid comparison data for the requested comparison
   */
  public async requestComparisonData(sessionA: Session, sessionB: Session) {
    const loadedMetricData = await firstValueFrom(this.store.select(selectLoadedMetricSessions));
    // If we don't have sessionA data, request it
    if (!loadedMetricData.includes(sessionA.id.toString())) {
      this.requestMetricDataForSession(sessionA);
    }
    // If we don't have sessionB data, request it
    if (!loadedMetricData.includes(sessionB.id.toString())) {
      this.requestMetricDataForSession(sessionB);
    }
  }
}
