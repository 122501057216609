import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { Bookmark, BookmarkType, ChartConfiguration, CommonSetting, CommonSettingDescription } from "@tdms/common";
import { LegendDisplay } from "@tdms/frontend/modules/charts/shared/legend/models/legend.display";
import { ZoomDomainUpdateEmitter } from "@tdms/frontend/modules/charts/shared/timeline/timeline.selection.base";
import { ColorMap } from "@tdms/frontend/modules/material/services/themes.service";
import { PluginSettingsService } from "@tdms/frontend/modules/settings/services/settings.service";
import { SettingValue } from "@tdms/frontend/modules/settings/store/value/setting.value.model";
import { TDMSTheme } from "@tdms/frontend/modules/shared/components";
import { AngularCustomTypes } from "@tdms/frontend/modules/shared/models/angular.custom.types";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import { kebabCase } from "lodash-es";
import { BehaviorSubject, combineLatest, from, Observable, startWith, Subscription } from "rxjs";
import {
  FrontendSupportedMetricType,
  MetricCardDataStore,
  MetricServiceDataStore,
} from "./models/metric.configuration";

/** This class contains a centralized position for bookmark properties that metrics will want to have control over */
@Component({ template: "" })
export class BookmarkProperties extends SubscribingComponent {
  /** Bookmarks that the entire metric grid this timeline is rendered in should display */
  @Input() gridLevelBookmarks: Bookmark[] = [];
  /** An array of currently available bookmark types */
  @Input() bookmarkTypes: BookmarkType[] = [];
  /** A subject to listen to for when bookmarks are drawn on any charts so we only allow one draw at a time. */
  @Input() bookmarkDrawingStatus = new BehaviorSubject<boolean>(false);
  /** Callback function to use to add new bookmarks */
  @Input() onAddBookmarkClicked?: { (bookmark: Bookmark): void } | undefined;
  /** Callback function to use to update bookmarks */
  @Input() onUpdateBookmarkClicked?: { (bookmark: Bookmark): void } | undefined;
}

/**
 * A generic card component to render the metric service data as given
 */
@Component({
  selector: "metric-card[dataStore][applicationTheme][totalChartTimeFrame]",
  templateUrl: "./metric-card.component.html",
  styleUrls: ["./metric-card.component.scss"],
})
export class MetricCardComponent<_ChartDataType extends FrontendSupportedMetricType>
  extends BookmarkProperties
  implements OnInit, OnDestroy, OnChanges
{
  @Input() dataStore!: MetricCardDataStore;

  @Input() enabled!: boolean;

  @Input() graphTitleStart?: string;
  @Input() graphTitleEnd?: string;

  /**
   * The total time frame for this given chart. This is required because we must be able to detect the min and max domain values,
   *  in the event we don't have an scroll domain
   */
  @Input() totalChartTimeFrame!: [Date, Date];

  @Input() forceUpdateFilterDomain!: EventEmitter<{ domain: [Date, Date] | undefined; isResizeEvent: boolean }>;

  /**
   * A callable that will be called to determine the export prefix for our metric.
   */
  @Input() getExportPrefix?: (dataStore: MetricCardDataStore) => string;

  @Input() zoomDomainUpdater!: ZoomDomainUpdateEmitter;
  /** Tracks our current zoom domain */
  zoomDomain: [Date, Date] | undefined;

  /**
   * The theme that the overarching app is currently utilizing
   */
  @Input() applicationTheme!: TDMSTheme;

  _currentColorMap?: ColorMap;
  _colorMapSubscription?: Subscription;

  /** Since bookmarks can exist in both grid level and chart specific, this array contains all the current bookmarks merged together. */
  currentMergedBookmarks: Bookmark[] = [];

  /**
   * Current bookmark display enabled status, which is updated via the dataStore bookmarkEnabled observable.
   */
  bookmarksEnabled: boolean = true;
  /** A tooltip so we know what to say about our current bookmark drawing capability for it's button. */
  bookmarksTooltip: string = "Bookmark Creation";
  /** The subject for the bookmark enabled status. Kept so we can destroy it if the data store ever changes. */
  bookmarksEnabledSub: Subscription | undefined;

  /**
   * Tracks if this grid is rendered in the session comparison or not
   */
  @Input() isSessionComparison: boolean = false;

  /** Loaded settings for this specific metric. */
  settings: readonly (CommonSetting & CommonSettingDescription)[] = [];
  /** The subject for the current settings subscription. Used so if the card reference changes, the settings will be properly re referenced. */
  settingsSub: Subscription | undefined;

  constructor(private settingsService: PluginSettingsService) {
    super();
    this.updateColorMapSubscription = this.updateColorMapSubscription.bind(this);
  }

  ngOnInit(): void {
    this.mergeBookmarks();
    this.updateColorMapSubscription();
    this.addSubscription(this.zoomDomainUpdater?.subscribe((content) => (this.zoomDomain = content.domain)));
  }

  ngOnChanges(changes: AngularCustomTypes.BaseChangeTracker<MetricCardComponent<any>>): void {
    if (changes.dataStore != null) {
      this.setupBookmarkEnabledObservable();
      this.updateColorMapSubscription();
      this.mergeBookmarks();
      this.setupSettingObservables();
    }
    // Update bookmarks if the grid level ones change
    if (changes.gridLevelBookmarks) this.mergeBookmarks();
  }

  /**
   * Handle changes to the local bookmarks or grid level bookmarks, ensuring our chart gets the updated data.
   * We need to do this here to prevent over-rendering by creating a new array instance every time it's requested.
   * Instead, we only merge the data when angular detects changes.
   */
  mergeBookmarks() {
    this.currentMergedBookmarks = [
      ...(MetricServiceDataStore.getEnabledBookmarks(
        this.metric?.bookmarks,
        LegendDisplay.flattenOptions(this.legend || [])
      ) ?? []),
      ...this.gridLevelBookmarks,
    ];
  }

  /**
   * When our metric card data store changes, we need to release resources and subscribe to the new color map observable.
   */
  updateColorMapSubscription() {
    this._colorMapSubscription?.unsubscribe();

    this._colorMapSubscription = this.dataStore?.colorMap.subscribe((colorMap) => {
      this._currentColorMap = colorMap;
    });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this._colorMapSubscription?.unsubscribe();
  }

  /**
   * Returns the prefix to add to the export data for the charts
   */
  get exportPrefixText() {
    if (this.dataStore == null || this.getExportPrefix == null) return null;
    return kebabCase(this.getExportPrefix(this.dataStore)) + "-";
  }

  get metric() {
    return this.dataStore?.metric;
  }

  get isLoading() {
    /**
     * We consider a chart still "loading" if no color values are available yet. But if no colors will ever be loaded,
     *  because there's no data, we need to short circuit this check and allow that to be displayed.
     */
    if (this.metric?.dataIsNotAvailable === true) return false;
    else return this.colorMap == null || this.colorMap.length === 0;
  }

  get chartDataIsCalculating() {
    return this.metric?.isCalculating;
  }

  get configuration(): ChartConfiguration | null {
    if (this.dataStore == null) return null;
    return this.dataStore.configuration;
  }

  get chartType() {
    return this.configuration?.type;
  }

  get data() {
    return this.metric?.data as any;
  }

  get colorMap() {
    return this._currentColorMap;
  }

  get bookmarks() {
    return this.currentMergedBookmarks;
  }

  get legend() {
    return this.dataStore?.legend?.value;
  }

  /** Returns the line chart domain to utilize for display */
  get lineChartDomain() {
    return this.zoomDomain || this.totalChartTimeFrame;
  }

  /** Sets up the setting observables so this chart can adequately keep track of any setting values this chart cares about */
  private setupSettingObservables() {
    if (this.settingsSub && !this.settingsSub.closed) this.settingsSub.unsubscribe();
    // Based on the config, grab any additional settings we'd like to be configurable via the kebab menu
    let additionalSettings: Observable<SettingValue[]> = from([]);
    if (this.configuration?.additionalSettings)
      additionalSettings = combineLatest(
        this.configuration.additionalSettings.map((x) => this.settingsService.observeSetting(x.plugin, x.settingName))
      );
    // Handle all settings into a subscription to update
    this.settingsSub = combineLatest([
      this.settingsService.observeCollectionSettings(this.configuration!.metricName),
      additionalSettings.pipe(startWith([])),
    ]).subscribe((x) => {
      // Combine all our settings and set them into the global property
      this.settings = [...x[0], ...x[1]];
    });
    this.addSubscription(this.settingsSub);
  }

  /** Creates the observable that keeps track of bookmark enabled status */
  private setupBookmarkEnabledObservable() {
    if (this.bookmarksEnabledSub && !this.bookmarksEnabledSub.closed) this.bookmarksEnabledSub.unsubscribe();
    this.bookmarksEnabledSub = this.dataStore.bookmarksEnabled.subscribe((enabled) => {
      this.bookmarksEnabled = enabled;

      if (enabled) {
        this.bookmarksTooltip = "Bookmark Creation";
      } else {
        this.bookmarksTooltip = "Bookmarks are disabled on this chart and cannot be added";
      }
    });

    this.addSubscription(this.bookmarksEnabledSub);
  }
}
