<ng-container *ngIf="setting != null">
  <!-- Overarching title and description info -->
  <div class="setting-title">{{ helpfulTitle }}</div>
  <div class="setting-description">
    {{ helpfulDescription }}
  </div>
  <!-- Render each option based on supported content -->
  <div class="option">
    <!-- Boolean -->
    <mat-slide-toggle
      class="setting-slide-toggle"
      [ngModel]="asBoolean"
      *ngIf="isBoolean"
      (change)="onSettingUpdated($event.checked)"
    >
    </mat-slide-toggle>
    <!-- String -->
    <mat-form-field appearance="fill" *ngIf="isString && setting.arrayOptions == null">
      <input matInput placeholder="Placeholder" [ngModel]="setting.value" onchange="onSettingChanged" />
    </mat-form-field>
    <!-- Number -->
    <mat-form-field appearance="fill" *ngIf="isNumeric">
      <input
        matInput
        type="number"
        placeholder="Placeholder"
        [ngModel]="setting.value"
        (onchange)="onSettingUpdated($event)"
      />
    </mat-form-field>
    <!-- Array or string with array options -->
    <shared-improved-select
      *ngIf="isArray || (isString && setting.arrayOptions != null)"
      class="select"
      #columnVisibilitySelect
      [dropdownOptions]="setting.arrayOptions ?? []"
      [selectedValue]="setting.value"
      [allowMultipleSelections]="isArray ? true : false"
      (selectedValueChanged)="onArraySettingChanged($event)"
      appearance="fill"
    >
    </shared-improved-select>
  </div>
</ng-container>
