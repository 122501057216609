<div class="improved-select-container">
  <!-- Render prefix buttons we want -->
  <div class="prefix-buttons" *ngIf="prefixButtonIcon">
    <button
      mat-icon-button
      class="button"
      (click)="prefixButtonCallback.next()"
      [matTooltip]="(prefixButtonDisabled ? prefixButtonDisabledTooltip : prefixButtonTooltip) ?? ''"
      [matTooltipPosition]="prefixButtonTooltipPosition ?? 'below'"
      [disabled]="prefixButtonDisabled ?? false"
      [configDisable]="prefixButtonConfigDirectivePath"
      [configDisabledStateMessage]="prefixButtonConfigDirectiveTooltip"
    >
      <mat-icon>{{ prefixButtonIcon }}</mat-icon>
    </button>
  </div>
  <!-- Render the actual form field for the select -->
  <mat-form-field
    [appearance]="appearance"
    [color]="color"
    class="form-field improved-dropdown-container-field improved-form-field improved-filter-form-field"
    [ngClass]="{
      'has-content': selectedValueHasContent,
      'has-clear-button': clearable && selectedValue,
      'has-prefix-children': prefixButtonIcon != null
    }"
  >
    <mat-label *ngIf="dropdownName">{{ dropdownName }}</mat-label>
    <mat-select
      #select
      [formControl]="$any(control)"
      class="improved-dropdown"
      [ngClass]="{ 'hide-arrow': hideDropdownArrow || (currentValue != null && clearable) }"
      panelClass="improved-dropdown-panel"
      (selectionChange)="selectionChange($event.value)"
      [multiple]="allowMultipleSelections"
      (closed)="filterOptions('')"
      [compareWith]="matOptionCompare"
      [disableOptionCentering]="true"
    >
      <mat-select-trigger class="trigger">
        <!-- Render either given container -->
        <ng-container *ngIf="currentValue">
          <ng-container
            select="[replace=optionDisplay]"
            *ngTemplateOutlet="
              optionDisplayRef;
              context: {
                value: currentValue,
                valueClean: cleanName(currentValue),
                multiValue: multiSelectedValue,
                isTopLevel: true
              }
            "
          ></ng-container>
        </ng-container>
        <!-- Or standard data -->
        <span *ngIf="!optionDisplayRef">{{ cleanName(currentValue) }}</span>
        <!-- Clear for selected option button -->
        <button
          *ngIf="clearable && currentValue"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          class="clear"
          (click)="clearSelected($event)"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-select-trigger>
      <!-- Filter -->
      <mat-form-field
        *ngIf="filterEnabled"
        color="accent"
        appearance="outline"
        class="improved-filter-form-field filter internal-filter"
      >
        <span class="content">
          <input
            matInput
            type="text"
            [(ngModel)]="filterValue"
            placeholder="Filter"
            (keyup)="filterOptions($any($event.target)?.value)"
            [maxLength]="maxFilterInputLength"
          />
          <span class="buttons">
            <!-- Clear button for filter -->
            <button
              *ngIf="filterValue"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              class="clear-filter"
              (click)="filterOptions('')"
            >
              <mat-icon>close</mat-icon>
            </button>
            <!-- Prefix option button, specific to the filter enabled  -->
            <button
              *ngIf="prefixOptionText"
              mat-menu-item
              [matTooltip]="prefixOptionTooltip || ''"
              [matTooltipPosition]="prefixOptionTooltipPosition || 'below'"
              (click)="prefixOptionCallback.next(!filterValue ? undefined : filterValue); select.close()"
              class="prefix-option-filter"
              [configDisable]="prefixOptionConfigDirectivePath"
              [configDisabledStateMessage]="prefixOptionConfigDirectiveTooltip"
            >
              <mat-icon *ngIf="prefixOptionIcon">{{ prefixOptionIcon }}</mat-icon>
            </button>
          </span>
        </span>
      </mat-form-field>
      <div class="option-list">
        <!-- Custom option if provided -->
        <button
          *ngIf="prefixOptionText && !filterEnabled"
          mat-menu-item
          [matTooltip]="prefixOptionTooltip || ''"
          [matTooltipPosition]="prefixOptionTooltipPosition || 'below'"
          (click)="prefixOptionCallback.next(!filterValue ? undefined : filterValue); select.close()"
          class="prefix-option"
          [configDisable]="prefixOptionConfigDirectivePath"
          [configDisabledStateMessage]="prefixOptionConfigDirectiveTooltip"
        >
          <mat-icon *ngIf="prefixOptionIcon">{{ prefixOptionIcon }}</mat-icon> {{ prefixOptionText }}
        </button>
        <!-- Auto Hide Options. Things that should be displayed in the main bar but not be shown in the option list -->
        <mat-option class="hidden-option" *ngFor="let option of displayOptions.autoHideOptions" [value]="option">
          {{ cleanName(option) }}
        </mat-option>
        <!-- Standard options -->
        <mat-option
          *ngFor="let option of displayOptions.options"
          [value]="option"
          [ngClass]="{ 'hidden-option': $any(option).shouldAutoHide }"
        >
          <!-- Render either given container -->
          <ng-container
            select="[replace=optionDisplay]"
            *ngTemplateOutlet="
              optionDisplayRef;
              context: {
                value: option,
                valueClean: cleanName(option)
              }
            "
          ></ng-container>
          <!-- Or standard data -->
          <span *ngIf="!optionDisplayRef">{{ cleanName(option) }}</span>
        </mat-option>
        <!-- No option warning -->
        <mat-option disabled class="no-options-warning" *ngIf="displayOptions.options.length === 0">
          No options available
        </mat-option>
      </div>
      <!-- Paginator to help not load so much at once -->
      <mat-paginator
        #dropdownPaginator
        *ngIf="paginatorEnabled"
        [length]="options.length"
        [pageSize]="itemsPerPage"
        [hidePageSize]="true"
        (page)="updatePageData($event)"
        aria-label="Select page"
        class="improved-paginator"
      >
      </mat-paginator>
    </mat-select>
    <mat-hint *ngIf="hint" class="hint">{{ hint }}</mat-hint>
  </mat-form-field>
</div>
