import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AngularMaterialModule } from "@tdms/frontend/modules/material/material.module";
import { SharedModule } from "@tdms/frontend/modules/shared/shared.module";
import { BarChartComponent } from "./bar/bar.component";
import { GaugeChartComponent } from "./gauge/gauge.component";
import { LineChartComponent } from "./line/line.component";
import { PieComponent } from "./pie/pie.component";
import { ChartBaseComponent } from "./shared/base/base.component";
import { CustomLegendComponent } from "./shared/legend/legend.component";
import { TimeSeriesBarChartComponent } from "./time-series-bar/time-series-bar.component";
import { WaveformChartComponent } from "./waveform/waveform.component";

/**
 * Array of supported components from the charting module
 */
const CHART_COMPONENTS = [
  PieComponent,
  BarChartComponent,
  TimeSeriesBarChartComponent,
  LineChartComponent,
  ChartBaseComponent as any, // It's an abstract class so we must apply any here
  CustomLegendComponent,
  GaugeChartComponent,
  WaveformChartComponent,
];

@NgModule({
  declarations: CHART_COMPONENTS,
  imports: [CommonModule, AngularMaterialModule, SharedModule, FormsModule, ReactiveFormsModule],
  exports: CHART_COMPONENTS,
})
export class ChartsModule {}
