/** Base settings built into TDMS Core */
export namespace BaseSettings {
  /** Even though core isn't really a plugin, TDMS tracks the settings for the core functionality under `Core` */
  export const CORE_SETTINGS_PLUGIN_NAME = "Core";
  /** Helper enum of names attached to basic settings */
  export enum Names {
    visibleSessionSessionColumns = "visible_session_columns",
    visibleUserManagementColumns = "visible_user_columns",
    colorTheme = "color_theme",
  }

  /** Helper enum of titles attached to basic settings */
  export enum HelpfulTitles {
    colorTheme = "Color Theme",
  }

  /** Helper enum of descriptions attached to basic settings */
  export enum HelpfulDescriptions {
    colorTheme = "The theme that decides coloration of the dashboard charts.",
  }
}
