<div
  class="toast-container"
  [ngClass]="classProperty"
  [@heightAnimation]="!shouldAnimate ? 'void' : animationState"
  (@heightAnimation.done)="animateDone()"
>
  <!-- Close button to acknowledge the toast -->
  <button *ngIf="canDismiss && toast.canDismiss" mat-icon-button class="close" (click)="acknowledge()">
    <mat-icon>close</mat-icon>
  </button>
  <!-- Render indication bar -->
  <span
    class="type-bar type-coloring"
    [ngClass]="{ light: themeService.applicationLevelTheme.value === 'light' }"
  ></span>
  <!-- Render indication icon -->
  <div
    [ngSwitch]="toast.type"
    class="type type-coloring"
    [ngClass]="{ light: themeService.applicationLevelTheme.value === 'light' }"
  >
    <mat-icon *ngSwitchCase="'normal'">info</mat-icon>
    <mat-icon *ngSwitchCase="'warning'">warning</mat-icon>
    <mat-icon *ngSwitchCase="'error'">report</mat-icon>
    <mat-icon *ngSwitchCase="'success'">check_circle</mat-icon>
  </div>
  <!-- Render actual message content -->
  <div class="info">
    <span class="title">{{ toast.title }}</span>
    <span class="message" [ngClass]="{ limit: mode === 'compress' }">{{ toast.message }}</span>
    <span class="date">{{ toast.prettyDate }}</span>
  </div>
</div>
