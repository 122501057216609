import { Component } from "@angular/core";
import { AccessLogTableConfiguration, DataStoreAccessLog, Utility } from "@tdms/common";
import { AccessLogService } from "@tdms/frontend/modules/data-store/services/access-log.service";
import { DataColumn } from "@tdms/frontend/modules/shared/components/tables/models";

/** Definition of internal filter reasons for access log related to internal TDMS code requests */
export const ACCESS_INTERNAL_FILTER = ["role-mapping", "waveform display", "speech-processing"];

/**
 * Access log tab displayed on the data store page.
 */
@Component({
  selector: "access-log",
  templateUrl: "./access-log.component.html",
  styleUrls: ["./access-log.component.scss"],
})
export class AccessLogComponent {
  accessLog!: DataStoreAccessLog[];

  /**
   * Bool to filter "waveform display" values form access log table
   */
  filterAccessLog: boolean = false;

  /** Columns that should be displayed for the table within this component */
  displayedColumns = [
    DataColumn.fromColumnConfig(AccessLogTableConfiguration.Columns.ID),
    DataColumn.fromColumnConfig(AccessLogTableConfiguration.Columns.Username),
    DataColumn.fromColumnConfig(
      AccessLogTableConfiguration.Columns.FileName,
      (_, obj) => obj.dataStoreFile.uncompressedFileName
    ),
    DataColumn.fromColumnConfig(
      AccessLogTableConfiguration.Columns.Plugin,
      (_, obj) => obj.dataStoreFile.matchingPlugin
    ),
    DataColumn.fromColumnConfig(AccessLogTableConfiguration.Columns.Reason),
    DataColumn.fromColumnConfig(AccessLogTableConfiguration.Columns.AccessedOn, Utility.getDateAsZulu.bind(this)),
  ];

  /** Defines the setting name and plugin for column visibility persistence within the table of this component */
  readonly columnVisibilitySetting = AccessLogTableConfiguration.SettingConfig;

  constructor(private accessLogService: AccessLogService) {
    this.accessLogClicked = this.accessLogClicked.bind(this);
  }

  ngOnInit() {
    this.loadAccessLog();
  }

  /**
   * Load our access log data from the backend.
   */
  async loadAccessLog() {
    this.filterAccessLog = !this.filterAccessLog;
    this.accessLog = await this.accessLogService.getAccessLog();
    if (this.filterAccessLog) {
      this.filterAccessLogValues();
    }
  }

  /**
   * Handle an access log being clicked.
   * TODO: Implement drill-down access log view.
   * @param entry The log that was clicked.
   */
  accessLogClicked(_entry: DataStoreAccessLog) {}

  /**
   * A function for converting a Date object to a better string format
   */
  formatTime(date: Date) {
    return Utility.getDateAsZulu(date);
  }

  /** Filters the access log to exclude internal TDMS requests */
  filterAccessLogValues() {
    this.accessLog = this.accessLog.filter((x) => !ACCESS_INTERNAL_FILTER.includes(x.reason.toLowerCase()));
  }
}
