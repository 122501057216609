import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  ChartComparisonOptions,
  ChartConfiguration,
  ChartData,
  ChartXAxisFormatters,
  ChartYAxisFormatters,
  CommonSetting,
  CommonSettingDescription,
  CommonSettingType,
  ComparisonXAxisFormatters,
  CustomTypes,
  LineChartData,
  MetricBaseSettings,
  Utility,
} from "@tdms/common";
import { LegendDisplayObject } from "@tdms/frontend/modules/charts/shared/legend/models/legend.display.object";
import { PluginBase } from "@tdms/frontend/modules/charts/shared/plugins/plugin.base";
import { ColorMap } from "@tdms/frontend/modules/material/services/themes.service";
import {
  FrontendSupportedMetricType,
  MetricServiceDataStore,
} from "@tdms/frontend/modules/metrics/components/metric-card/models/metric.configuration";
import { PluginSettingsService } from "@tdms/frontend/modules/settings/services/settings.service";
import { SettingValue } from "@tdms/frontend/modules/settings/store/value/setting.value.model";
import {
  DialogWrapperComponent,
  HelpDialogComponent,
  HelpDialogProperties,
} from "@tdms/frontend/modules/shared/components";
import { AngularCustomTypes } from "@tdms/frontend/modules/shared/models/angular.custom.types";
import { FrontendUtility } from "@tdms/frontend/modules/shared/models/utility";
import Chart, { ChartDataset, ChartOptions, ChartType, ChartTypeRegistry, PluginOptionsByType } from "chart.js/auto";
import "chartjs-adapter-date-fns";
import { cloneDeep, kebabCase, merge } from "lodash-es";
import { v4 as uuidv4 } from "uuid";
import { selectCallbackFunction } from "../../models/supported.chart.fnc.types";
import { ButtonBaseComponent, KebabToggle } from "../base-extensions/button.base";
import { LegendDisplay } from "../legend/models/legend.display";
import { BookmarkPlugin } from "../plugins/bookmark";
import { BookmarkLegendPlugin } from "../plugins/bookmark.legend";
import { PluginController } from "../plugins/controller";
import { CustomTooltipPlugin } from "../plugins/custom.tooltip";
import { NeedlePlugin } from "../plugins/needle";
import { ExtendedChartType, TDMSChartJSPlugins } from "../plugins/plugin.typing";
import { SelectPlugin } from "../plugins/select";

/**
 * Specifies what kind of update our chart update should trigger. Can be any of the following:
 *    1. `chart` - You wish for the entire chart to re render. Note that this can be quite computationally expensive for large charts.
 *    2. `plugins` - You wish to only re render the plugins. This will be much faster to execute.
 *    3. false - Don't update the charts at all
 */
export type ChartUpdate = "chart" | "plugins" | false;

/**
 * This abstracted base component allows us to apply generic structuring to all of our charts to help reduce code
 *  duplication of charts that implement this functionality.
 */
@Directive({ selector: "charts-base[colorLookup][configuration][data]" })
export abstract class ChartBaseComponent<T extends FrontendSupportedMetricType, InternalChartType extends ChartType>
  extends ButtonBaseComponent
  implements AfterViewInit, OnChanges, OnDestroy, OnInit
{
  /**
   * The chart type that the extended component is trying to render. This will be the same value as the `InternalChartType`
   */
  abstract chartType: ChartType;

  @Input() configuration!: ChartConfiguration;

  /**
   * Colors to render for these objects
   */
  @Input() colorLookup!: ColorMap;

  /**
   * A value to display before the chart title. These will be spaced to their maximum distance apart.
   */
  @Input() graphTitleStart: string | undefined;

  /**
   * A value to display after the chart title. These will be spaced to their maximum distance apart.
   */
  @Input() graphTitleEnd: string | undefined;

  /**
   * The data to render on the chart
   */
  @Input() data: T | undefined = [] as any;

  /**
   * Display's a spinner over this chart if this is true. Useful if the data is still calculating
   */
  @Input() isCalculating = false;

  /** Displays a spinner if the chart is still loading some content. This will spin before even checking calculating status */
  @Input() isLoading = false;

  /**
   * String names of the values currently displayed on the graph
   */
  @Input() customLegendValues: LegendDisplay[] | undefined;

  /**
   * A callback to be executed when a value in the legend is clicked
   */
  @Output() customLegendClickCallback = new EventEmitter<LegendDisplayObject>();

  /**
   * callback to fire when an element is selected on the chart
   */
  @Input() onSelect: selectCallbackFunction | undefined;

  /**
   * The prefix to add on the file name if this chart is exported
   */
  @Input() exportPrefix: string = "";

  /**
   * A function to call when the help button is clicked
   */
  @Input() helpButtonCallback() {
    this.dialog.open(HelpDialogComponent, {
      data: { title: this.configuration.title, body: this.configuration.helpBody } as HelpDialogProperties,
      ...DialogWrapperComponent.getDefaultOptions(),
    });
  }

  /**
   * If this is a session comparison chart
   */
  @Input() isSessionComparison = false;

  /**
   * The current canvas rendered chart by chart.js for us to display in our component
   */
  currentChart: ExtendedChartType<InternalChartType> | undefined;

  /** An array of settings that may apply to this component */
  @Input() settings: readonly (CommonSetting & CommonSettingDescription)[] = [];

  /** A reference to the canvas container for the chart */
  @ViewChild("canvasContainer") canvasContainer: ElementRef<HTMLDivElement> | undefined;
  /** The current size of canvas container */
  canvasContainerSizing: { height: number; width: number } | undefined;

  /** The absolute minimum height this chart canvas can be, in pixels. */
  minHeight: number | undefined;

  constructor(public dialog: MatDialog, private settingService: PluginSettingsService) {
    super();
  }

  /** Returns if the legend should be rendered */
  get shouldDisplayLegend() {
    return this.configuration.displayLegend && this.customLegendValues && !this.dataIsNotAvailable;
  }

  /** Returns if this chart has data available */
  get dataIsNotAvailable(): boolean {
    return ChartBaseComponent.getChartDataIsNotAvailable<ChartBaseComponent<T, InternalChartType>>(this);
  }

  /** Returns if the chart data is not available due to conditions like calculating or missing data */
  static getChartDataIsNotAvailable<T extends MetricServiceDataStore | ChartBaseComponent<any, any>>(component: T) {
    // isCalculating is actually handled by another data set. While that is technically "no data" it's important to allow the spinner to handle the display.
    if (component.isCalculating) return false;
    else if (component.data == null) return true;
    else if (Array.isArray(component.data) && component.data.length === 0) return true;
    else if (!Array.isArray(component.data) && component.data?.value == null) return true;
    // Check series data
    else if (
      Array.isArray(component.data) &&
      component.data.length !== 0 &&
      component.data.filter((x) => x instanceof LineChartData && x.series.length === 0).length == component.data.length
    )
      return true;
    else return false;
  }

  ngOnInit(): void {
    // Initialize our buttons once @Inputs() are resolved
    this.headerButtons.push({
      id: "help-button",
      icon: "help",
      tooltip: "Help",
      isVisible: () => this.configuration.showHelp && this.helpButtonCallback != null,
      click: this.helpButtonCallback,
    });
    this.kebabButtons.push({
      id: "export-button",
      icon: "download",
      title: "Export",
      tooltip: "Export chart contents",
      isVisible: () => this.configuration.exportAllowed,
      enabled: () => this.data != null,
      click: this.exportChartData,
    });
    this.populateQuickOptions();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    // Destroy the chart to dump any listeners
    this.currentChart?.destroy();
  }

  /** Re-renders all plugins individually */
  protected triggerPluginsReRender() {
    const plugins = this.currentChart?.config.plugins as Array<PluginBase<any, any>> | undefined;
    plugins?.forEach((x) => {
      x.onPluginUpdate("forced");
      x.render();
    });
  }

  ngOnChanges(changes: AngularCustomTypes.BaseChangeTracker<ChartBaseComponent<any, any>>) {
    const shouldUpdate = this.shouldChartUpdate(changes as any);
    if (shouldUpdate) {
      // Update the entire chart on specific cases
      if (shouldUpdate === "chart") this.currentChart?.update();
      // Else update plugins if available
      else if (shouldUpdate === "plugins") this.triggerPluginsReRender();
    }
    // Update setting buttons by removing them and re adding them if the settings change
    if (changes.settings && !changes.settings.firstChange) this.populateQuickOptions();
    setTimeout(() => this.onResize()); // Start off our resize outside normal change detect
  }

  /** Requests for a chart option update and merges content together */
  updateChartOptions() {
    if (this.currentChart) {
      const originalOpts = this.currentChart.config.options;
      const newOpts = this.getBaseChartOptions();
      this.currentChart.options = Utility.mergeDeep(originalOpts, newOpts);
    }
  }

  /**
   * This function is used to determine if the chart should be updated based on given change options. You should update any chart options
   *  as required and return a value based from {@link ChartUpdate}
   */
  shouldChartUpdate(changes: AngularCustomTypes.BaseChangeTracker<ChartBaseComponent<any, any>>): ChartUpdate {
    let chartUpdate: ChartUpdate = false;
    if (this.currentChart && !this.isCalculating) {
      const dataToUseForProcess = changes.data == null ? this.data : changes.data.currentValue;
      // Handle data updates
      if (changes.data?.currentValue || changes.customLegendValues) {
        if (dataToUseForProcess) this.currentChart.data.datasets = this.getChartData(dataToUseForProcess);
        // Update the labels
        if (dataToUseForProcess) this.currentChart.data.labels = this.getChartLabels(dataToUseForProcess);
        this.updateChartOptions();
        chartUpdate = "chart";
      }

      // Handle option updates
      if (changes.data || changes.applicationTheme) {
        // We should re render options as well
        this.updateChartOptions();
        chartUpdate = "chart";
      }

      // Handle color changes. Only update if it hasn't already been updates this run
      if (changes.colorLookup && !changes.data) {
        if (dataToUseForProcess) this.currentChart.data.datasets = this.getChartData(dataToUseForProcess);
        chartUpdate = "chart";
      }
    }
    return chartUpdate;
  }

  ngAfterViewInit() {
    this.currentChart = this.generateChart();
    setTimeout(() => this.onResize()); // Start off our resize outside normal change detect
  }

  /**
   * Returns the x axis formatter function the configuration wishes to use
   */
  protected getXAxisFormatter(chartConfig: ChartConfiguration = this.configuration): { (val: any): string } {
    return ChartXAxisFormatters[chartConfig.xAxisFormat];
  }

  /**
   * Returns the x axis formatter function the configuration wishes to use
   */
  protected getComparisonXAxisFormatter(chartConfig: ChartComparisonOptions): { (startVal: any, val: any): string } {
    return ComparisonXAxisFormatters[chartConfig.xAxisFormatOverride!];
  }

  /**
   * Given a configuration, returns the function intended to format the y axis of the chart
   */
  protected getYAxisFormatter(chartConfig: ChartConfiguration = this.configuration): { (val: any): string } {
    return ChartYAxisFormatters[chartConfig.yAxisFormat];
  }

  /**
   * Given a name value, returns a color for it.
   */
  getColor(value: any) {
    return this.colorLookup?.find((mapping) => mapping.name.toLowerCase() == value.toLowerCase())?.value;
  }

  /**
   * Takes our current data and exports it to a .csv file for the user to save
   */
  exportChartData() {
    if (this.data) {
      const fileName = `${this.exportPrefix}${kebabCase(this.configuration.title)}`;
      let csvData: string;
      if (Array.isArray(this.data) && this.data.length > 0)
        csvData = (this.data[0].constructor as typeof ChartData).toCSV(this.data);
      else {
        csvData = (this.data.constructor as typeof ChartData).toCSV(this.data);
      }
      FrontendUtility.saveFile("csv", csvData, fileName);
    }
  }

  /**
   * Returns the chart Id for the canvas that will display the chart
   */
  chartId = `tdms-chart-${uuidv4()}`;
  /** Id for the plugins canvas */
  chartPluginId = this.chartId + "-plugin";

  /**
   * Returns the DOM element to render the chart into
   */
  get chartElement() {
    return document.getElementById(this.chartId) as HTMLCanvasElement;
  }

  /** Handles what to do when this component resizes */
  @HostListener("window:resize") onResize() {
    if (this.canvasContainer)
      this.canvasContainerSizing = {
        width: this.canvasContainer.nativeElement.clientWidth,
        height: this.canvasContainer.nativeElement.clientHeight,
      };
  }

  /**
   * Generates the chart to display on the canvas and returns it's reference
   */
  generateChart() {
    const pluginCanvas = document.getElementById(this.chartPluginId) as HTMLCanvasElement;
    // New style plugins to load. NOTE: ORDER IS IMPORTANT HERE
    const plugins = [
      new PluginController.Plugin(pluginCanvas),
      new NeedlePlugin.Plugin(pluginCanvas),
      new BookmarkPlugin.Plugin(pluginCanvas),
      new SelectPlugin.Plugin(pluginCanvas),
      new CustomTooltipPlugin.Plugin(pluginCanvas),
      new BookmarkLegendPlugin.Plugin(pluginCanvas),
    ];
    // Create the chart
    const chart = new Chart<InternalChartType>(this.chartElement, {
      type: this.chartType as InternalChartType,
      data: {
        labels: this.getChartLabels(this.data || ([] as any)),
        datasets: this.data == null ? [] : this.getChartData(this.data),
      },
      options: this.getBaseChartOptions() as ChartOptions<InternalChartType>,
      plugins: plugins,
    }) as ExtendedChartType<InternalChartType>;
    chart.tdmsType = this.configuration.type;
    chart.tdmsName = this.configuration.metricName;
    return chart;
  }

  /**
   * This function returns the chart data intended to display on the chart at any point in time.
   */
  abstract getChartData(dataSet: T): ChartDataset<InternalChartType, any>[];

  /**
   * The labels that should be displayed on the chart with the corresponding data.
   */
  getChartLabels(_dataSet: T): (Date | string | number)[] {
    return this.customLegendValues?.flatMap((x) => x.options.filter((z) => z.displayed).map((z) => z.name)) || [];
  }

  /**
   * Override callback so that charts extending this component can implement their own options
   *  as they see fit for their specific types.
   * @param coreOptions The default set of options
   */
  abstract chartOptionOverrides(coreOptions: ChartOptions<InternalChartType>): ChartOptions<InternalChartType>;

  /** Returns the x formatting function for display */
  get xFormatter() {
    return this.getXAxisFormatter(this.configuration);
  }

  /** Returns the y formatting function for display */
  get yFormatter() {
    return this.getYAxisFormatter(this.configuration);
  }

  /**
   * Returns the generic chart options that all components will utilize. This will be re rendered when data
   *  changes so you may use this to use main data for determination.
   */
  getBaseChartOptions() {
    const internalThis = this;
    const coreOptions = {
      plugins: {
        customTooltip: {
          enabled: true,
          snappingInteractionMode: this.configuration.showLines ? "index" : "x",
        },
        tooltip: {
          enabled: false, // We use a custom tooltip
        },
        legend: {
          display: false,
        },
      } as TDMSChartJSPlugins & PluginOptionsByType<keyof ChartTypeRegistry>,

      animation: false,
      responsive: true,
      maintainAspectRatio: false,

      showLine: this.configuration.showLines || false,

      elements: {
        point: {
          hoverRadius: !this.configuration.showLines ? 7 : 0,
          radius: !this.configuration.showLines ? 5 : 0,
        },
      },
      layout: {
        padding: {
          right: 20,
        },
      },

      scales: {
        // X-Axis configuration
        x: {
          display: this.configuration.showXAxis,
          title: {
            text: this.configuration.xAxisLabel,
            display: this.configuration.showXAxisLabel,
            ...this.scaleTitleStyling,
          },
          grid: {
            ...this.gridStyling,
          },
          ticks: {
            ...this.tickStyling,
            count: 10,
            maxRotation: 0,
            display: !this.isCalculating,
            callback: function (initialVal, tickIndex, ticks) {
              let val: string | Date;
              // Adjust val for time formats
              if (this.type === "time") {
                val = new Date();
                val.setTime(ticks[tickIndex].value);
              } else val = this.getLabelForValue(initialVal as any);
              return internalThis.xFormatter(val);
            },
          },
        },
        // Y-Axis configuration
        y: {
          display: this.configuration.showYAxis,
          title: {
            text: this.configuration.yAxisLabel,
            display: this.configuration.showYAxisLabel,
            ...this.scaleTitleStyling,
          },
          grid: {
            ...this.gridStyling,
          },
          ticks: {
            ...this.tickStyling,
            display: true,
            callback: function (val) {
              const labelVal = this.getLabelForValue(val as any) as any;
              const valToUse = isNaN(parseFloat(labelVal)) ? labelVal : val;
              return internalThis.yFormatter(valToUse);
            },
          },
          beginAtZero: true,
          min: this.configuration.yScaleMin,
          max: this.configuration.yScaleMax,
        },
      },
    } as ChartOptions;
    // Assign requested changes
    Utility.mergeDeep(coreOptions, this.chartOptionOverrides(coreOptions as ChartOptions<InternalChartType>));
    return coreOptions as any as ChartOptions<InternalChartType>;
  }

  /**
   * This function is used to directly update the options of a charts plugin without having to re render the function. This can
   *  assist in reducing `update` calls of the chart causing slowdowns.
   *
   * **Note: You should minimize use of this as it can cause issues during re renders if updating something that doesn't revalidate itself**
   * @param plugin The name of the plugin to update
   * @param newOpts The new options you would like to update
   */
  updateOptionsDirect<PluginKey extends CustomTypes.PropertyNames<TDMSChartJSPlugins, any>>(
    plugin: PluginKey,
    newOpts: TDMSChartJSPlugins[PluginKey]
  ) {
    // Update relevant locations
    if (this.currentChart) {
      const chart = this.currentChart as any as ExtendedChartType<InternalChartType>;
      chart[plugin] = merge(chart[plugin], newOpts);
      chart.config.options.plugins[plugin] = merge(chart.config.options.plugins[plugin], newOpts);
    }
  }

  /**
   * Given the data set, filters out only enabled values according to the legend and returns them
   */
  filterDataset<T extends FrontendSupportedMetricType>(dataSet: T) {
    return (dataSet as Array<ChartData>).filter((x) => {
      if (this.customLegendValues == null) return true;
      const matchingOption = LegendDisplay.findSpecificElement(this.customLegendValues, x.name);
      return matchingOption == null || matchingOption.element.displayed;
    }) as T;
  }

  /** Populates quick options into the kebab menu for some settings */
  populateQuickOptions() {
    const booleanSettings = this.settings
      .filter(
        (x) =>
          // Grab all settings that are of boolean type
          x.settingType === CommonSettingType.boolean &&
          // Ignore the `chartEnabled` setting as that would be confusing if they disabled the chart and it just disappeared
          x.name !== MetricBaseSettings.Names.chartEnabled
      )
      .sort((a, b) => a.name.localeCompare(b.name));
    for (let setting of booleanSettings) {
      this.updateOrAddButtonSettings<KebabToggle>("kebabButtons", {
        id: `setting-${setting.name}`,
        icon: "toggle",
        title: setting.helpfulTitle,
        tooltip: setting.helpfulDescription,
        toggleStatus: setting.value,
        click: () => {
          // Invert setting value
          const localSetting = cloneDeep({ ...setting }) as SettingValue;
          localSetting.value = !localSetting.value;
          // Tell service of updated setting
          this.settingService.updateSpecificSetting(localSetting, localSetting.collectionId);
        },
      });
    }
  }
}
