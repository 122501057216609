import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Notification, NotificationTopics, TDMSWebSocketMessage, User, WebSocketCommunication } from "@tdms/common";
import { WebSocketService } from "@tdms/frontend/modules/communication/services/websocket.service";
import { NotificationState } from "@tdms/frontend/modules/notification/models/notification.state";
import { NotificationActions } from "@tdms/frontend/modules/notification/models/store/notification.action";
import { selectAllNotificationsFromState } from "@tdms/frontend/modules/notification/models/store/notification.selector";
import { Service } from "@tdms/frontend/modules/shared/services/base.service";
import { firstValueFrom } from "rxjs";

/**
 * This service provides the frontend with the ability to display notifications to the user
 */
@Injectable({ providedIn: "root" })
export class NotificationService extends Service {
  /** The current authenticated user to the websocket */
  private currentAuthenticatedUser: User | undefined;

  constructor(private store: Store<NotificationState>, private wsService: WebSocketService) {
    super();
  }

  override async onUserChanged(user: User, _wasJWTLogin?: boolean) {
    this.currentAuthenticatedUser = user;
    this.store.dispatch(NotificationActions.empty({}));
    await this.getAllByUser(user);
  }

  override async onUserLoggedOut() {
    this.currentAuthenticatedUser = undefined;
    this.store.dispatch(NotificationActions.empty({}));
  }

  /**
   * Given some parameters, opens a new notification for display within TDMS.
   *
   * This actually takes this notification, informs the backend of it, then when
   *  the backend resolves it will display the notification. This is so the backend
   *  is also tracking the notifications for persistance.
   * @param title The title to display at the top of the notification
   * @param message The message to display as the content to the notification
   * @param type The notification type so we can apply some theme capability.
   * @param autoCloseTimeout A timer to auto close this notification. This only applies
   * @param canDismiss If this notification can be dismissed by the user.
   */
  async open(
    message: string | undefined = "Notification",
    type: Notification["type"] = "normal",
    canDismiss = true,
    title?: string
  ) {
    if (message == null) return; // Don't notify blank messages
    // If we already have an open notification, acknowledge it before opening another
    let notification = Notification.fromPlain({ message, type, title, canDismiss });
    notification.autoSetTitle();
    // Only track notifications if we're authenticated
    if (this.currentAuthenticatedUser != null) notification = await this.trackNotification(notification);
    else {
      notification.id = new Date().getTime();
      // Dispatch our new notification for display
      this.store.dispatch(NotificationActions.add({ data: [notification] }));
    }
    return notification;
  }

  /** Acknowledges the current notification with the given mode. Please see {@link Notification.acknowledgement} for more info. */
  async acknowledge(notification: Notification, mode: Notification["acknowledgement"], publishUpdate = true) {
    notification = Notification.fromPlain(notification); // Don't update the original
    notification.acknowledgement = mode;
    // If we have a user, we can execute the update to the backend
    if (this.currentAuthenticatedUser != null && publishUpdate) {
      /** Send the update request. The store will handle the update in {@link onUpdateReceived} */
      await this.updateNotification(notification);
    } else {
      // Else notifications will desync from a user and happen locally
      this.store.dispatch(NotificationActions.update({ data: notification }));
    }
  }

  /**
   * Given a notification, schedules a time for it to be auto acknowledged. This has no sync process with the backend and should really only be used for frontend
   *  specific messages. If the frontend is disconnected or refreshed, this will not auto complete.
   * @param timeout How long, in milliseconds, until the notification should be dismissed.
   */
  scheduleAcknowledgement(notification?: Notification, timeout = 5000) {
    if (notification) setTimeout(() => this.acknowledge(notification, "user", false), timeout);
  }

  /** Marks all notifications for the current user as read */
  async markAllRead() {
    const notifications = await firstValueFrom(this.store.select(selectAllNotificationsFromState));
    if (notifications.some((x) => x.acknowledgement !== "user"))
      await this.wsService.sendAndReceive(new TDMSWebSocketMessage(NotificationTopics.markAllRead));
  }

  /** Listens for mass update requests from the backend */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<Notification[]>>(NotificationTopics.massUpdate)
  protected async markAllReadReceived(data: TDMSWebSocketMessage<Notification[]>) {
    const notifications = Notification.fromPlainArray(data.payload);
    this.store.dispatch(NotificationActions.updatemany({ data: notifications }));
  }

  /** Handles removing notifications when the backend requests it. These are normally only send to the current queue */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<Notification>>(NotificationTopics.delete)
  protected async onDeleteReceived(data: TDMSWebSocketMessage<Notification>) {
    this.store.dispatch(NotificationActions.delete({ id: data.payload.id }));
  }

  /** Handles updating notifications as requested */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<Notification>>(NotificationTopics.update)
  protected async onUpdateReceived(data: TDMSWebSocketMessage<Notification>) {
    const notification = Notification.fromPlain(data.payload);
    this.store.dispatch(NotificationActions.update({ data: notification }));
  }

  /** Handles inserting new notifications */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<Notification>>(NotificationTopics.insert)
  protected async onInsertReceived(data: TDMSWebSocketMessage<Notification>) {
    // If a notification is already open, close it, if necessary
    if (data.payload.acknowledgement == null) {
      const notifications = await firstValueFrom(this.store.select(selectAllNotificationsFromState));
      const openNotifications = Notification.fromPlainArray(notifications.filter((x) => x.acknowledgement == null));
      openNotifications.forEach((z) => (z.acknowledgement = "auto"));
      this.store.dispatch(NotificationActions.updatemany({ data: openNotifications }));
    }
    const notification = Notification.fromPlain(data.payload);
    this.store.dispatch(NotificationActions.add({ data: [notification] }));
  }

  /** Updates the given notification. Note that the backend only accepts some properties being updated. */
  private async updateNotification(notification: Notification) {
    return Notification.fromPlain(
      (
        await this.wsService.sendAndReceive<Notification>(
          new TDMSWebSocketMessage(NotificationTopics.update, undefined, notification)
        )
      ).payload
    );
  }

  /** Tells the backend this user has a new notification to track (insert) */
  private async trackNotification(notification: Notification) {
    return Notification.fromPlain(
      (
        await this.wsService.sendAndReceive<Notification>(
          new TDMSWebSocketMessage(NotificationTopics.insert, undefined, notification)
        )
      ).payload
    );
  }

  /** Gets all the notifications for the given user */
  private async getAllByUser(_user: User) {
    const result = await this.wsService.sendAndReceive<Notification[]>(
      new TDMSWebSocketMessage(NotificationTopics.getAllByUser)
    );
    this.store.dispatch(NotificationActions.add({ data: Notification.fromPlainArray(result.payload) }));
  }
}
