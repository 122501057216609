import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  BackgroundAudioComponent,
  BG_AUDIO_INJECTION,
} from "@tdms/frontend/modules/audio/components/background-audio/background-audio.component";
import AudioService from "@tdms/frontend/modules/audio/services/audio.service";
import { TranscriptionState } from "@tdms/frontend/modules/audio/store/transcription.state";
import { ColorThemeService } from "@tdms/frontend/modules/material/services/themes.service";
import { MetricGridDataStore } from "@tdms/frontend/modules/metrics/components/metric-grid/models/metric-grid.configuration";
import { MetricGridService } from "@tdms/frontend/modules/metrics/services/metric.grid.service";
import { selectSpecificMetricConfig } from "@tdms/frontend/modules/metrics/store/metric.selector";
import { firstValueFrom } from "rxjs";
import { BackgroundAudioBase } from "../background-audio/bg-audio.base";

@Component({
  selector: "audio-track",
  templateUrl: "./audio-track.component.html",
  styleUrls: ["./audio-track.component.scss"],
})
export class AudioTrackComponent extends BackgroundAudioBase implements OnInit {
  /** Chart data for the waveform */
  metricGrid: MetricGridDataStore | undefined;

  constructor(
    @Inject(BG_AUDIO_INJECTION) bgAudioPlayer: BackgroundAudioComponent,
    private store: Store<TranscriptionState>,
    public audioService: AudioService,
    public themeService: ColorThemeService,
    private metricGridService: MetricGridService,
    cdr: ChangeDetectorRef
  ) {
    super(bgAudioPlayer, cdr);
  }

  async ngOnInit(): Promise<void> {
    await this.loadGridDataStore();
  }

  /** Loads the metric grid with the waveform we want to display */
  async loadGridDataStore() {
    // Locate the configuration for the waveform
    const config = (await firstValueFrom(this.store.select(selectSpecificMetricConfig("AudioWaveform"))))?.clone();
    if (config) {
      // Modify the config slightly
      config.showXAxis = false;
      config.dashboard.enabled = true;
      // Create the observables for the grid
      this.metricGrid = await this.metricGridService.observeMetricDataForSession(
        [config],
        undefined,
        this.audioService.getPlaybackBookmark(this.bgAudioPlayer),
        false
      );
    }
  }

  /**
   * Changes notReady message depending on various states
   */
  get notReadyMessage(): string {
    return "Loading Audio Playback";
  }
}
