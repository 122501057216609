<div class="metric-grid-outer" #chartContainer *ngIf="dataStore != null">
  <!-- Render the overarching session timeline -->
  <div class="sticky" [ngClass]="{ 'has-content': shouldDisplayTimeline && timelineCard }">
    <timeline
      #chart
      *ngIf="shouldDisplayTimeline && timelineCard"
      [card]="timelineCard"
      [zoomDomainUpdater]="zoomDomainUpdater"
      [gridLevelBookmarks]="gridLevelBookmarks"
      [bookmarkTypes]="bookmarkTypes"
      [bookmarkDrawingStatus]="bookmarkDrawingStatus"
      [onAddBookmarkClicked]="onAddBookmarkClicked"
      [onUpdateBookmarkClicked]="onUpdateBookmarkClicked"
      [theme]="currentApplicationTheme"
      [isSessionComparison]="isSessionComparison"
      [gridStartDate]="gridStartDate"
      [gridEndDate]="gridEndDate"
    ></timeline>
  </div>

  <!-- Render the main grid of metric cards -->
  <div class="metric-grid-inner" #chartInnerContainer>
    <!-- Render each individual card -->
    <ng-container *ngFor="let chart of currentCards; let i = index; trackBy: getElementTracker">
      <metric-card
        *ngIf="chart.lastEnabledStatus"
        [dataStore]="chart"
        [getExportPrefix]="getExportPrefix"
        [gridLevelBookmarks]="gridLevelBookmarks"
        [bookmarkTypes]="bookmarkTypes"
        [bookmarkDrawingStatus]="bookmarkDrawingStatus"
        [onAddBookmarkClicked]="onAddBookmarkClicked"
        [onUpdateBookmarkClicked]="onUpdateBookmarkClicked"
        [zoomDomainUpdater]="zoomDomainUpdater"
        [ngClass]="{
          'has-scrollbar': elementHasScrollbar,
          half: chart!.configuration.sizing === 'half',
          'has-next-half': hasNextHalf(i),
          'no-data': chart.metric?.dataIsNotAvailable
        }"
        [applicationTheme]="currentApplicationTheme"
        [isSessionComparison]="isSessionComparison"
        [totalChartTimeFrame]="[gridStartDate, gridEndDate]"
      >
      </metric-card>
    </ng-container>
  </div>
</div>
