import { Injectable } from "@angular/core";
import {
  aggregateToMax,
  AvailableMetricNamesType,
  ChartConfiguration,
  CustomTypes,
  DataStoreFileUpdate,
  DataStoreMetric,
  DataStoreTopics,
  MetricPluginDescription,
  PieChartData,
  TDMSWebSocketMessage,
  User,
  WebSocketCommunication,
} from "@tdms/common";
import { LegendDisplay } from "@tdms/frontend/modules/charts/shared/legend/models/legend.display";
import { LegendDisplayObject } from "@tdms/frontend/modules/charts/shared/legend/models/legend.display.object";
import { WebSocketService } from "@tdms/frontend/modules/communication/services/websocket.service";
import { ColorThemeService } from "@tdms/frontend/modules/material/services/themes.service";
import {
  MetricCardDataStore,
  MetricServiceDataStore,
} from "@tdms/frontend/modules/metrics/components/metric-card/models/metric.configuration";
import { LegendService } from "@tdms/frontend/modules/metrics/services/legend.service";
import { ConfigService } from "@tdms/frontend/modules/settings/services/config.service";
import { Service } from "@tdms/frontend/modules/shared/services/base.service";
import { BehaviorSubject, map, of } from "rxjs";

/**
 * A service to collect and provide data store metric information for display on the Data Store Home tab.
 */
@Injectable({ providedIn: "root" })
export default class DataStoreMetricService extends Service {
  /**
   * The space per plugin chart configuration.
   * These are hard coded on the front end because it's necessarily separated
   * from the metric plugins and metric service.
   * As a result, the chart configurations cannot be retrieved from the backend dynamically.
   */
  static readonly SPACE_PER_PLUGIN_CONFIG = new ChartConfiguration({
    type: "pie",
    metricName: "space-per-plugin" as AvailableMetricNamesType,
    title: "Space Used By Plugin (MB)",
    getBySessionIdQueue: "space-per-plugin",
    position: 0,
    exportAllowed: false,
    sizing: "half",
  });

  /**
   * The total files per plugin chart configuration.
   */
  static readonly TOTAL_FILES_PER_PLUGIN = new ChartConfiguration({
    type: "pie",
    metricName: "total-files-by-plugin" as AvailableMetricNamesType,
    title: "Total Files By Plugin",
    getBySessionIdQueue: "total-files-by-plugin",
    position: 0,
    exportAllowed: false,
    sizing: "half",
  });

  /**
   * A subject used to help track updates in the metrics displayed for the data store based on data store content
   */
  private dataStoreMetricSubject = new BehaviorSubject<DataStoreMetric>(new DataStoreMetric());

  constructor(
    private wsService: WebSocketService,
    private themeService: ColorThemeService,
    private configService: ConfigService,
    private legendService: LegendService
  ) {
    super();
  }

  override async onUserChanged(_: User): Promise<void> {
    if (this.configService.pluginIsEnabled("DataStore")) {
      const metrics = await this.getDataStoreMetrics();
      this.dataStoreMetricSubject.next(metrics);
    }
  }

  /**
   * Given a configuration and the property to use as data, generates a metric card data store
   *  so this can be displayed onto a metric grid.
   * @param valueUpdateCallback A callback to fire if you wish to change the value of the result before it's used
   */
  getPluginMetricByConfig(
    config: ChartConfiguration,
    property: CustomTypes.PropertyNames<MetricPluginDescription, number>,
    valueUpdateCallback?: { (value: number): number },
    maxDisplayedValues = 5
  ) {
    return this.dataStoreMetricSubject.pipe(
      map((metrics) => {
        const data = this.getPieChartData(
          metrics,
          (pluginName, description) =>
            new PieChartData(
              pluginName,
              valueUpdateCallback ? valueUpdateCallback(description[property]) : description[property]
            ),
          maxDisplayedValues
        );
        /// Map the color map for the selected metric data.
        const colorMap = this.themeService.observeColorsFor(data);
        return new MetricCardDataStore(
          new MetricServiceDataStore(data, [], false),
          config,
          colorMap,
          of(true),
          of(true),
          this.generateChartLegend(data)
        );
      })
    );
  }

  /**
   * Helper function to generate a legend display for the given pie chart data set.
   * @param dataSet The data set to generate the legend for
   * @returns
   */
  generateChartLegend(dataSet: PieChartData[]) {
    const legend = [
      new LegendDisplay({
        options: dataSet.map((data) => {
          return new LegendDisplayObject({
            name: data.name,
            displayed: true,
          });
        }),
      }),
    ];
    return this.legendService.toBehavior(legend);
  }

  /**
   * Grab data store metric and convert to Pie Chart data using the given selector.
   * @param selector The data to grab from each data store metric entry for the pie chart.
   * @param maxDisplayed The maximum number of pie chart slices to display. Data will be sliced to at most this many entries.
   * @returns
   */
  private getPieChartData(
    dataStoreMetric: DataStoreMetric,
    selector: (pluginName: string, pluginDescription: MetricPluginDescription) => PieChartData,
    maxDisplayed?: number
  ): PieChartData[] {
    let data = Object.keys(dataStoreMetric.metricByPlugin).map((pluginName) => {
      const description = dataStoreMetric.metricByPlugin[pluginName];
      return selector(pluginName, description);
    });
    data = data.filter((row) => row.value != 0);
    if (maxDisplayed != null) data = aggregateToMax(data, maxDisplayed);

    return data;
  }

  /**
   * Load the data store metric payload from the backend and cache it in our service.
   * We do this since both the spaceUsedByPlugin and totalFilesByPlugin use this response data.
   */
  private async getDataStoreMetrics(): Promise<DataStoreMetric> {
    const response = await this.wsService.sendAndReceive<DataStoreMetric>(
      new TDMSWebSocketMessage(DataStoreTopics.getMetrics)
    );
    return response.payload;
  }

  /**
   * This message is echoed by the backend and allows us to
   * receive update events about the recycle bin asynchronously.
   * @param _data The updated recycle bin data.
   */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<DataStoreFileUpdate[]>>(DataStoreTopics.update)
  async receiveBackendMetricUpdates(data: TDMSWebSocketMessage<DataStoreFileUpdate[]>) {
    if (data.payload.find((update) => update.type == "add" || update.type == "delete") != null) {
      // Re-request the data store metrics whenever files are added or permanently deleted.
      this.dataStoreMetricSubject.next(await this.getDataStoreMetrics());
    }
  }
}
