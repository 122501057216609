import { Session } from "../../session-data";
import { TableColumnConfiguration, TableConfiguration } from "../../settings/table/settings";
import { Tag } from "../../tag";
import { BaseSettings } from "../settings.base";

/** This namespace contains settings related to rendering the session selection table */
export class SessionSelectionTableConfiguration extends TableConfiguration {
  static override readonly SettingConfig = {
    pluginName: BaseSettings.CORE_SETTINGS_PLUGIN_NAME,
    settingName: BaseSettings.Names.visibleSessionSessionColumns,
  };

  static override readonly Columns = {
    Name: new TableColumnConfiguration<Session, string>("name"),
    Start: new TableColumnConfiguration<Session, Date>("startDate"),
    End: new TableColumnConfiguration<Session, Date>("endDate"),
    Tags: new TableColumnConfiguration<Session, Tag[]>("tags"),
  };
}
