import { Component, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { BookmarkType } from "@tdms/common";
import { BookmarkTypeService } from "@tdms/frontend/modules/bookmark/services/bookmark.type.service";
import { ConfigService } from "@tdms/frontend/modules/settings/services/config.service";
import {
  ConfirmationDialogComponent,
  ConfirmationDialogProperties,
  DialogWrapperComponent,
} from "@tdms/frontend/modules/shared/components";

/** Properties for the dialog popup regarding a bookmark type */
export interface BookmarkTypeDialogProperties {
  data: BookmarkType;
  /** Used so we can determine if a duplicate exists and just use that one instead */
  totalData: BookmarkType[];
  /** Determines if we should treat this as an edit or creation */
  type: "edit" | "create";
  /** Called after we successfully update or edit a data element */
  onComplete: { (data: BookmarkType): void } | undefined;
  /** Called if this element is deleted */
  onDelete: { (data: BookmarkType): void } | undefined;
}

/** A component used within dialogs to allow editing or creation of bookmark types. */
@Component({
  selector: "bookmark-type-info",
  templateUrl: "./bookmark-type-info.component.html",
  styleUrls: ["./bookmark-type-info.component.scss"],
})
export class BookmarkTypeInfoComponent {
  /** Tracks if we've submitted a creation request or not */
  submitted = false;
  form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public properties: BookmarkTypeDialogProperties,
    private bookmarkTypeService: BookmarkTypeService,
    private dialogRef: MatDialogRef<any>,
    public configService: ConfigService,
    private dialog: MatDialog
  ) {
    this.form = new FormGroup({
      name: new FormControl(
        {
          value: properties.data.name,
          disabled:
            properties.type === "create"
              ? !configService.configData?.bookmark.allowTypeCreation
              : !configService.configData?.bookmark.allowTypeEditing,
        },
        [Validators.required]
      ),
      color: new FormControl({ value: properties.data.color, disabled: false }, [Validators.required]),
    });
    // Disable form if config says to do so
    if (
      properties.type === "create"
        ? !configService.configData?.bookmark.allowTypeCreation
        : !configService.configData?.bookmark.allowTypeEditing
    )
      this.form.disable();
  }

  /** Returns if the values have actually changed. If we are not in edit mode, will always resolve true. */
  get editValuesHaveChanged() {
    return this.properties.type !== "edit" || !this.dataFromForm.equals(this.properties.data);
  }

  get maxNameLength() {
    return BookmarkType.MAX_NAME_LENGTH;
  }

  get dataFromForm() {
    const data = this.properties.data.clone();
    data.name = this.form.controls["name"].value;
    data.color = this.form.controls["color"].value;
    return data;
  }

  async submit() {
    if (!this.editValuesHaveChanged || !this.form.valid) return;
    let data = this.dataFromForm;
    // Check if a matching data already exists and just complete with that instead.
    const existingData = BookmarkType.checkUnique(this.properties.totalData, data);
    if (existingData != null) {
      data = existingData;
    } else {
      this.submitted = true;
      // Ask the service to create the data
      const result = await (this.properties.type === "create"
        ? this.bookmarkTypeService.add(data)
        : this.bookmarkTypeService.update(data));
      this.submitted = false;
      if (!result.success) return; // Don't close the dialog on failure
      else data = BookmarkType.fromPlain(result.payload);
    }
    this.dialogRef.close();
    this.properties.onComplete?.call(this, data);
  }

  delete() {
    this.dialogRef.close();
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        description:
          "Are you sure you want to remove this bookmark type? This will remove all bookmarks in the database using this type. This cannot be undone!",
        confirmButtonText: "Remove",
        confirmClickCallback: () => {
          this.properties.onDelete?.call(this, this.properties.data);
          this.bookmarkTypeService.delete(this.properties.data);
          this.dialogRef.close();
        },
        cancelClickCallback: () => {
          this.dialog.open(BookmarkTypeInfoComponent, {
            data: this.properties,
            ...DialogWrapperComponent.getDefaultOptions(),
          });
        },
      } as Partial<ConfirmationDialogProperties>,
      ...DialogWrapperComponent.getDefaultOptions(),
    });
  }
}
