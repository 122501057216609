import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { StoreModule } from "@ngrx/store";
import { AngularMaterialModule } from "@tdms/frontend/modules/material/material.module";
import { SharedModule } from "@tdms/frontend/modules/shared/shared.module";
import { NotificationMenuComponent } from "./components/menu/menu.component";
import { ToastOverlayComponent } from "./components/toast-overlay/toast-overlay.component";
import { ToastComponent } from "./components/toast/toast.component";
import { notificationReducer } from "./models/store/notification.reducer";
import { NotificationService } from "./services/notification.service";

const COMPONENTS = [ToastComponent, ToastOverlayComponent, NotificationMenuComponent];

/**
 * A Module that provides audio based displays and capabilities
 */
@NgModule({
  declarations: COMPONENTS,
  imports: [
    CommonModule,
    SharedModule,
    AngularMaterialModule,
    MatSnackBarModule,
    StoreModule.forFeature("notification", notificationReducer),
  ],
  providers: [NotificationService],
  exports: COMPONENTS,
})
export class NotificationModule {}
