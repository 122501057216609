import { Session } from "../../../../session-data";
import { TableColumnConfiguration, TableConfiguration } from "../../../../settings/table/settings";
import { User } from "../../../../user";
import { DataStoreFile } from "../../data.store";
import { DataStoreSettings } from "../settings";

/** This configuration defines table options related to the Recycle Bin table within the data store */
export class RecycleBinTableConfiguration extends TableConfiguration {
  static override readonly SettingConfig = {
    pluginName: DataStoreSettings.PLUGIN_NAME,
    settingName: DataStoreSettings.Names.recycleVisibleColumns,
  };

  static override readonly Columns = {
    SessionName: new TableColumnConfiguration<DataStoreFile, Session>("session", "Session Name"),
    DeleteRequestedBy: new TableColumnConfiguration<DataStoreFile, User>("deleteRequestedUser", "Requested By"),
    FileName: new TableColumnConfiguration<DataStoreFile, string>("uncompressedFileName", "File Name"),
    DeletionSchedule: new TableColumnConfiguration<DataStoreFile, Date>("deletionSchedule", "Auto Removal On"),
  };
}
