<div class="user-management">
  <!-- Show the user table -->
  <shared-generic-table
    class="user-table"
    tableName="User Control"
    [data]="users"
    [displayedColumns]="displayedColumns"
    defaultHeaderSortDirection="asc"
    [exportEnabled]="false"
    [matRowHeight]="65"
    [columnVisibilitySettingConfig]="columnVisibilitySetting"
  >
    <!-- Custom end row buttons -->
    <ng-template #buttonRow>
      <!-- User Creation -->
      <button
        mat-raised-button
        color="accent"
        (click)="createUser()"
        matTooltip="Opens a dialog to create a new user"
        matTooltipPosition="below"
        [configDisable]="'user.allowCreation'"
        configDisabledStateMessage="User creation is disabled"
      >
        Create User
      </button>
    </ng-template>
    <!-- Custom cell content -->
    <ng-template #cellTemplate let-value="value" let-column="column" let-element="element">
      <ng-container [ngSwitch]="column.name" *ngVar="element.firstName && element.lastName as hasName">
        <ng-container *ngVar="element.id === userService.currentAuthenticatedUser?.id as elementIsCurrentUser">
          <!-- Custom name cell -->
          <div *ngSwitchCase="'username'" class="name-cell">
            <user-icon [user]="element"></user-icon>
            <!-- Actual user info -->
            <div class="identification">
              <span *ngIf="hasName" class="name">{{ element.fullName }}</span>
              <span [ngClass]="{ username: hasName, name: !hasName }">{{ element.username }}</span>
            </div>
          </div>
          <!-- Action button row -->
          <div *ngSwitchCase="'actions'" class="actions">
            <!-- Lock/unlock account -->
            <button
              mat-stroked-button
              [matTooltip]="
                elementIsCurrentUser
                  ? ''
                  : element.lockedOut
                  ? 'Unlock user account so they can login'
                  : 'Lock user account so they cannot login'
              "
              matTooltipPosition="left"
              (click)="lockUnlockAccount(element)"
              [disabled]="elementIsCurrentUser"
            >
              <ng-container *ngIf="element.lockedOut">Unlock account</ng-container>
              <ng-container *ngIf="!element.lockedOut">Lock account</ng-container>
            </button>
            <!-- Reset password -->
            <button
              mat-stroked-button
              (click)="updateUserPassword(element)"
              [disabled]="elementIsCurrentUser || userService.userIsDemoUser(element)"
              [matTooltip]="
                elementIsCurrentUser
                  ? 'You cannot update your own password here. Please use the change password in the top left dropdown.'
                  : userService.userIsDemoUser(element)
                  ? 'You cannot change the demo user password'
                  : ''
              "
              [configDisable]="'user.allowPasswordChange'"
              configDisabledStateMessage="Changing users passwords is disabled"
            >
              Update Password
            </button>
            <!-- Edit user info -->
            <button
              mat-stroked-button
              (click)="editUserAccountInfo(element)"
              matTooltip=""
              [configDisable]="'user.allowEditing'"
              configDisabledStateMessage="Editing users is disabled"
            >
              Edit
            </button>
          </div>
          <!-- Lockout check -->
          <div
            *ngSwitchCase="column.name === 'lockedOut' || column.name === 'admin' ? column.name : ''"
            class="booleans"
          >
            <mat-icon *ngIf="value">done</mat-icon>
          </div>
          <!-- Default display -->
          <ng-container *ngSwitchDefault>
            {{ value }}
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-template>
  </shared-generic-table>
</div>
