import { fromEvent, Subject } from "rxjs";

/**
 * This class provides some handlers from the overarching document in a methodology
 *  that will reduce the performance impact on the calls. We do this by separating
 *  the calls into separate event emitters to detach them from the original event.
 */
export module PluginListeners {
  /** Keydown events attached to the overarching document. */
  export module Keydown {
    /** The subject to subscribe to as this event is fired */
    export const subject = new Subject<KeyboardEvent>();
    /** Attach the keydown event to the emitter subject */
    fromEvent<KeyboardEvent>(document, "keydown").subscribe((x) => subject.next(x));
  }

  /** Keyup events attached to the overarching document. */
  export module Keyup {
    /** The subject to subscribe to as this event is fired */
    export const subject = new Subject<KeyboardEvent>();
    /** Attach the keyup event to the emitter subject */
    fromEvent<KeyboardEvent>(document, "keyup").subscribe((x) => subject.next(x));
  }
}
