import { Directive, Input } from "@angular/core";
import { LineChartSeries, TimeSeriesBarChartValues, TimeSeriesChartBase } from "@tdms/common";
import { ExtendedChartOptions } from "@tdms/frontend/modules/charts/shared/plugins/plugin.typing";
import { AngularCustomTypes } from "@tdms/frontend/modules/shared/models/angular.custom.types";
import { ChartOptions } from "chart.js/auto";
import { ChartBaseComponent } from "../base/base.component";

/**
 * A base layer component that allows timeline control to effect any chart type that extends upon this
 *  thanks to some overrides of the domainRange capability and re rendering control. This normally enforces that
 *  any chart that uses this is a time series chart.
 */
@Directive({ selector: "charts-timeline-base[colorLookup][configuration][data][totalChartTimeFrame]" })
export abstract class TimelineChartBaseComponent<
  ChartDataType extends TimeSeriesChartBase<any>,
  InternalChartType extends "line" | "bar"
> extends ChartBaseComponent<ChartDataType[], InternalChartType> {
  /** Tracks if this is the timeline of it's current metric grid or not */
  @Input() isTimeline: boolean = false;

  /**
   * A domain range to restrict the charts display to in the X direction
   */
  @Input() xDomainRange: [Date, Date] | undefined | null;

  /**
   * The total time frame for this given chart. This is required because we must be able to detect the min and max domain values,
   *  in the event we don't have an scroll domain
   */
  @Input() totalChartTimeFrame!: [Date, Date];

  /**
   * @param checkDomainRange If a change in the xDomainRange should be considered during an update
   */
  override shouldChartUpdate(
    changes: AngularCustomTypes.BaseChangeTracker<TimelineChartBaseComponent<any, any>>,
    checkDomainRange: boolean = !this.isTimeline
  ) {
    let shouldUpdate = super.shouldChartUpdate(changes);
    if (this.currentChart && !this.isCalculating) {
      // Check for domain changes
      if (checkDomainRange && changes.xDomainRange) {
        this.updateChartOptions();
        shouldUpdate = "chart";
      }
    }
    return shouldUpdate;
  }

  chartOptionOverrides(_coreOptions: ChartOptions<InternalChartType>): ChartOptions<InternalChartType> {
    const options: ExtendedChartOptions<"line"> = {
      scales: {
        x: {
          type: "time",
          min: this.xDomainRange ? this.xDomainRange[0]?.toISOString() : undefined,
          max: this.xDomainRange ? this.xDomainRange[1]?.toISOString() : undefined,
          ticks: {
            maxTicksLimit: 10,
          },
        },
      },
      plugins: {
        customTooltip: {
          timeDisplay: this.xFormatter.bind(this),
          styling: {
            smallMode: this.isTimeline,
          },
        },
        bookmark: {
          shouldRenderHorizontal: !this.isTimeline,
        },
      },
      layout: {
        padding: {
          left: this.isTimeline ? 20 : undefined,
        },
      },
    };
    return options as any;
  }

  override get xFormatter() {
    let xFormatter = super.xFormatter;
    const data = this.data?.find((x) => x.series[0] != null)?.series[0] as
      | LineChartSeries
      | TimeSeriesBarChartValues
      | undefined;
    // Check session comparison override
    if (this.isSessionComparison && this.configuration.comparison?.xAxisFormatOverride != null && data != null) {
      let startVal: Date | undefined;
      if (data instanceof LineChartSeries) startVal = data.name;
      else startVal = data.startTime;
      if (startVal) xFormatter = this.getComparisonXAxisFormatter(this.configuration.comparison).bind(this, startVal);
    }
    return xFormatter;
  }
}
