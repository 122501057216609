import { Component, Inject, QueryList, ViewChildren } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SafeResourceUrl } from "@angular/platform-browser";
import {
  DataStoreUploadMetadata,
  DataStoreUploadMetadataOption,
  DataStoreUploadOptionMetadataChange,
  TDMSWebSocketMessage,
  UploadMetadataSupportedTypes,
} from "@tdms/common";
import AudioService from "@tdms/frontend/modules/audio/services/audio.service";
import { WebSocketService } from "@tdms/frontend/modules/communication/services/websocket.service";
import { DownloadService } from "@tdms/frontend/modules/data-store/services/download.service";
import { AudioPlayerComponent } from "@tdms/frontend/modules/shared/components";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import { snakeCase } from "lodash-es";

/** Properties for customizing the Upload Metadata dialog */
export interface UploadMetadataProps {
  request: DataStoreUploadMetadata;
  onComplete: Function;
  onCancel: Function;
}

@Component({
  selector: "data-store-upload-metadata",
  templateUrl: "./upload-metadata.component.html",
  styleUrls: ["./upload-metadata.component.scss"],
})
export class UploadMetadataComponent extends SubscribingComponent {
  formGroup: FormGroup;

  /** Rendered audio players for the various components */
  @ViewChildren(AudioPlayerComponent) audioPlayers!: QueryList<AudioPlayerComponent>;
  /** In the event the {@link DataStoreUploadMetadataOption} defines an audio file, this will be populated to allow playback of the audio. */
  audio = new Map<DataStoreUploadMetadataOption<any>, string | SafeResourceUrl | undefined>();

  /** This tracker allows us to handle when we are processing due to an {@link informOfChange} callback. True if we are processing, false if not. */
  processingTracker = new Map<DataStoreUploadMetadataOption<any>, boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public properties: UploadMetadataProps,
    public downloadService: DownloadService,
    private audioService: AudioService,
    private wsService: WebSocketService
  ) {
    super();
    // Flatten the options to turn them into controls
    const options = properties.request.groups.flatMap((x) => x.options.flatMap((z) => ({ option: z, group: x })));
    const controls = options.reduce((x, curr) => {
      // If this option would like to display an audio file, allow it to do so.
      if (curr.option.audio) {
        const audioPath = curr.option.audio.path;
        this.processingTracker.set(curr.option, true);
        this.audioService.getAudioFileBlob(audioPath).then((x) => {
          if (x) {
            this.audio.set(curr.option, x.url);
            this.processingTracker.set(curr.option, false);
          }
        });
      }
      const control = new FormControl(this.getTypedValue(curr.option, curr.option.value), [Validators.required]);
      // Handle updating the controls
      this.addSubscription(
        control.valueChanges.subscribe((val) => {
          curr.option.value = val;
          // Fire an update against a topic if we have one
          if (curr.option.internalType === "dropdown" && curr.option.dropdownOptions?.valueChangeTopic != null)
            this.informOfChange(curr.option, val);
        })
      );
      return {
        ...x,
        [this.getFieldName(curr.option, curr.group.fileName)]: control,
      };
    }, {});
    this.formGroup = new FormGroup(controls);
  }

  /** Handles sending an updated option to the backend if defined by the dropdown queues */
  async informOfChange(option: DataStoreUploadMetadataOption<any>, value: UploadMetadataSupportedTypes) {
    if (option.internalType === "dropdown" && option.dropdownOptions?.valueChangeTopic != null) {
      // Pause all audio players
      this.audioPlayers.forEach((x) => x.pause());
      this.processingTracker.set(option, true);
      // Send the request
      const message = DataStoreUploadOptionMetadataChange.fromPlain({ value, audio: option.audio });
      const response = await this.wsService.sendAndReceive<DataStoreUploadOptionMetadataChange<any>>(
        new TDMSWebSocketMessage(option.dropdownOptions.valueChangeTopic, undefined, message)
      );
      // If we were tracking audio, go ahead and see if it needs updated
      if (response.payload.audio) {
        const audioPath = await this.audioService.getAudioFileBlob(response.payload.audio.path);
        this.audio.set(option, audioPath.url!);
      }
      this.processingTracker.set(option, false);
    }
  }

  /** Given a new value and the field, get's it as a typed value */
  getTypedValue<T extends UploadMetadataSupportedTypes>(field: DataStoreUploadMetadataOption<T>, newVal: T) {
    if (field.type === "Date" && typeof newVal === "string") return new Date(newVal);
    else return newVal;
  }

  /** Returns the form control name for the given content */
  getFieldName(field: DataStoreUploadMetadataOption<any>, groupName: string) {
    return snakeCase(groupName + " " + field.name);
  }

  onSubmit() {
    // Update all options with their adjustments
    this.properties.onComplete();
  }

  /** Returns if this set of data is valid or not with an error message. Controls if we can submit the metadata or not. */
  get validityError() {
    // Base handling
    if (!this.formGroup.valid) return "Please fill in all required fields.";
    // If any of our filters are processing, don't allow submitting
    else if ([...this.processingTracker.values()].some((value) => value))
      return "Audio filter is being applied. Please wait.";
    return undefined;
  }
}
