import { createFeatureSelector, createSelector } from "@ngrx/store";
import { NotificationState } from "../notification.state";
import { notificationAdapter } from "./notification.reducer";

/** State selector for notifications */
export const selectNotificationState = createFeatureSelector<NotificationState>("notification");

/** Returns all notifications given state */
export const selectAllNotifications = (state: NotificationState) => notificationAdapter.getSelectors().selectAll(state);

/** Returns, from state, the current notifications */
export const selectAllNotificationsFromState = createSelector(selectNotificationState, selectAllNotifications);
