import { Component, InjectionToken, ViewChild } from "@angular/core";
import AudioService from "@tdms/frontend/modules/audio/services/audio.service";
import { Route_URLs } from "@tdms/frontend/modules/routing/models/url";
import { RouterService } from "@tdms/frontend/modules/routing/services/router.service";
import { AudioPlayerComponent } from "@tdms/frontend/modules/shared/components";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import { BehaviorSubject, Subscription } from "rxjs";

/**
 * This injection token allows the core component to provide the background audio component as
 *  an injectable to anyone who wants it. It can be used like:
 *
 * ```ts
 * constructor(@Inject(BACKGROUND_AUDIO_PLAYER) private bgAudioPlayer: BackgroundAudioComponent) {}
 * ```
 */
export const BG_AUDIO_INJECTION = new InjectionToken<BackgroundAudioComponent>("BackgroundAudioPlayer");

/**
 * This component is used to track the audio player in the background of every other page. This allows us to load
 *  the audio player once in a session and be able to reference it from anywhere else. This is intended for use
 *  with the session audio file and uses the audio services blob to render that.
 *
 * This is notably useful so we can maintain playback state and keep playing the audio file while switching pages
 */
@Component({
  selector: "audio-background",
  templateUrl: "./background-audio.component.html",
  styleUrls: ["./background-audio.component.scss"],
})
export class BackgroundAudioComponent extends SubscribingComponent {
  /** Routes that have playback controllers that utilize the bg audio player. This is used so we can pause if we navigate away from these. */
  static ROUTES_WITH_PLAYBACK = [Route_URLs.communication, Route_URLs.dashboard];

  /** The audio player that contains our content */
  private audioPlayer: AudioPlayerComponent | undefined;

  /** Sets the player utilizing angular's view child */
  @ViewChild(AudioPlayerComponent) protected set player(ref: AudioPlayerComponent | undefined) {
    this.playerReady.next(ref == null ? undefined : ref);
    this.audioPlayer = ref;
  }

  /**
   * Tracks when the player is ready to handle functionality. When this element is initialized,
   *  the player doesn't technically have to exist because this component persists across
   *  sessions. This allows you to check if there even is a valid player to use.
   */
  playerReady = new BehaviorSubject<AudioPlayerComponent | undefined>(undefined);

  /** This behavior subject tracks the current audio player's playback time in seconds. Will handle if the player isn't yet constructed. */
  currentPlayerTime = new BehaviorSubject(0);

  /** This subscription is the linking subscription from the player to our local {@link currentPlayerTime}. It is cleaned up as the player ready state changes. */
  private currentPlayerLinkSub: Subscription | undefined;

  /** Gets the current audio file duration. If no audio file is loaded, returns 0. */
  get duration() {
    return this.audioPlayer?.duration ?? 0;
  }

  /** Returns the URL of the audio blob we are rendering */
  get url() {
    return this.audioService.audioBlob.value?.url;
  }

  /** Returns true if the audio player is paused. */
  get isPaused() {
    return this.audioPlayer?.isPaused ?? true;
  }

  /**
   * Seeks the player, if initialized, to the given time.
   * @param time The time in seconds to seek to. If 0 is given, the player will be paused.
   */
  seek(time: number) {
    this.audioPlayer?.seekTo(time, time === 0 ? false : true);
  }

  constructor(private audioService: AudioService, private routerService: RouterService) {
    super();
    // Handle route changes pausing playback if we're still in session but on a page that doesn't have a audio controller
    this.addSubscription(
      this.routerService.navigationEnd.subscribe((x) => {
        if (this.audioPlayer)
          if (!BackgroundAudioComponent.ROUTES_WITH_PLAYBACK.includes(x.tdmsUrl) && !this.audioPlayer.isPaused)
            this.audioPlayer.pause();
      })
    );
    // Make sure to keep our player time aligned with the potentially changing player due to changes in sessions
    this.addSubscription(
      this.playerReady.subscribe((x) => {
        if (!x) {
          this.currentPlayerLinkSub?.unsubscribe();
          this.currentPlayerTime.next(0);
        } else {
          const link = x.currentPlayerTime.subscribe((z) => this.currentPlayerTime.next(z));
          this.currentPlayerLinkSub = link;
          this.addSubscription(link); // Incase we deconstruct before cleaning up
        }
      })
    );
  }
}
