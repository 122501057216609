import { startCase } from "lodash";
import { CustomTypes } from "../../custom.types";
import { CommonSetting, CommonSettingType } from "../settings";

/** A type containing all supported column types to be displayed within our table header config */
export type TableColumnSupportedColumnTypes = number | string | boolean | Date | Array<any> | Object;

/**
 * This class defines table columns to be displayed
 *  within frontend tables. This is just intended to define
 *  the actual column to the displayed text. This will be used in
 *  the settings determination of each table.
 */
export class TableColumnConfiguration<ObjectType extends Object, ColumnType extends TableColumnSupportedColumnTypes> {
  /**
   * Creates an instance of the table header configuration so we can map property values to specific display properties.
   * @param propertyValue The actual property within the object given of {@link ObjectType}. This is just used for data access
   *  not actual display.
   * @param displayName This is the display used for the header so we can render prettier text. By default, this uses lodash {@link startCase}
   *  to cleanup the text.
   * @param enabledByDefault If this header should be enabled by default when displayed to the user with
   *  column visibility settings. Default is true.
   */
  constructor(
    public propertyValue: CustomTypes.PropertyNames<ObjectType, ColumnType>,
    public displayName: string = startCase(propertyValue),
    public enabledByDefault = true
  ) {}
}

/**
 * This class provides an abstraction layer to extend upon to allow you to specify a configuration
 *  for a table that will be displayed in the frontend. This will contain column display information
 *  so the backend can additionally maintain some user settings while considering these columns.
 */
export abstract class TableConfiguration {
  /** Setting information that can help align this to a user setting to persist table column configuration information */
  static readonly SettingConfig: { pluginName: string; settingName: string };
  /** The columns this table supports with customization defined */
  static readonly Columns: { [name: string]: TableColumnConfiguration<any, any> };

  /** Returns the columns as an array of configurations */
  private static getAllColumns() {
    const keys = Object.keys(this.Columns);
    return keys.map((key) => this.Columns[key]);
  }

  /** Returns the `displayName` of each column */
  private static getAllColumnNames() {
    return this.getAllColumns().map((x) => x.displayName);
  }

  /** Returns an array of the default enabled columns for this table. This will return the `displayName` value, not the property key value. */
  static getEnabledColumns() {
    return this.getAllColumns().filter((z) => z.enabledByDefault);
  }

  /** Returns the `displayName` of each enabled by default column */
  static getEnabledColumnNames() {
    return this.getEnabledColumns().map((x) => x.displayName);
  }

  /** Converts the current table configuration into a setting with default values defined */
  static toSetting() {
    return {
      name: this.SettingConfig.settingName,
      value: this.getEnabledColumnNames(),
      settingType: CommonSettingType.array,
      arrayOptions: this.getAllColumnNames(),
      allowMultipleOptions: true,
    } as CommonSetting;
  }
}
