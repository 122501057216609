<div class="toast-overlay-container">
  <!-- Render each individual toast -->
  <div class="toasts">
    <notification-toast
      *ngFor="let notification of notifications"
      [toast]="notification"
      mode="compress"
    ></notification-toast>
  </div>
</div>
