import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  Bookmark,
  ChartConfiguration,
  ChartData,
  CustomTypes,
  filterNulls,
  LineChartData,
  LineChartSeries,
  Session,
  TimeSeriesBarChartData,
  TimeSeriesBarChartValues,
  TimeSeriesChartBase,
} from "@tdms/common";
import { LegendDisplay } from "@tdms/frontend/modules/charts/shared/legend/models/legend.display";
import { ColorThemeService } from "@tdms/frontend/modules/material/services/themes.service";
import {
  MetricCardDataStore,
  MetricServiceDataStore,
} from "@tdms/frontend/modules/metrics/components/metric-card/models/metric.configuration";
import { MetricGridDataStore } from "@tdms/frontend/modules/metrics/components/metric-grid/models/metric-grid.configuration";
import { LegendService } from "@tdms/frontend/modules/metrics/services/legend.service";
import { MetricGridService } from "@tdms/frontend/modules/metrics/services/metric.grid.service";
import { MetricService } from "@tdms/frontend/modules/metrics/services/metric.service";
import { selectMetricDataForGivenSession } from "@tdms/frontend/modules/metrics/store/metric.selector";
import { RouterParamTypes, RouterService } from "@tdms/frontend/modules/routing/services/router.service";
import { selectSessionFromId } from "@tdms/frontend/modules/session/store/session.selector";
import { Service } from "@tdms/frontend/modules/shared/services/base.service";
import { SessionState } from "http2";
import { cloneDeep } from "lodash-es";
import { BehaviorSubject, combineLatest, filter, map, Observable, of, switchMap } from "rxjs";

/**
 * A service intended to provide functionality when utilizing the session comparison capability of a metric card
 */
@Injectable({
  providedIn: "root",
})
export class SessionComparisonService extends Service {
  constructor(
    private store: Store<SessionState>,
    private themeService: ColorThemeService,
    private routerService: RouterService,
    private metricService: MetricService,
    private metricGridService: MetricGridService,
    private legendService: LegendService
  ) {
    super();
  }

  /**
   * Start a new session comparison with the given sessions, which will create a @MetricGridDataStore
   * preconfigured with observables that will return the comparison data for each chart.
   * @param sessionA The first compared session
   * @param sessionB The second compared session
   * @returns
   */
  async startSessionComparison(sessionA: Session, sessionB: Session): Promise<MetricGridDataStore> {
    /// Update our url params with the session comparison information
    this.routerService.setQueryParam(RouterParamTypes.comparedSessions, [sessionA.id, sessionB.id]);
    /// Reset the zoom of the charts before starting session comparison
    this.metricService.zoomDomainUpdate.next({ domain: undefined, source: "external" });

    /// Start requests for comparison data from the metric service, loading from backend if necessary.
    await this.metricService.requestComparisonData(sessionA, sessionB);

    // A legend that just displays the session names
    const sessionBasedLegend = this.legendService.toBehavior(
      this.legendService.getSessionBasedLegend([sessionA, sessionB])
    );
    // A legend that displays the total roles for each session
    const rolesBasedLegend = this.legendService.toBehavior(
      this.legendService.getRoleBasedLegendFromSessions([sessionA, sessionB])
    );

    /// Load all metric configuration data from the metric grid service, filtering for only those that allow comparison.
    /// Then apply special configuration settings for comparison using @adjustChartConfigForSessionCompare
    const configurations = (await this.metricGridService.getMetricConfigurations())
      .filter((configuration) => configuration.comparison?.enabled)
      .map((configuration) => this.adjustChartConfigForSessionCompare(configuration, false));

    /// Grab our session timeline card specific configuration from the available configurations.
    let timelineObservable: Observable<MetricCardDataStore | undefined> = of(undefined);
    const timelineConfig = this.metricGridService.findTimelineMetricConfig(configurations, false);
    if (timelineConfig)
      timelineObservable = timelineConfig.pipe(
        switchMap((config) => {
          if (!config) return of(undefined);
          else
            return this.createObservableFor(
              this.adjustChartConfigForSessionCompare(config, true),
              sessionA,
              sessionB,
              undefined,
              undefined,
              true
            );
        })
      );

    const observables: Observable<MetricCardDataStore>[] = [];

    /// Create an observable for every available configuration.
    for (const configuration of configurations) {
      observables.push(
        this.createObservableFor(configuration, sessionA, sessionB, sessionBasedLegend, rolesBasedLegend)
      );
    }

    const bookmarkObservableA = this.store
      .select(selectSessionFromId(sessionA.id))
      .pipe(map((session) => ({ session, bookmarks: session?.bookmarks?.filter((x) => x.critical) ?? [] })));

    const bookmarkObservableB = this.store
      .select(selectSessionFromId(sessionB.id))
      .pipe(map((session) => ({ session, bookmarks: session?.bookmarks?.filter((x) => x.critical) ?? [] })));

    const combinedBookmarkObservable = combineLatest([bookmarkObservableA, bookmarkObservableB]).pipe(
      map(([a, b]) => {
        let bookmarksA = a.bookmarks.map((x) => Bookmark.fromPlain({ ...x }));
        let bookmarksB = b.bookmarks.map((x) => Bookmark.fromPlain({ ...x }));
        if (a.session && b.session) {
          const longestSession = this.returnLongestSession(a.session, b.session);
          bookmarksA = this.correctBookmarkTimes(bookmarksA, longestSession.startDate, a.session.startDate);
          bookmarksB = this.correctBookmarkTimes(bookmarksB, longestSession.startDate, b.session.startDate);
        }
        return [...bookmarksA, ...bookmarksB];
      })
    );

    /// Adjust the data domain for our comparison using @findDateRange
    const dateRange = this.findDateRange(sessionA, sessionB);

    return new MetricGridDataStore(
      observables,
      of(true),
      of(dateRange[0]),
      of(dateRange[1]),
      combinedBookmarkObservable,
      timelineObservable
    );
  }

  /**
   * Create observables for the metrics attached to this configuration on both given sessions, then merge them together.
   * @param configuration
   * @param sessionA
   * @param sessionB
   * @param isTimeline If this observable is related to the timeline or not
   * @returns
   */
  private createObservableFor(
    configuration: ChartConfiguration,
    sessionA: Session,
    sessionB: Session,
    sessionBasedLegend?: BehaviorSubject<LegendDisplay[]>,
    rolesBasedLegend?: BehaviorSubject<LegendDisplay[]>,
    isTimeline = false
  ): Observable<MetricCardDataStore> {
    return (
      combineLatest([
        this.store.select(selectMetricDataForGivenSession(configuration, sessionA.id)),
        this.store.select(selectMetricDataForGivenSession(configuration, sessionB.id)),
      ])
        /// Filter out emissions where one or both of the metric data stores are null.
        .pipe(
          filter(
            (metrics): metrics is [MetricServiceDataStore<any>, MetricServiceDataStore<any>] =>
              metrics[0] != null && metrics[1] != null
          )
        )
        /// Call @flattenSessionCombination on each metric combination.
        .pipe(
          map((metrics) => {
            return {
              comparison: this.flattenSessionCombination(configuration, [sessionA, sessionB], metrics),
              metrics: metrics,
            };
          })
        )
        /// Filter out null values from the session combination.
        .pipe(filterNulls)
        /// Grab the color map and query the enabled setting of the resultant metric combination.
        .pipe(
          map((data) => {
            const colorMap = this.themeService.observeColorsFor(data.comparison.data);
            // Determine legend to use
            const totalMetricsLength = data.metrics!.reduce((a, b) => (b?.data?.length || 0) + a, 0);
            const legend = totalMetricsLength === 2 ? sessionBasedLegend : rolesBasedLegend;
            const enabledObservable = this.metricGridService.getChartEnabledObservable(configuration);
            const bookmarksEnabledObservable = this.metricGridService.getBookmarkEnabledObservable(configuration);
            return new MetricCardDataStore(
              data.comparison,
              configuration,
              colorMap,
              enabledObservable,
              bookmarksEnabledObservable,
              legend,
              isTimeline
            );
          })
        )
    );
  }

  private findDateRange(sessionA: Session, sessionB: Session): [Date, Date] {
    const startA = sessionA.startDate;
    const endA = sessionA.endDate;
    const startB = sessionB.startDate;
    const endB = sessionB.endDate;

    const durationA = endA.getTime() - startA.getTime();
    const durationB = endB.getTime() - startB.getTime();

    if (durationA > durationB) {
      return [startA, endA];
    }

    return [startB, endB];
  }

  private returnLongestSession(sessionA: Session, sessionB: Session): Session {
    const startA = sessionA.startDate;
    const endA = sessionA.endDate;
    const startB = sessionB.startDate;
    const endB = sessionB.endDate;

    const durationA = endA.getTime() - startA.getTime();
    const durationB = endB.getTime() - startB.getTime();

    if (durationA > durationB) {
      return sessionA;
    }

    return sessionB;
  }

  /**
   * Given a chart configuration, updates it so it is more consistent for session comparison by overriding it's defaults.
   * @param isTimeline If this is the timeline card. This will require some special handling.
   */
  adjustChartConfigForSessionCompare(configuration: ChartConfiguration, isTimeline = false) {
    // Clone our configuration to not accidentally change the original
    configuration = cloneDeep(configuration);
    if (!isTimeline) configuration.displayLegend = true;
    return configuration;
  }

  /**
   * Given the metric service data store data from the observable of data, breaks it down for session comparison information
   *  by separating some of the information.
   */
  flattenSessionCombination(
    metricConfig: ChartConfiguration,
    sessions: [Session, Session],
    observableData: [MetricServiceDataStore<any>, MetricServiceDataStore<any>]
  ): MetricServiceDataStore {
    const data = [cloneDeep(observableData[0]), cloneDeep(observableData[1])];
    // Get the total amount of data so we can track if these should be individual charts or not
    const totalDataLength = data.reduce((a, b) => (b?.data?.length || 0) + a, 0);
    const dataWillMatchToSession = totalDataLength === 2;
    const containsCalculatingData = data.find((x) => x != null && x.isCalculating) != null;
    // Adjust session names
    const convertedData = containsCalculatingData
      ? []
      : this.adjustNamesToSessions(data, sessions, dataWillMatchToSession);
    const flattenedConvertedData = convertedData.flatMap((x) => x.data!).filter((z) => z != null);
    // The actual return data that has been flattened
    let flattenedData = MetricServiceDataStore.fromPlain({
      // Determine if any of the charts are calculating
      isCalculating: containsCalculatingData,
      data: flattenedConvertedData,
      // Include specialized bookmarks
      bookmarks: this.getSessionComboBookmarks(data, sessions),
    }) as MetricServiceDataStore;
    // Align time series data so the time range matches
    this.alignTimeSeriesData(metricConfig, flattenedData, containsCalculatingData, sessions);
    return flattenedData;
  }

  /**
   * Returns the session combination bookmarks for the given params
   */
  private getSessionComboBookmarks(data: MetricServiceDataStore[], sessions: [Session, Session]) {
    return data
      .flatMap((x, i) =>
        x?.data?.flatMap((z) => {
          const session = sessions[i];
          const longestSession = this.returnLongestSession(sessions[0], sessions[1]);
          const bookmarks = (z as LineChartData).seriesSpecificBookmarks?.filter((bmk) => bmk.critical);
          // Adjust bookmark naming
          if (bookmarks)
            bookmarks.forEach((bookmark) => {
              // Only adjust horizontal for now
              if (bookmark.bookmarkType.renderStyle === "horizontal") {
                const sessionName = session.getOrderedName(i);
                bookmark.associatedRoleName = sessionName;
              }
            });
          // Correct bookmark times specific for this data
          return this.correctBookmarkTimes(bookmarks, longestSession.startDate, session.startDate);
        })
      )
      .filter((z) => z != null) as Bookmark[];
  }

  /**
   * Adjusts the given data to consider the sessions for their names for better data display
   * @param willMatchToSession If the data matches to the session, we can rename the data to be the session specifically. Else
   *  we use a combination of the original chart data name and the session name.
   */
  private adjustNamesToSessions(
    data: MetricServiceDataStore<(ChartData & { originalName?: string })[]>[],
    sessions: Session[],
    willMatchToSession: boolean
  ) {
    return data
      .flatMap((dataStore, i) => {
        if (dataStore?.data) {
          // Adjust chart data name to be more in line with the session comparison
          dataStore.data = dataStore.data.map((chartData) => {
            // Set the session name by splitting based on amount of data
            const sessionName = willMatchToSession
              ? sessions[i].getOrderedName(i)
              : `${sessions[i].getOrderedName(i)} - ${chartData.name}`;
            chartData.originalName = chartData.name;
            chartData.name = sessionName;
            return chartData;
          });
        }
        return dataStore!;
      })
      .filter((x) => x != null);
  }

  /**
   * Given some basic information, determines what values are the timestamp and end time (if it exists) so we can easily auto pull apart
   *  time series data and adjust date values.
   */
  private getTimeSeriesChartDataAttr<ChartDataType extends LineChartData | TimeSeriesBarChartData>(
    dataTyping: ChartDataType,
    endRange = false
  ) {
    if (dataTyping instanceof LineChartData)
      if (!endRange) return <CustomTypes.PropertyNames<LineChartSeries, Date>>"name";
      else return undefined;
    else if (dataTyping instanceof TimeSeriesBarChartData)
      if (!endRange) return <CustomTypes.PropertyNames<TimeSeriesBarChartValues, Date>>"startTime";
      else return <CustomTypes.PropertyNames<TimeSeriesBarChartValues, Date>>"endTime";
  }

  /**
   * Given some metric service data, realigns all data so the start time is at the same point
   * @param dataIsStillCalculating Tracks if our data is still being calculated via the backend
   */
  private alignTimeSeriesData(
    configuration: ChartConfiguration,
    data: MetricServiceDataStore,
    dataIsStillCalculating: boolean,
    sessions: [Session, Session]
  ) {
    if (dataIsStillCalculating) return data;
    // Ignore missing data
    if (!data.data) return data;
    const dataArray = data.data;
    // Grab the time attribute names
    const timeAttr = this.getTimeSeriesChartDataAttr(dataArray[0] as any);
    const endTimeAttr = this.getTimeSeriesChartDataAttr(dataArray[0] as any, true);

    if (timeAttr == null) {
      throw new Error("Failed to align time series data as the timestamp attribute could not be determined.");
    }

    // grabs session with the longest duration
    var longestSession = this.returnLongestSession(sessions[0], sessions[1]);

    // Verify this is a time series chart
    if (configuration.isTimeSeriesChart && timeAttr) {
      const dataToProcess = dataArray as TimeSeriesChartBase<any>[];

      // Grab the base start time for alignment
      const newStartTime = longestSession.startDate;
      // Process the actual times
      dataToProcess.map((typedData) => {
        const series = typedData?.series;
        if (series?.length > 0) {
          const originalStartTime = (series[0] as any)[timeAttr] as Date;
          let timeDiff = Math.abs(newStartTime.getTime() - originalStartTime.getTime());
          // Determine time padding direction
          if (newStartTime.getTime() > originalStartTime.getTime()) timeDiff = -timeDiff;
          // Iterate and update time values based on diff
          typedData.series = series.map((x: any) => {
            // Timestamp/start time
            x[timeAttr] = new Date((x[timeAttr] as Date).getTime() - timeDiff);
            // End time, if it exists
            if (endTimeAttr != null) x[endTimeAttr] = new Date((x[endTimeAttr] as Date).getTime() - timeDiff);
            return x;
          }) as any;
        }
        return typedData;
      });
    }
    return data;
  }

  /** Corrects bookmark times to be adjusted to the new start time of sessions as they occur */
  private correctBookmarkTimes(bookmarks: Bookmark[], newStartTime: Date, originalStartTime: Date) {
    return bookmarks.map((bmk) => {
      if (bmk.startTime) {
        const timeDiff = Math.abs(newStartTime.getTime() - originalStartTime.getTime());
        bmk.startTime = new Date(Math.abs(bmk.startTime.getTime() + timeDiff));
        if (bmk.endTime) bmk.endTime = new Date(Math.abs(bmk.endTime.getTime() + timeDiff));
      }
      return bmk;
    });
  }
}
