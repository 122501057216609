import { Injectable } from "@angular/core";
import { DataStoreFile, DataStoreTopics, TDMSWebSocketMessage, Utility, WebSocketCommunication } from "@tdms/common";
import { WebSocketService } from "@tdms/frontend/modules/communication/services/websocket.service";
import { FrontendUtility } from "@tdms/frontend/modules/shared/models/utility";
import { Service } from "@tdms/frontend/modules/shared/services/base.service";
import { UserService } from "@tdms/frontend/modules/user/services/user.service";
import DataStoreService from "./data.store.service";

/**
 * A service to provide downloading capabilities from the data store
 */
@Injectable({
  providedIn: "root",
})
export class DownloadService extends Service {
  constructor(
    private userService: UserService,
    private dataStoreService: DataStoreService,
    private wsService: WebSocketService
  ) {
    super();
  }

  /**
   * Download and save a given data store file, creating an access log as well.
   * @param file The data store file.
   * @param accessReason The access reason provided by the user.
   */
  async saveDataStoreFile(file: DataStoreFile, accessReason: string, removeCompression: boolean = true) {
    let filepath;
    let filename;

    if (removeCompression) {
      /// If we are removing compression, we need to get the uncompressed filepath and filename
      /// and use that for the download.
      filepath = file.uncompressedHttpPath;
      filename = file.uncompressedFileName;
    } else {
      filepath = file.httpPath;
      filename = file.fileName;
    }

    const blob = await this.downloadFile(filepath, accessReason);
    if (blob) {
      FrontendUtility.saveFile("blob", blob, filename!);
    }
  }

  /**
   * Given some information, determines the download url for a given file
   * @param filename The file path to the file to download. This must contain session pathing information
   * @param accessReason The reason to download this file
   */
  getDownloadPathing(filename: string, accessReason: string) {
    return this.dataStoreService.getDataStoreEndpoint("download") + filename + `?reason=${accessReason}`;
  }

  /**
   * Download a given filename from the data store.
   * @param filename The filename to download.
   * @param accessReason The reason for file access.
   * @param removeCompression If compression should be removed for the download. Default is true.
   * @returns The file data as a Blob.
   */
  async downloadFile(filename: string, accessReason: string, removeCompression = true) {
    //Handle user authentication. Check user's jwt local expiry time, log them out if jwt is expired.
    if (!this.userService.checkUserJwtValidation()) {
      this.userService.logout();
      return;
    }
    // Remove compression
    if (removeCompression && filename.endsWith(DataStoreFile.COMPRESSION_EXTENSION))
      filename = filename.replace(DataStoreFile.COMPRESSION_EXTENSION, "");
    // Execute request
    const response = await fetch(this.getDownloadPathing(filename, accessReason), {
      method: "GET",
      cache: "no-cache",
      headers: {
        Authorization: "Bearer " + this.userService.getJWT(),
        "client-identifier": this.wsService.clientIdentifier,
      },
    });
    // Handle errors
    if (!response.ok) throw new Error(response.statusText);
    return response.blob();
  }

  /** Listens for parsed file results as they occur */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<string>>(DataStoreTopics.downloadSpecialParse)
  protected async downloadSpecialFile(data: TDMSWebSocketMessage<string>) {
    const filePath = data.payload;
    const fileName = Utility.getFileName(filePath);
    const blob = await this.downloadFile(filePath, "Special Download", false);
    if (blob) FrontendUtility.saveFile("blob", blob, fileName);
  }
}
