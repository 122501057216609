import { ChangeDetectorRef, Component, ElementRef, Inject, Input, OnInit, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  AudioSettings,
  Bookmark,
  BookmarkType,
  ChartXAxisFormatters,
  LineChartData,
  Transcription,
} from "@tdms/common";
import {
  BackgroundAudioComponent,
  BG_AUDIO_INJECTION,
} from "@tdms/frontend/modules/audio/components/background-audio/background-audio.component";
import { BackgroundAudioBase } from "@tdms/frontend/modules/audio/components/background-audio/bg-audio.base";
import AudioService from "@tdms/frontend/modules/audio/services/audio.service";
import { selectAllTranscriptionsFromState } from "@tdms/frontend/modules/audio/store/transcription.selector";
import { TranscriptionState } from "@tdms/frontend/modules/audio/store/transcription.state";
import { ZoomDomainUpdateEmitter } from "@tdms/frontend/modules/charts/shared/timeline/timeline.selection.base";
import { ColorMap, ColorThemeService } from "@tdms/frontend/modules/material/services/themes.service";
import { BookmarkProperties } from "@tdms/frontend/modules/metrics/components/metric-card/metric-card.component";
import { MetricCardDataStore } from "@tdms/frontend/modules/metrics/components/metric-card/models/metric.configuration";
import { SafeSpace, TimeDomain } from "@tdms/frontend/modules/metrics/components/timeline/canvas";
import { selectCurrentSession } from "@tdms/frontend/modules/session/store/session.selector";
import { PluginSettingsService } from "@tdms/frontend/modules/settings/services/settings.service";
import { TDMSTheme } from "@tdms/frontend/modules/shared/components";
import { BehaviorSubject, combineLatest, startWith, Subject } from "rxjs";

/*
 * This component is intended to be our timeline display for our metric grid. It dynamically chooses between
 *  displaying an audio based timeline and a simple line based one.
 */
@Component({
  selector: "timeline[zoomDomainUpdater][theme]",
  templateUrl: "./timeline.component.html",
  styleUrls: ["./timeline.component.scss"],
})
export class TimelineComponent extends BackgroundAudioBase implements OnInit, BookmarkProperties {
  /// Bookmark related content to pass to the card from the grid
  @Input() gridLevelBookmarks: Bookmark[] = [];
  @Input() bookmarkTypes: BookmarkType[] = [];
  @Input() bookmarkDrawingStatus = new BehaviorSubject<boolean>(false);
  @Input() onAddBookmarkClicked?: { (bookmark: Bookmark): void } | undefined;
  @Input() onUpdateBookmarkClicked?: { (bookmark: Bookmark): void } | undefined;

  //// Info given from the metric grid
  /** This configuration defines the configuration for the specific chart we wish to show as a timeline */
  @Input() card: MetricCardDataStore | undefined;
  /** The current theme of the app so we can tell what color to render for the chart. */
  @Input() theme!: TDMSTheme;
  /** If this timeline is being rendered in a session comparison. */
  @Input() isSessionComparison!: boolean;
  // /** The start date of the current grid range. Used so we can always keep the timeline to the max extent. */
  @Input() gridStartDate!: Date;
  /** The end date of the current grid range. Used so we can always keep the timeline to the max extent. */
  @Input() gridEndDate!: Date;

  /** This array contains bookmarks that should only be rendered on the timeline. This will combine the playback bookmark and the {@link gridLevelBookmarks} */
  gridBookmarks: Bookmark[] = [];

  /** This domain specifies when we choose to zoom in so we can inform other charts of the new domain */
  @Input() zoomDomainUpdater!: ZoomDomainUpdateEmitter;
  /** Used so we can update the padding based on if the transcription display is rendered or not. */
  @ViewChild("waveformContainer") waveformContainer!: ElementRef<HTMLDivElement>;
  /** This subject tracks when the safe space for the transcription display updates */
  safeSpace: Subject<SafeSpace> = new BehaviorSubject({ horizontal: 0, vertical: 0 });

  timeDomain: TimeDomain | undefined;
  loadingTranscriptionData: boolean = true;
  transcriptions: Transcription[][] | undefined;
  speakers: string[] | undefined;
  colorLookup: ColorMap | undefined;

  /** If the transcription display should be shown. Based off the audio plugin settings. */
  shouldDisplayTranscription: boolean = true;
  /** The last safe space calculation for the transcription display */
  lastSafeSpace: SafeSpace | undefined;

  constructor(
    @Inject(BG_AUDIO_INJECTION) bgAudioPlayer: BackgroundAudioComponent,
    private store: Store<TranscriptionState>,
    public audioService: AudioService,
    private themeService: ColorThemeService,
    private settingService: PluginSettingsService,
    cdr: ChangeDetectorRef
  ) {
    super(bgAudioPlayer, cdr);
  }

  ngOnInit() {
    if (this.isSessionComparison) {
      this.shouldDisplayTranscription = false;
    } else
      this.addSubscription(
        combineLatest([
          this.store.select(selectCurrentSession),
          this.store.select(selectAllTranscriptionsFromState).pipe(startWith(undefined)),
        ]).subscribe((data) => {
          const session = data[0];
          const transcriptions = data[1];
          if (session == null) return;
          this.addSubscription(
            this.themeService
              .observeColorsFor(
                session.roles.map((speaker) => {
                  return LineChartData.fromPlain({
                    name: speaker.name,
                  });
                })
              )
              .subscribe((colorMap) => {
                this.colorLookup = colorMap;
              })
          );
          if (transcriptions == null) return;
          this.timeDomain = { start: session.startDate, end: session.endDate };
          const parsed = Transcription.fromPlainArray(transcriptions);
          this.transcriptions = this.separateTranscriptionsBySpeaker(parsed);
          this.loadingTranscriptionData = false;
        })
      );
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.addSubscription(
      this.safeSpace.subscribe((space) => {
        this.lastSafeSpace = space;
        this.paddingToChart();
      })
    );

    if (!this.isSessionComparison) {
      this.addSubscription(
        this.settingService
          .observeSetting(AudioSettings.PLUGIN_NAME, AudioSettings.Names.transcriptionDisplayed)
          .subscribe((x) => {
            this.shouldDisplayTranscription = x.value;
            this.paddingToChart();
            this.cdr.detectChanges();
          })
      );

      // Listen for the playback bookmark updates so we can update our complete bookmarks
      this.addSubscription(
        this.audioService.getPlaybackBookmark(this.bgAudioPlayer).subscribe((x) => {
          this.gridBookmarks = this.gridLevelBookmarks.concat(x);
          this.cdr.detectChanges();
        })
      );
    }
  }

  /** Applies padding to offset the charts to line up with the transcription table */
  paddingToChart(space = this.lastSafeSpace) {
    const padding = !this.shouldDisplayTranscription || space == null ? `0` : `${space.horizontal - 10}px`;
    this.waveformContainer.nativeElement.style["paddingLeft"] = padding;
    // Adjust the waveforms title as well to help keep it centered based off that padding
    const waveformTitle = document.querySelector(".waveform-container .chart-title-row .title");
    if (waveformTitle) (waveformTitle as HTMLElement).style.setProperty("padding-right", padding);
  }

  separateTranscriptionsBySpeaker(transcriptions: Transcription[]): Transcription[][] {
    const speakerMap: { [speaker: string]: Transcription[] } = {};

    for (const transcription of transcriptions) {
      if (transcription.speaker == null) continue;
      if (speakerMap[transcription.speaker] == null) {
        speakerMap[transcription.speaker] = [transcription];
      } else {
        speakerMap[transcription.speaker].push(transcription);
      }
    }
    this.speakers = Object.keys(speakerMap).sort();
    return this.speakers.map((speaker) => speakerMap[speaker]);
  }

  getColor(value: string): string | undefined {
    return this.colorLookup?.find((mapping) => mapping.name == value)?.value;
  }

  /** Returns the pretty version of the session start */
  getPrettySessionStart() {
    if (this.isSessionComparison) return "00:00:00";
    return ChartXAxisFormatters.timeFormatter(this.gridStartDate);
  }

  /** Returns the pretty version of the session stop */
  getPrettySessionStop() {
    if (this.isSessionComparison) {
      const durationMillis = this.gridEndDate.getTime() - this.gridStartDate.getTime();
      const totalSeconds = Math.floor(durationMillis / 1000);

      const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, "0");
      const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, "0");
      const seconds = String(totalSeconds % 60).padStart(2, "0");

      return `${hours}:${minutes}:${seconds}`;
    }
    return ChartXAxisFormatters.timeFormatter(this.gridEndDate);
  }
}
