import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import {
  DataStoreDeleteRequest,
  DataStoreFile,
  DataStoreFileUpdate,
  DataStoreRestoreRequest,
  DataStoreTopics,
  TDMSWebSocketMessage,
  User,
  WebSocketCommunication,
} from "@tdms/common";
import { WebSocketService } from "@tdms/frontend/modules/communication/services/websocket.service";
import { DataStoreState } from "@tdms/frontend/modules/data-store/models/data.store.state";
import { NotificationService } from "@tdms/frontend/modules/notification/services/notification.service";
import { ConfigService } from "@tdms/frontend/modules/settings/services/config.service";
import {
  ConfirmationDialogComponent,
  ConfirmationDialogProperties,
  DialogWrapperComponent,
} from "@tdms/frontend/modules/shared/components";
import { Service } from "@tdms/frontend/modules/shared/services/base.service";
import { DataStoreActions } from "../models/store/data.store.action";

/**
 * A service to collect and provide recycle bin data for display on the recycle bin tab.
 * Also handles all recycle bin actions, e.g. restore, delete.
 */
@Injectable({ providedIn: "root" })
export default class RecycleBinService extends Service {
  constructor(
    private store: Store<DataStoreState>,
    private wsService: WebSocketService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private configService: ConfigService
  ) {
    super();
  }

  override async onUserChanged(_: User): Promise<void> {
    if (this.configService.pluginIsEnabled("DataStore")) {
      this.store.next(DataStoreActions.emptyRecycleBinFiles({}));
      const response = await this.wsService.sendAndReceive<DataStoreFile[]>(
        new TDMSWebSocketMessage(DataStoreTopics.getDeletion)
      );
      this.store.next(
        DataStoreActions.addRecycleBinFiles({ files: response.payload.map((x) => DataStoreFile.fromPlain(x)) })
      );
    }
  }

  /**
   * Set the given files to be deleted.
   * @param files The list of files to delete
   * @param permanent If these files should be permanently delete (true) or moved to the recycle bin (false)
   */
  async deleteFiles(files: DataStoreFile[], permanent = false) {
    return new Promise(async (res, _rej) => {
      let description: string;
      const filesToDelete = files.map((x) => x.uncompressedFileName).join(", ");

      // Change depending on if it's a permanent delete or not
      if (permanent) {
        description = `You are about to permanently delete the following files: ${filesToDelete}. This operation cannot be undone! Would you like to continue?`;
      } else {
        // Get some config data for messages
        const configData = await this.configService.waitForConfigData();
        const recycleTimeout = configData.dataStore.recycleBinTimeout;
        description = `You are about to schedule the following files for deletion: ${filesToDelete}. These will be moved to the recycle bin and deleted permanently in ${recycleTimeout} days. Would you like to continue?`;
      }
      // Open the dialog and wait for the prompt to complete
      this.dialog.open(ConfirmationDialogComponent, {
        data: {
          description: description,
          confirmClickCallback: async () => {
            for (let file of files)
              await this.wsService.sendAndReceive<DataStoreFile[]>(
                new TDMSWebSocketMessage(
                  DataStoreTopics.delete,
                  undefined,
                  new DataStoreDeleteRequest([file.id], permanent)
                )
              );
            this.notificationService.open(
              `${files.length > 1 ? "Files" : "File"} ${permanent ? "deleted" : "recycled"} successfully.`,
              "success"
            );
            res(undefined);
          },
          cancelClickCallback: () => {
            this.dialog.closeAll();
            res(undefined);
          },
        } as Partial<ConfirmationDialogProperties>,
        ...DialogWrapperComponent.getDefaultOptions(),
      });
    });
  }

  /**
   * Restores the given files so they are no longer scheduled for deletion
   */
  async restore(files: DataStoreFile[]) {
    const fileGrouping = files.map((x) => x.uncompressedFileName).join(", ");
    return new Promise<any>((res, _rej) => {
      this.dialog.open(ConfirmationDialogComponent, {
        data: {
          header: "Restore Confirmation",
          description: `Are you sure you want to restore the following files: ${fileGrouping}? This will cancel automatic file deletion.`,
          confirmButtonText: "Restore",
          confirmButtonColor: "accent",
          cancelButtonColor: "primary",
          confirmClickCallback: async () => {
            for (let file of files)
              await this.wsService.sendAndReceive<DataStoreFile[]>(
                new TDMSWebSocketMessage(DataStoreTopics.restore, undefined, new DataStoreRestoreRequest([file.id]))
              );
            this.notificationService.open(`${files.length > 1 ? "Files" : "File"} restored successfully.`, "success");
            this.dialog.closeAll();
            res(undefined);
          },
          /// Do nothing on cancel
          cancelClickCallback: () => {
            this.dialog.closeAll();
            res(undefined);
          },
          ...DialogWrapperComponent.getDefaultOptions(),
        },
      });
    });
  }

  /**
   * Returns the recycle bins timeout once the configuration is loaded for it
   */
  async getRecycleBinTimeout() {
    return (await this.configService.waitForConfigData()).dataStore.recycleBinTimeout;
  }

  /**
   * This message is echoed by the backend and allows us to
   * receive update events about the recycle bin asynchronously.
   * @param data The updated recycle bin data.
   */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<DataStoreFileUpdate[]>>(DataStoreTopics.update)
  async receiveBackendUpdates(data: TDMSWebSocketMessage<DataStoreFileUpdate[]>) {
    for (let update of data.payload) {
      /// Need to convert the plain javascript object to the proper type so that it has the fileName getter.
      const instantiatedFile = DataStoreFile.fromPlain(update.file);
      switch (update.type) {
        case "delete":
        case "restore":
          this.store.next(DataStoreActions.removeRecycleBinFile(instantiatedFile));
          break;
        case "recycle":
          /// Ad the newly recycled file.
          this.store.next(DataStoreActions.addRecycleBinFile(instantiatedFile));
          break;
        case "add":
          // We don't care about adds here.
          break;
      }
    }
  }
}
