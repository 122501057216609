import { AvailableMetricNamesType, AvailablePluginNamesType } from "../../plugins";

export namespace AudioSettings {
  export const PLUGIN_NAME: AvailablePluginNamesType = "Audio";

  /** Helper enum for audio setting names */
  export enum Names {
    transcriptionDisplayed = "transcription_displayed",
    visibleColumns = "visible_comm_columns",
    autoScroll = "auto_scroll",
  }

  /** Helper enum for audio setting titles */
  export enum HelpfulTitles {
    transcriptionDisplayed = "Transcription Displayed on Dashboard",
    visibleColumns = "Columns Displayed on Communication Table",
    autoScroll = "Playback auto scroll",
  }

  /** Helper enum for audio setting descriptions */
  export enum HelpfulDescriptions {
    transcriptionDisplayed = "Controls if the dashboard should render the transcription playback.",
    visibleColumns = "Allows you to specify what columns should be displayed within the communication table.",
    autoScroll = "Customizes if the playback on the communication table should keep the most recent utterance within view.",
  }
}

export namespace AudioWaveformSettings {
  export const PLUGIN_NAME: AvailableMetricNamesType = "AudioWaveform";

  /** Helper enum for audio setting names */
  export enum Names {
    useWaveform = "use_waveform",
  }

  /** Helper enum for audio setting titles */
  export enum HelpfulTitles {
    useWaveform = "Use waveform for timeline",
  }

  /** Helper enum for audio setting descriptions */
  export enum HelpfulDescriptions {
    useWaveform = "Controls if we should use the waveform for the timeline or fallback to the default line chart.",
  }
}
