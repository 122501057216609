/** Options for background noise filters in the metadata upload */
export enum AudioFilterModels {
  /** None is the original file with no modifications */
  NONE = "None",
  /*BD = "bd.rnnn",
    CB = "cb.rnnn",
    LQ = "lq.rnnn",
    MP = "mp.rnnn",
    SH = "sh.rnnn",*/
  STD = "std.rnnn", // https://jmvalin.ca/demo/rnnoise/
}
