<shared-dialog-wrapper header="Settings" class="settings-dialog" #dialogWrapper>
  <div class="settings-container">
    <mat-accordion class="setting-panel-accordion" multi>
      <ng-container *ngIf="settings !== null">
        <ng-container *ngFor="let collection of settings">
          <ng-container *ngIf="collection !== null">
            <div class="setting-panel-wrapper">
              <setting-panel [collection]="collection"></setting-panel>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </mat-accordion>
    <div class="section-separator"></div>
    <!-- Display versioning for this app -->
    <div class="version-row">
      <div>Frontend: {{ configService.frontendVersion }}</div>
      <div>Backend: {{ configService.backendVersion }}</div>
      <div>Build Date: {{ configService.webpackData.buildDate }}</div>
    </div>
  </div>
</shared-dialog-wrapper>
