import { TableColumnConfiguration, TableConfiguration } from "../../settings/table/settings";
import { User } from "../../user";
import { BaseSettings } from "../settings.base";

/** This namespace contains settings related to rendering the user management table */
export class UserManagementTableConfiguration extends TableConfiguration {
  static override readonly SettingConfig = {
    pluginName: BaseSettings.CORE_SETTINGS_PLUGIN_NAME,
    settingName: BaseSettings.Names.visibleUserManagementColumns,
  };

  static override readonly Columns = {
    Name: new TableColumnConfiguration<User, string>("username", "Name"),
    LastLogin: new TableColumnConfiguration<User, Date>("lastLoginTime", "Last Login"),
    Admin: new TableColumnConfiguration<User, boolean>("admin", "Is Admin?"),
    LockedOut: new TableColumnConfiguration<User, boolean>("lockedOut", "Locked Out?"),
  };
}
