<div id="generic-table-container" class="table-container">
  <!-- Filter form -->
  <div
    class="mat-elevation-z8 table-card"
    [ngClass]="{ filterable: filterable, guessedSize: shouldGuessPaginatorPageSize }"
  >
    <!-- Utility row -->
    <div id="generic-table-utility-row" class="utility-row mat-table">
      <!-- Flex place holder -->
      <div *ngIf="!filterable"></div>
      <!-- Filter row -->
      <div *ngIf="filterable" class="filter-form">
        <!-- Select for what we're searching on -->
        <mat-form-field appearance="outline" color="accent" class="search-form-field">
          <mat-label>Search On</mat-label>
          <mat-select [(value)]="filterOn" (selectionChange)="searchOnChange()">
            <mat-option
              class="generic-table-filter-option"
              *ngFor="let field of filterableFields"
              [value]="field.value"
            >
              {{ field.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Search term -->
        <mat-form-field color="accent" appearance="outline" class="search-form-field">
          <mat-label>Search...</mat-label>
          <input matInput type="text" [(ngModel)]="filterValue" (keyup)="applyFilter($event)" autocomplete="off" />

          <!-- Clear button -->
          <button
            *ngIf="filterValue != null && filterValue.length != 0"
            matSuffix
            mat-icon-button
            aria-label="Search"
            (click)="clearFilter()"
            class="clear-button"
          >
            <mat-icon>clear</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <!-- Buttons -->
      <div class="buttons">
        <!-- Add a new row -->
        <button *ngIf="addRowCallback" mat-raised-button color="accent" (click)="addRowCallback()">
          <mat-icon>add</mat-icon>Add New Row
        </button>
        <!-- Optional additional buttons at the end of the row -->
        <ng-container select="[replace=endingButtons]" *ngTemplateOutlet="buttonRowRef; context: {}"></ng-container>
        <!-- Column visibility -->
        <div class="column-visibility" *ngIf="allowColumnVisibilityInteraction">
          <!-- We use the select but we actually open it with the button below to look prettier. -->
          <shared-improved-select
            class="select"
            #columnVisibilitySelect
            [dropdownOptions]="visibilityColumnTitles"
            [selectedValue]="visibilityColumnRenderedTitles"
            [allowMultipleSelections]="true"
            (selectedValueChanged)="userUpdatedVisibleColumns($event)"
          >
          </shared-improved-select>
          <button
            mat-raised-button
            color="accent"
            matTooltip="Customize what columns are displayed"
            matTooltipPosition="above"
            (click)="columnVisibilitySelect.select.toggle()"
          >
            <mat-icon>filter_alt</mat-icon>
          </button>
        </div>
        <!-- Export button -->
        <button
          *ngIf="exportEnabled"
          mat-raised-button
          color="accent"
          (click)="exportTableData()"
          matTooltip="Export table contents to a .csv"
        >
          <mat-icon>download</mat-icon>Export
        </button>
      </div>
    </div>

    <!-- Data table -->
    <div class="table-wrapper" (mousewheel)="onScroll.emit($event)">
      <table
        mat-table
        matSort
        [dataSource]="dataSource"
        [matSortActive]="defaultSortHeader || ''"
        [matSortDirection]="defaultHeaderSortDirection"
        #genericTable
      >
        <!-- Render columns dynamically -->
        <ng-container *ngFor="let column of renderedColumns; let i = index" [matColumnDef]="column.name">
          <!-- Header cells so we can define title -->
          <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!column.isDataBacked">
            <!-- Checkbox for selection column -->
            <mat-checkbox
              *ngIf="column.name === selectionColumn.name"
              (change)="$event ? toggleAllRows() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
            >
            </mat-checkbox>

            <!-- Standard cells -->
            <ng-container *ngIf="column.name !== selectionColumn.name">
              <span class="header">
                <span>{{ column.title }}</span>
                <mat-icon *ngIf="column.tooltip" [matTooltip]="column.tooltip" matTooltipPosition="above" class="help">
                  help
                </mat-icon>
              </span>
            </ng-container>
          </th>
          <!-- Table cell -->
          <td mat-cell *matCellDef="let element">
            <!-- Spinner if the element has it enabled -->
            <shared-div-progress-component
              *ngIf="element.isProcessing && i === 0"
              class="generic-table-row-spinner"
              [ready]="false"
              [spinnerDiameter]="40"
              [ngStyle]="{ width: tableWidth + 'px', height: matRowHeight - 1 + 'px' }"
            >
            </shared-div-progress-component>

            <!-- Selection box. This needs rendered no matter if we have cell display because the user won't want to override this base capability. -->
            <ng-container *ngIf="column.name === selectionColumn.name">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(element) : null"
                [checked]="selection.isSelected(element)"
              >
              </mat-checkbox>
            </ng-container>

            <!-- Render the cell template provided by our parent component for this column. -->
            <ng-container *ngVar="displayMap.get(column.name) as display">
              <ng-container *ngIf="display != null; then overridden; else default"></ng-container>
              <ng-template #overridden>
                <ng-container
                  *ngTemplateOutlet="
                    display!.displayTemplateRef;
                    context: {
                      value: getCellValue(element, column),
                      column: column,
                      element: element
                    }
                  "
                ></ng-container>
              </ng-template>
            </ng-container>

            <ng-template #default>
              <!-- This is the old style single template override mechanism. -->
              <!-- This mechanism is deprecated and will be removed in the future. -->
              <!-- Prefer using individual <cell-display> elements as children for each column you'd like to override.-->
              <!-- Render the cell template provided by our parent component. -->
              <ng-container
                select="[replace=cellDisplay]"
                *ngTemplateOutlet="
                  cellDisplayRef;
                  context: {
                    value: getCellValue(element, column),
                    column: column,
                    element: element
                  }
                "
              ></ng-container>

              <!-- Render normal cell if no template was provided -->
              <span *ngIf="!cellDisplayRef">
                <!-- Standard override -->
                <ng-container *ngIf="column.name !== selectionColumn.name">
                  {{ getCellValue(element, column) }}
                </ng-container>
              </span>
            </ng-template>
          </td>
        </ng-container>

        <!-- Render columns headers to be displayed -->
        <tr mat-header-row *matHeaderRowDef="renderedHeaderNames; sticky: !shouldGuessPaginatorPageSize"></tr>
        <!-- The actual element row -->
        <tr
          mat-row
          class="table-row"
          *matRowDef="let row; columns: renderedHeaderNames; index as i"
          [id]="getTableRowId(row, i)"
          [ngClass]="{ clickable: clickCallback != null && !row.isProcessing, active: activeRows?.includes(row) }"
          (click)="clickCallback != null && !row.isProcessing ? clickCallback(row) : null"
        ></tr>

        <!-- Row shown when there is no matching data for filter. -->
        <tr class="mat-row no-data-row" *matNoDataRow>
          <td class="mat-cell" [colSpan]="renderedColumns.length">
            <!-- If we are using a filter, -->
            <span *ngIf="filterValue != null && filterValue.length > 0">
              No data matching the filter "{{ filterValue }}"</span
            >
            <!-- If we just have no data -->
            <span *ngIf="filterValue == null || filterValue?.length === 0"> No data available</span>
          </td>
        </tr>
      </table>
    </div>
    <!-- Footer of the table. Use the mat-table class to match colors. -->
    <div class="footer mat-table">
      <!-- Indicator if auto sizing is enabled -->
      <span>
        <mat-icon
          class="auto-indicator"
          *ngIf="shouldGuessPaginatorPageSize"
          matTooltip="This table is auto-sizing it's items per page"
        >
          autorenew
        </mat-icon>
      </span>
      <!-- Pagination -->
      <mat-paginator
        id="generic-table-paginator"
        class="paginator"
        color="accent"
        #contentTablePaginator
        [pageSize]="paginatorPageSize"
        [pageSizeOptions]="paginatorPageSizeOptions ?? [paginatorPageSize]"
        [showFirstLastButtons]="showFirstLastButtons"
        [disabled]="!paginationButtonsEnabled"
        [matTooltip]="!paginationButtonsEnabled ? paginationDisabledReasoning ?? '' : ''"
      >
      </mat-paginator>
    </div>
  </div>
</div>
