import { AfterViewInit, ChangeDetectorRef, Component, Inject } from "@angular/core";
import {
  BackgroundAudioComponent,
  BG_AUDIO_INJECTION,
} from "@tdms/frontend/modules/audio/components/background-audio/background-audio.component";
import { AudioPlayerComponent } from "@tdms/frontend/modules/shared/components";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";

/**
 * This component provides base capability that allows injecting the background audio player so it can be easily references and accessed
 *  for displaying in your own actual audio player.
 */
@Component({ template: "" })
export class BackgroundAudioBase extends SubscribingComponent implements AfterViewInit {
  /** This {@link AudioPlayerComponent} is the player reference to the background audio player. */
  player: AudioPlayerComponent | undefined;

  constructor(
    @Inject(BG_AUDIO_INJECTION) protected bgAudioPlayer: BackgroundAudioComponent,
    protected cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.addSubscription(
      this.bgAudioPlayer.playerReady.subscribe((x) => {
        this.player = x;
        this.cdr.detectChanges(); // Required to tell Angular that we now have our player
      })
    );
  }
}
