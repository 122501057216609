import { Component, Inject, OnDestroy, QueryList, ViewChildren } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Store } from "@ngrx/store";
import {
  AudioFilterModels,
  AudioTopics,
  DataStoreUploadOptionMetadataChange,
  MappingsAndUserid,
  Role,
  RoleMapping,
  RoleMappingRequest,
  RoleTopics,
  TDMSWebSocketMessage,
  User,
  UserIconOptions,
  Utility,
} from "@tdms/common";
import AudioService from "@tdms/frontend/modules/audio/services/audio.service";
import { WebSocketService } from "@tdms/frontend/modules/communication/services/websocket.service";
import { DownloadService } from "@tdms/frontend/modules/data-store/services/download.service";
import { AudioPlayerComponent, DialogWrapperComponent } from "@tdms/frontend/modules/shared/components";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import {
  UserEditComponent,
  UserToEditDialogProperties,
} from "@tdms/frontend/modules/user/components/user-edit/user-edit.component";
import { UserService } from "@tdms/frontend/modules/user/services/user.service";
import { selectAllUsersFromState } from "@tdms/frontend/modules/user/store/user.selector";
import { UserState } from "@tdms/frontend/modules/user/store/user.state";

/** The properties to display for the role mapping */
export interface RoleMappingDialogProps {
  request: RoleMappingRequest;
  onComplete: Function;
  onCancel: Function;
  sessionId?: number;
}

/** User type for selection dropdown */
export type SelectionUser = User & { name: string };

/**
 * A component to allow role mapping configuration for uploaded role based data for sessions
 */
@Component({
  selector: "role-mapping-dialog",
  templateUrl: "./role-mapping.component.html",
  styleUrls: ["./role-mapping.component.scss"],
})
export class RoleMappingComponent extends SubscribingComponent implements OnDestroy {
  /** Caches audio files to display */
  audioStore: { [path: string]: { blob: Blob; url: SafeResourceUrl } } = {};

  /** List of all audio players currently loaded into this role mapping */
  @ViewChildren(AudioPlayerComponent) audioPlayers!: QueryList<AudioPlayerComponent>;

  /** Users we can assign to roles */
  availableUsers: SelectionUser[] = [];

  /** Maps mapping to currently selected audioFilter */
  audioMap = new Map<RoleMapping, { model: AudioFilterModels; isProcessing: boolean }>();

  /** An array of available filter models for the dropdown */
  filterModels = Object.values(AudioFilterModels);

  /** A separated array of only the roles within this role mapping */
  rolesFromRoleMappings: Role[] = [];

  /** This property defines the original requests mappings so we can display the information of them */
  roleMapDialogPropMappings: MappingsAndUserid[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public properties: RoleMappingDialogProps,
    private store: Store<UserState>,
    private downloadService: DownloadService,
    private domSanitizer: DomSanitizer,
    private dialog: MatDialog,
    private userService: UserService,
    private wsService: WebSocketService,
    private audioService: AudioService
  ) {
    super();
    this.populateAudioStore();
    this.addSubscription(
      this.store
        .select(selectAllUsersFromState)
        .subscribe((users) => (this.availableUsers = users.map(this.getModifiedUser) as SelectionUser[]))
    );
    this.roleMapDialogPropMappings = properties.request.mappings;
    // Default all filters to none
    this.roleMapDialogPropMappings.forEach((x) =>
      this.audioMap.set(x.mapping, { model: AudioFilterModels.NONE, isProcessing: false })
    );
  }

  /** Given a user, turns it into the selection dropdown option */
  getModifiedUser(user: User | undefined) {
    if (!user) return undefined;
    const modifiedUser = user as SelectionUser;
    // Users don't have to have a name. Check that here
    if (user.firstName && user.lastName) modifiedUser.name = `${user.fullName} (${user.username})`;
    else modifiedUser.name = `${user.username}`;
    return modifiedUser;
  }

  getModifiedUserById(mappingAndUser: MappingsAndUserid) {
    if (mappingAndUser.userId != undefined) {
      return this.availableUsers.find((x) => x.id === mappingAndUser.userId!);
    }
  }

  /** Handles updating a mappings associated user */
  handleUserUpdate(mappingAndUser: MappingsAndUserid, user: SelectionUser | undefined) {
    mappingAndUser.userId = user?.id;
  }

  /** Handles updating a mappings filter and state */
  async handleFilterUpdate(mapping: MappingsAndUserid, model: AudioFilterModels) {
    const original = this.audioMap.get(mapping.mapping);
    // Set our new model information
    const isProcessing = model !== "None" && model !== original?.model;
    this.audioMap.set(mapping.mapping, { model, isProcessing });
    // Pause all audio players
    this.audioPlayers.forEach((x) => x.pause());
    // Send the request
    const message = DataStoreUploadOptionMetadataChange.fromPlain({
      value: model,
      audio: { path: mapping.mapping.playbackPath! },
    });
    const response = await this.wsService.sendAndReceive<DataStoreUploadOptionMetadataChange<any>>(
      new TDMSWebSocketMessage(AudioTopics.backgroundFilter, undefined, message)
    );
    // If we were tracking audio, go ahead and see if it needs updated
    const { blob, url } = await this.audioService.getAudioFileBlob(response.payload.audio!.path, "audio-filter");
    if (blob && url) {
      this.audioStore[mapping.mapping.playbackPath!] = { blob, url };
      mapping.mapping.filteredPlaybackPath = response.payload.audio?.path;
    }
    this.audioMap.set(mapping.mapping, { model, isProcessing: false });
  }

  /** Populates the audio file references into our store to link back to */
  async populateAudioStore() {
    for (let mapping of this.properties.request.mappings)
      if (mapping.mapping.playbackPath) {
        const playbackPath = mapping.mapping.playbackPath!;
        if (this.audioStore[playbackPath] == null) {
          const blob = await this.downloadService.downloadFile(mapping.mapping.playbackPath!, "role-mapping");
          // Sanitize the URL since its safely stored on this machine anyways
          if (blob) {
            const url = this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
            this.audioStore[playbackPath] = { blob, url };
          }
        }
      }
  }

  /** Returns if this set of data is valid or not with an error message. Controls if we can submit the metadata or not. */
  get validityError() {
    for (let mapping of this.properties.request.mappings) {
      const channelMapping = parseInt(`${mapping.mapping.channel}`);
      if (!mapping.mapping.userGivenName) return "All roles must have a name";
      else if (!mapping.mapping.sessionGrouping) return "All roles must be grouped to a session";
      else if (isNaN(channelMapping)) return "All channels must be filled out";
      else if (mapping.createdManually && mapping.userId == undefined)
        return "Manually added roles must have a user associated with them.";
    }
    // If any of our filters are processing, don't allow submitting
    if ([...this.audioMap.values()].some((value) => value.isProcessing))
      return "Audio filter is being applied. Please wait.";
    return undefined;
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.audioStore = {};
  }

  get canCreateUsers() {
    return this.userService.currentAuthenticatedUser?.hasPermissions(["createUser"], false);
  }

  openUserCreation(mappingAndUser: MappingsAndUserid) {
    this.dialog.open(UserEditComponent, {
      data: {
        userToEdit: User.fromPlain({ icon: Utility.randomValueFromArray(UserIconOptions) }),
        mode: "add",
        submitCallback: (user) => {
          // Assign the new user to that role mapping field
          const selectionUser = this.getModifiedUser(user);
          if (selectionUser) this.handleUserUpdate(mappingAndUser, selectionUser);
        },
      } as UserToEditDialogProperties,
      ...DialogWrapperComponent.getDefaultOptions(),
    });
  }

  /** Adds a MappingsAndUserId object to the session*/
  async addScratchRole() {
    const response = await this.wsService.sendAndReceive<MappingsAndUserid>(
      new TDMSWebSocketMessage(RoleTopics.createScratch, this.properties.sessionId)
    );
    this.roleMapDialogPropMappings.push(response.payload);
  }

  /** Removes a role created from scratch */
  async removeScratchRole(mapping: MappingsAndUserid) {
    const index = this.roleMapDialogPropMappings.findIndex((x) =>
      MappingsAndUserid.fromPlain(x).equals(MappingsAndUserid.fromPlain(mapping))
    );
    this.roleMapDialogPropMappings.splice(index, 1);
  }

  submit() {
    if (this.validityError == null) this.properties.onComplete();
  }
}
