import { Component, Input, OnInit } from "@angular/core";
import { Conversions, PieChartData, PopulatedSearchOption } from "@tdms/common";
import DataStoreMetricService from "@tdms/frontend/modules/data-store/services/data.store.metrics.service";
import { SearchService } from "@tdms/frontend/modules/data-store/services/search.service";
import { MetricGridDataStore } from "@tdms/frontend/modules/metrics/components/metric-grid/models/metric-grid.configuration";
import { TDMSTheme } from "@tdms/frontend/modules/shared/components";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import { of } from "rxjs";

/**
 * This component is the home tab of the data store manager.
 */
@Component({
  selector: "home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent extends SubscribingComponent implements OnInit {
  /**
   * If given, allows the rows to be clicked and fire this callback
   */
  @Input() onQueryUpdated: { (): void } | undefined;

  @Input() applicationTheme!: TDMSTheme;

  /**
   * The data store set to display in the metric grid
   */
  currentMetricDataStore: MetricGridDataStore | undefined;

  /**
   * This flag indicates whether or not the search service is executing a backend query right now.
   */
  isSearching: boolean = false;

  /** Total space used by all data store files by mb, not including cache or unmapped */
  totalSpaceUsed: number = 0;

  /** Total files in the data store */
  totalFiles: number = 0;

  constructor(private dataStoreMetricService: DataStoreMetricService, private searchService: SearchService) {
    super();
    this.onSearchStarted = this.onSearchStarted.bind(this);
  }

  async ngOnInit() {
    this.currentMetricDataStore = await this.loadGridDataStore();
    // Total size
    this.addSubscription(
      this.currentMetricDataStore.cards[0].subscribe((x) => {
        this.totalSpaceUsed = x.metric?.data?.reduce((a, b) => a + (b as PieChartData).value, 0) || 0;
      })
    );
    // Total files
    this.addSubscription(
      this.currentMetricDataStore.cards[1].subscribe((x) => {
        this.totalFiles = x.metric?.data?.reduce((a, b) => a + (b as PieChartData).value, 0) || 0;
      })
    );
  }

  /** Returns the total used space considering various sizing for a smaller number */
  getSizeString() {
    const mb = this.totalSpaceUsed;
    const gb = Conversions.Computing.megabyteToGigabyte(mb);
    const tb = Conversions.Computing.megabyteToTerabyte(mb);
    const pb = Conversions.Computing.megabyteToPetabyte(mb);
    if (pb > 1) return `${this.numberWithCommas(pb)} PB`;
    else if (tb > 1) return `${this.numberWithCommas(tb)} TB`;
    else if (gb > 1) return `${this.numberWithCommas(gb)} GB`;
    else return `${this.numberWithCommas(mb)} MB`;
  }

  /** Given a number adds commas for thousands place. Also provides fixed rounding. */
  numberWithCommas(num: number, fixed = 2) {
    return num.toFixed(fixed).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  /**
   * Returns the metric grid data store we intend to display
   */
  async loadGridDataStore() {
    return new MetricGridDataStore(
      [
        this.dataStoreMetricService.getPluginMetricByConfig(
          DataStoreMetricService.SPACE_PER_PLUGIN_CONFIG,
          "totalSpaceUsed",
          Conversions.Computing.byteToMegabyte.bind(this)
        ),
        this.dataStoreMetricService.getPluginMetricByConfig(
          DataStoreMetricService.TOTAL_FILES_PER_PLUGIN,
          "totalFiles"
        ),
      ],
      of(false),
      of(new Date()),
      of(new Date()),
      of([])
    );
  }

  /**
   * Called when user initiates search from query bar or advanced query builder.
   * Start the search and display the progress bar.
   * @param options The search options provided.
   */
  async onSearchStarted(options: PopulatedSearchOption[]) {
    this.isSearching = true;
    await this.searchService.startInteractiveQuery(options);
    this.isSearching = false;
  }
}
