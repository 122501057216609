<div class="notification-menu-container">
  <button
    #notificationMenuButton
    class="notification-menu-button"
    mat-icon-button
    [matMenuTriggerFor]="notificationMenu"
    (click)="acknowledgeAll()"
  >
    <mat-icon *ngIf="nonAcknowledgedCount === 0">notifications</mat-icon>
    <mat-icon *ngIf="nonAcknowledgedCount !== 0">notification_important</mat-icon>
  </button>
  <div class="notification-number" *ngIf="nonAcknowledgedCount !== 0">
    <div class="inner">{{ nonAcknowledgedCount }}</div>
  </div>
  <mat-menu #notificationMenu="matMenu" class="notification-menu" xPosition="before">
    <span class="header">Past Notifications</span>
    <!-- Render each notification -->
    <div class="toasts">
      <notification-toast
        *ngFor="let notification of notifications"
        [toast]="notification"
        [canDismiss]="false"
        [shouldAnimate]="false"
        class="notification"
      ></notification-toast>
      <!-- Display when we have no notifications -->
      <span *ngIf="notifications.length === 0" class="no-notifications">No notifications available</span>
    </div>
  </mat-menu>
</div>
