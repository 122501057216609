import { Component } from "@angular/core";
import { WaveformChartData } from "@tdms/common";
import { ExtendedChartOptions } from "@tdms/frontend/modules/charts/shared/plugins/plugin.typing";
import { ChartOptions, ChartType } from "chart.js";
import { merge } from "lodash-es";
import { TimelineSelectionBaseComponent } from "../shared/timeline/timeline.selection.base";

/**
 * This chart defines a waveform component. It's built off of a line chart that instead renders bars where the height is based on the given data
 *  in a certain range.
 *
 * The certain range we are aiming for is 0-100. This will be equalized on either side of this and is expected to be a decimal percentage of bar fill.
 */
@Component({
  selector: "charts-waveform[data][colorLookup][configuration]",
  templateUrl: "../shared/base/base.component.html",
  styleUrls: ["../shared/base/base.component.scss"],
})
export class WaveformChartComponent<
  ChartDataType extends WaveformChartData = WaveformChartData
> extends TimelineSelectionBaseComponent<ChartDataType, "line"> {
  /** Default color of the waveform if there is no color identity associated */
  static readonly DEFAULT_IDENTITY_COLOR = "#000000";
  override chartType: ChartType = "line";
  override minHeight = 56;

  override getChartData(dataSet: ChartDataType[]) {
    this.setMinMax(dataSet);
    return this.filterDataset(dataSet).map((x) => {
      const enforcedColors = x.series.map(() => this.getColor(x.name) || WaveformChartComponent.DEFAULT_IDENTITY_COLOR);
      return {
        label: x.name,
        data: x.series.flatMap((z) => {
          /**
           * Use the data value as both the min and max range. All values should be assumed to be calculated from `convertAudioDataToWaveform`
           *  which means they will be in a 0 - 1 range
           */
          const value = parseFloat((z.value * 100).toFixed(0));
          const y = [-value, value];
          return {
            y,
            x: z.name.getTime(),
          };
        }),
        backgroundColor: enforcedColors, // Necessary for tooltip display
        borderColor: enforcedColors,
        type: "bar" as any, // Type casting issues as it expects a "line",
        // Enforce gaps
        categoryPercentage: 1,
        barPercentage: 1,
      };
    });
  }

  override chartOptionOverrides(coreOptions: ChartOptions<"line">): ExtendedChartOptions<"line"> {
    const superOptions = super.chartOptionOverrides(coreOptions);
    const newOptions = {
      scales: {
        x: {
          type: "time",
        },
        y: {
          min: -100,
          max: 100,
          display: false,
        },
      },
      plugins: {
        customTooltip: {
          styling: {
            smallMode: true,
          },
        },
      },
      layout: {
        padding: {
          left: this.isTimeline ? 20 : undefined,
        },
      },
    } as Partial<ExtendedChartOptions<"line">>;
    return merge(superOptions, newOptions);
  }
}
