import { Component, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Tag, TagType } from "@tdms/common";
import { ConfigService } from "@tdms/frontend/modules/settings/services/config.service";
import {
  ConfirmationDialogComponent,
  ConfirmationDialogProperties,
  DialogWrapperComponent,
} from "@tdms/frontend/modules/shared/components";
import { TagService } from "@tdms/frontend/modules/tag/services/tag.service";

/** Properties for the dialog popup regarding a tag */
export interface TagInfoDialogProperties {
  tag: Tag;
  /** Used so we can determine if a duplicate tag exists and just use that one instead */
  totalTags: Tag[];
  /** Determines if we should treat this as an edit or create of a tag */
  type: "edit" | "create";
  /** Called after we successfully update or edit a tag */
  onComplete: { (tag: Tag): void } | undefined;
}

/** A component used within dialogs to allow editing or creation of tags */
@Component({
  selector: "tag-info",
  templateUrl: "./tag-info.component.html",
  styleUrls: ["./tag-info.component.scss"],
})
export class TagInfoComponent {
  tagSubmitted = false;
  form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public properties: TagInfoDialogProperties,
    private tagService: TagService,
    private dialogRef: MatDialogRef<any>,
    public configService: ConfigService,
    private dialog: MatDialog
  ) {
    this.form = new FormGroup({
      name: new FormControl(
        {
          value: properties.tag.name,
          disabled:
            properties.type === "create"
              ? !configService.configData?.tag.allowCreation
              : !configService.configData?.tag.allowEditing,
        },
        [Validators.required]
      ),
      color: new FormControl({ value: properties.tag.color, disabled: false }, [Validators.required]),
      type: new FormControl({ value: TagType[properties.tag.type], disabled: true }, [Validators.required]),
    });
    // Disable form if config says to do so
    if (
      properties.type === "create"
        ? !configService.configData?.tag.allowCreation
        : !configService.configData?.tag.allowEditing
    )
      this.form.disable();
  }

  /** Returns if the values have actually changed. If we are not in edit mode, will always resolve true. */
  get editValuesHaveChanged() {
    return this.properties.type !== "edit" || !this.tagFromForm.equals(this.properties.tag);
  }

  get tagTypes() {
    const keys = Object.keys(TagType)
      .map((key) => TagType[key as keyof Object])
      .filter((value) => typeof value === "string") as any[];
    return keys;
  }

  get maxTagLength() {
    return Tag.MAX_NAME_LENGTH;
  }

  get tagFromForm() {
    const tag = this.properties.tag.clone();
    tag.name = this.form.controls["name"].value;
    tag.color = this.form.controls["color"].value;
    tag.type = TagType[this.form.controls["type"].value] as any as TagType;
    return tag;
  }

  async submit() {
    if (!this.editValuesHaveChanged || !this.form.valid) return;
    let tag = this.tagFromForm;
    // Check if a matching tag already exists and just complete with that instead.
    const existingTag = Tag.checkUnique(this.properties.totalTags, tag);
    if (existingTag != null) {
      tag = existingTag;
    } else {
      this.tagSubmitted = true;
      // Ask the service to create the tag
      const result = await (this.properties.type === "create" ? this.tagService.add(tag) : this.tagService.update(tag));
      this.tagSubmitted = false;
      if (!result.success) return; // Don't close the dialog on failure
      else tag = Tag.fromPlain(result.payload);
    }
    this.dialogRef.close();
    this.properties.onComplete?.call(this, tag);
  }

  deleteTag() {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        description:
          "Are you sure you want to remove this tag? This will remove it from every element using it. This cannot be undone!",
        confirmButtonText: "Remove",
        confirmClickCallback: () => {
          this.tagService.delete(this.properties.tag);
          this.dialogRef.close();
        },
        cancelClickCallback: undefined,
      } as Partial<ConfirmationDialogProperties>,
      ...DialogWrapperComponent.getDefaultOptions(),
    });
  }
}
