import { TDMSBase } from "../base";

export type CommonSettingMap = Map<string, CommonSetting>;

/**
 * The supported types that a setting value can be.
 * This helps us provide some type checking to setting values.
 * Custom types could be implemented using the string type and a JSON representation, or added to this enum.
 */
export enum CommonSettingType {
  /** Boolean settings are also supported within the quick settings menu on charts */
  boolean = "boolean",
  string = "string",
  number = "number",
  array = "array",
}

/**
 * A single setting name/value pair that should be attached to the collection defined for one plugin/metric.
 */
export interface CommonSetting {
  name: string;
  value: any;
  settingType: CommonSettingType;

  /**
   * If {@link settingType} is an array, this value will help allow the frontend to determine the supported options.
   *
   * **Not stored in the db.** This allows it to always be up to date as changes are made.
   */
  arrayOptions?: Array<any>;
}

/**
 * A collection of settings that is defined by a single plugin/metric and attached to a user.
 * Plugins defined the default set of settings that they support,
 * and whenever a new user is created, a collection will be generated to tie to that user.
 */
export interface CommonSettingCollection {
  plugin: string;
  settings: CommonSetting[];
}

/**
 * Setting descriptions provide a helpful title and description that can be presented on screen to the user.
 * These should be defined by the plugin/metric in the backend.
 */
export interface CommonSettingDescription {
  name: string;
  helpfulTitle: string;
  helpfulDescription: string;
}

/**
 * Setting collection descriptions contain a set of @CommonSettingDescriptions for each setting in the collection,
 * as well as a helpful title and description for the plugin/metric itself.
 */
export interface SettingCollectionDescription {
  name: string;
  helpfulTitle: string;
  helpfulDescription: string;
  settingDescriptions: CommonSettingDescription[];
}

/**
 * Represent a collection of color data attached to a theme.
 */
export interface CommonColorTheme {
  name: string;
  colors: string[];
}

/**
 * Request that asks the backend for all available user settings for the currently logged in user.
 * This will error if no user is logged in.
 */
export class GetUserSettingsRequest extends TDMSBase {}

/**
 * Request that asks the backend for the available color themes.
 * Empty request as no parameters are required for this.
 */
export class GetColorThemesRequest extends TDMSBase {}

/**
 * Request for the backend to get setting descriptions for the provided setting collections.
 * Should be called after settings are loaded for a given user account.
 */
export class GetSettingDescriptionsRequest extends TDMSBase {}
