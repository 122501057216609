import { Type } from "class-transformer";
import { TDMSBase } from "./base";
import { Utility } from "./utility";

/** This class defines the structure for a notification that has occurred within TDMS */
export class Notification extends TDMSBase {
  /** The maximum number of notifications each user should be able to have in the database */
  static readonly MAX_NOTIFICATIONS_PER_USER = 10;

  /** A unique identifier for this notification in the db */
  id: number;
  /** The user Id that this notification is associated to */
  userId: number;
  /** The title to display for this notification */
  title: string;
  /** The message related to this notification */
  message: string;
  /** The type of this notification used for some display elements */
  type: "normal" | "success" | "warning" | "error";
  /**
   * This value tracks if the notification has been seen.
   * - `user`: Means the user clicked the X when the toast appeared or opened the past notifications panel
   * - `auto`: Means another notification appeared causing the original to go away (we only display one at a time). The system
   *  automatically acknowledged this. These will appear as a number in the past notification panel.
   * - `undefined`: The notification hasn't been seen and hasn't been automatically moved
   */
  acknowledgement: "user" | "auto" | undefined;
  /** The date at which this notification occurred. */
  @Type(() => Date)
  date: Date;
  /** A boolean that can help override if the notification can be dismissed by the user. Default is true, not managed in the database. */
  canDismiss = true;

  constructor(
    id: number,
    userId: number,
    message: string,
    type: Notification["type"],
    title?: string,
    acknowledgement: Notification["acknowledgement"] = undefined,
    date = new Date()
  ) {
    super();
    this.id = id;
    this.userId = userId;
    this.message = message;
    this.type = type;
    this.title = this.autoSetTitle(title);
    this.acknowledgement = acknowledgement;
    this.date = date;
  }

  /** If the title isn't defined, auto sets the title based on the type. */
  autoSetTitle(title?: string) {
    // Set title based on type
    if (title == null)
      if (this.type === "error") title = "Error";
      else if (this.type === "warning") title = "Warning";
      else if (this.type === "success") title = "Success";
      else title = "Info";
    this.title = title;
    return title;
  }

  get prettyDate() {
    return Utility.getDateAsZulu(this.date);
  }

  /** Creates a notification from the given error and returns it */
  static fromError(error: Error) {
    const notification = Notification.fromPlain({ message: error.message, type: "error" });
    notification.autoSetTitle();
    return notification;
  }
}
