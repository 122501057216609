import { Type } from "class-transformer";
import { decorate } from "ts-mixer";
import { TDMSBase } from "../../base";
import { CustomTypes } from "../../custom.types";

/** Types that are supported by the metadata requests */
export type UploadMetadataSupportedTypes = string | number | Date;

/** This class defines some specific piece of metadata we are looking for */
export class DataStoreUploadMetadataOption<T extends UploadMetadataSupportedTypes> extends TDMSBase {
  name: string;
  /** help text to provide more context */
  help?: string;
  /** The type of metadata this request is. If {@link dropdownOptions} is defined and this is typeof string, we will render dropdowns. */
  type: CustomTypes.TypeName<UploadMetadataSupportedTypes> = "string";
  /** Value that will have the users input option */
  value: T;
  /** Defines the options available to instead display a dropdown. Only works if {@link type} is typeof string. */
  dropdownOptions?: {
    /** The options to render in the dropdown */
    options: string[];
    /** The text to display above the dropdown for help on what they're selecting. */
    placeholder: string;
    /** If a dropdown value changes, and this value is set, a message will be sent to this endpoint informing of the new value. */
    valueChangeTopic?: string;
  };
  /** Provides metadata for you to allow an audio file playback for this specific metadata option to additionally be rendered. */
  audio?: {
    /** Path of the audio file via the data store http server. */
    path: string;
  };

  /** Returns the internally processed type that considers defined properties to modify the output type/. */
  get internalType() {
    if (this.type === "string" && this.dropdownOptions != null) return "dropdown";
    else return this.type;
  }

  constructor(
    name: string,
    type: DataStoreUploadMetadataOption<T>["type"],
    value: T,
    help?: string,
    dropdownOptions?: DataStoreUploadMetadataOption<T>["dropdownOptions"],
    audio?: DataStoreUploadMetadataOption<T>["audio"]
  ) {
    super();
    this.name = name;
    this.type = type;
    this.value = value;
    this.help = help;
    this.dropdownOptions = dropdownOptions;
    this.audio = audio;
  }
}

/** This class defines metadata that will be sent to the backend when dropdown options change in the event the `valueChangeTopic` is defined within the option. */
export class DataStoreUploadOptionMetadataChange<T extends UploadMetadataSupportedTypes> extends TDMSBase {
  /** Value that will have the users input option */
  value: T;
  /** If defined, allows you to update the audio of the original response */
  audio?: DataStoreUploadMetadataOption<T>["audio"];

  constructor(value: T) {
    super();
    this.value = value;
  }
}

/** This class defines the group of options to ask for based on the corresponding file*/
export class DataStoreUploadMetadataGroup extends TDMSBase {
  /** The file name that this group corresponds to */
  fileName: string;

  /** The name of the plugin this metadata request came from */
  pluginName: string;

  /** The options we should be asking for */
  @decorate(Type(() => DataStoreUploadMetadataOption))
  options: Array<DataStoreUploadMetadataOption<any>>;

  constructor(fileName: string, pluginName: string, options: Array<DataStoreUploadMetadataOption<any>>) {
    super();
    this.fileName = fileName;
    this.pluginName = pluginName;
    this.options = options;
  }
}

/**
 * This class defines the request/response information required for the metadata information
 */
export class DataStoreUploadMetadata extends TDMSBase {
  @decorate(Type(() => DataStoreUploadMetadataGroup))
  groups!: Array<DataStoreUploadMetadataGroup>;
  /** Tracks if this request was completed and the values inside {@link groups} are updated correctly. */
  complete: boolean = false;
}
