import { TDMSBase } from "../../base";
import { AudioFilterModels } from "./filter.models";
import { Transcription, TranscriptionModelOptionsType } from "./transcription";
import { VADParameters } from "./vad";

/** The base class that audio analysis requests support. */
export class AudioAnalyzeRequestBase extends TDMSBase {
  /**
   * If we should attempt to process our audio files utilizing CUDA capabilities, if available.
   *
   * Note: CUDA capabilities do not transcribe multiple files at once since the GPU cache's the transcription so the API is
   *  not flexible enough to support this.
   */
  useGPUIfAvailable: boolean = true;
}

/** This class defines a request for analyzing a transcript that was generated elsewhere. */
export class AudioAnalyzeTranscriptRequest extends AudioAnalyzeRequestBase {
  /** The transcription you want to perform an analysis of */
  transcription!: Transcription[];
}

/**
 * A class used to define the request to the audio microservice for analyzing audio files
 */
export class AudioAnalyzeRequest extends AudioAnalyzeRequestBase {
  /**
   * This request identifies how to pass content that the microservice should process. This could be thought of as an array of files or a single file. It has two modes:
   *  1. A single string: This means that we should process this single URL as the request and run the config values below
   *  2. An array of urls and identifiers: This means we should process each file given individually based on config values below, recombine the results, and return it.
   */
  file!:
    | string
    | {
        /** The URL to download the file from */
        url: string;
        /** This identifier will be applied as the speaker identity, assuming VAD isn't run to associate transcription content to this value */
        identifier: string;
      }[];

  /**
   * The JWT to authenticate for the download with
   */
  token!: string;

  /**
   * The model to utilize during the request
   */
  transcriptionModel: TranscriptionModelOptionsType = "medium.en";

  /** How many speakers are in the file for diarization. Helps tune the accuracy. */
  diarizeSpeakerCount: number = 3;

  /** If we should send this request to the diarization engine */
  diarize: boolean = false;

  /** If we should send this request to the transcription engine */
  transcribe: boolean = false;

  /** If set to true along side `transcribe`, this will allow us to perform an analysis on the results of a transcription for more metadata. */
  analyze: boolean = true;

  /** If we should send this request to the vad engine */
  vad: boolean = false;

  /** If this request should ignore the cache on the microservice and force a regeneration */
  bypassCache: boolean = false;

  /** The paramaters to control the VAD model invocation (default if empty). */
  vadParams: VADParameters = new VADParameters();
}

/** This class defines the structure of a request to filter the audio file. In the event this is successful, the same object will be returned with the new URL. */
export class AudioFilter extends TDMSBase {
  /* Location of the audio file to be adjusted. This should be relative to the cache. */
  audioFilePath!: string;

  /** Audio filter model selected */
  filterNeuralNet: AudioFilterModels = AudioFilterModels.STD;

  /** This is the location/url to the data store's http server of the audio file that has been successfully filtered, if set. If not set, we will fall back to the original path. */
  url?: string;
}

/** Class that defines a request to refresh transcription data. Same class will be used for returns. */
export class TranscriptionRefresh extends TDMSBase {
  audioFileId!: number;
}

/** Class that defines a request to edit transcription data. Same class will be used for the response */
export class TranscriptionEdit extends TDMSBase {
  transcriptions!: Transcription[];
}
