import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { Notification } from "@tdms/common";
import { NotificationState } from "../notification.state";
import { NotificationActions } from "./notification.action";

/** NGRX entity adapter */
export const notificationAdapter: EntityAdapter<Notification> = createEntityAdapter<Notification>({
  selectId: (notification) => notification.id,
});

/** Reducer for notification data NGRX Store */
export const notificationReducer = createReducer(
  notificationAdapter.getInitialState({} as NotificationState),
  on(NotificationActions.add, (state, data) => notificationAdapter.addMany(data.data, state)),
  on(NotificationActions.update, (state, data) =>
    notificationAdapter.updateOne({ id: data.data.id, changes: data.data }, state)
  ),
  on(NotificationActions.updatemany, (state, data) =>
    notificationAdapter.updateMany(
      data.data.map((x) => ({ id: x.id, changes: x })),
      state
    )
  ),
  on(NotificationActions.empty, (state, _data) => notificationAdapter.removeAll(state)),
  on(NotificationActions.delete, (state, data) => notificationAdapter.removeOne(data.id, state))
);
