import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { Notification } from "@tdms/common";
import { NotificationState } from "@tdms/frontend/modules/notification/models/notification.state";
import { selectAllNotificationsFromState } from "@tdms/frontend/modules/notification/models/store/notification.selector";
import { NotificationService } from "@tdms/frontend/modules/notification/services/notification.service";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";

/** This component provides a notification button and a menu that will open when the button is selected to display notifications that have been acknowledged related to this user. */
@Component({
  selector: "notification-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class NotificationMenuComponent extends SubscribingComponent {
  /** Notifications that should be rendered */
  notifications: Notification[] = [];

  /** Notifications that were not yet acknowledged by the user. */
  nonAcknowledgedNotifications: Notification[] = [];

  constructor(private store: Store<NotificationState>, public notificationService: NotificationService) {
    super();
    this.addSubscription(
      this.store.select(selectAllNotificationsFromState).subscribe((x) => {
        // Re apply typing as the store might remove it
        this.notifications = Notification.fromPlainArray(x).reverse(); // Reverse them so newest is first
        this.nonAcknowledgedNotifications = this.notifications.filter((z) => z.acknowledgement !== "user");
      })
    );
  }

  /** How many notifications were automatically cleared by the system */
  get nonAcknowledgedCount() {
    return this.nonAcknowledgedNotifications.length;
  }

  /** Acknowledges every notification that doesn't have a user acknowledgement state. */
  acknowledgeAll() {
    this.notificationService.markAllRead();
  }
}
