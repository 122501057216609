import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { Tag, TagType } from "@tdms/common";
import { ConfigService } from "@tdms/frontend/modules/settings/services/config.service";
import { DialogWrapperComponent } from "@tdms/frontend/modules/shared/components";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import {
  TagInfoComponent,
  TagInfoDialogProperties,
} from "@tdms/frontend/modules/tag/components/tag-info/tag-info.component";
import {
  TagOverflowDisplayComponent,
  TagOverflowDisplayProperties,
} from "@tdms/frontend/modules/tag/components/tag-overflow-display/tag-overflow-display.component";
import { selectAllTagsFromState } from "@tdms/frontend/modules/tag/store/tag.selector";
import { TagState } from "@tdms/frontend/modules/tag/store/tag.state";
import { cloneDeep } from "lodash-es";

/** A component used to display multiple tags within a row for use in other components. Also contains adding and selection capabilities. */
@Component({
  selector: "tag-display[tags]",
  templateUrl: "./tag-display.component.html",
  styleUrls: ["./tag-display.component.scss"],
})
export class TagDisplayComponent extends SubscribingComponent {
  /** The tags we wish to render in this current tag display */
  @Input() tags!: Tag[];

  /** All available tags we could use. */
  @Input() totalTags: Tag[] = [];

  /** If we should show the edit dropdown to display more options to select */
  @Input() showSelection = false;

  /** If we are able to remove the labels from being selected */
  @Input() canRemove = false;

  /** If we can create new labels */
  @Input() canCreate = false;

  /** If we should wrap the elements or cut them off for overflow */
  @Input() shouldWrap = false;

  /** If our tags can be edited */
  @Input() canEdit = false;

  /**
   * Called whenever we remove, add, or any of the other handling to adjust the tags associated to the given element that
   *  gave us the {@link tags} input.
   */
  @Output() selectedTagsChanged = new EventEmitter<Tag[]>();

  constructor(private store: Store<TagState>, private dialog: MatDialog, public configService: ConfigService) {
    super();
    this.addSubscription(
      this.store.select(selectAllTagsFromState).subscribe((tags) => {
        this.totalTags = Tag.fromPlainArray(tags);
      })
    );
  }

  /** When a tag is unassigned by clicking the X on the tag */
  onTagRemove(tag: Tag) {
    const matchingTagIndex = this.tags.findIndex((x) => x.id === tag.id);
    if (matchingTagIndex !== -1) {
      const tagsClone = cloneDeep(this.tags);
      tagsClone.splice(matchingTagIndex, 1);
      this.selectedTagsChanged.emit(tagsClone);
    }
  }

  dropdownTagsChanged(selectedValues: Tag[] | undefined) {
    if (selectedValues) this.selectedTagsChanged.emit(selectedValues);
  }

  /** Opens a dialog for us to create or edit a tag within */
  openTagInfo(
    tag: Tag = Tag.fromPlain({ name: "New Tag", type: TagType.Session }),
    type: "create" | "edit" = "create"
  ) {
    this.dialog.open(TagInfoComponent, {
      data: {
        tag,
        totalTags: this.totalTags,
        type: type,
        onComplete:
          type === "create"
            ? (tag) => {
                if (!this.tags.some((x) => x.equals(tag))) {
                  const totalTags = cloneDeep(this.tags).concat(tag);
                  this.selectedTagsChanged.emit(totalTags);
                }
              }
            : undefined,
      } as TagInfoDialogProperties,
      ...DialogWrapperComponent.getDefaultOptions(),
    });
  }

  openTagDisplayDialog(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dialog.open(TagOverflowDisplayComponent, {
      data: {
        tags: this.tags,
      } as TagOverflowDisplayProperties,
      ...DialogWrapperComponent.getDefaultOptions(),
    });
  }
}
