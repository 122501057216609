export namespace DataStoreSettings {
  export const PLUGIN_NAME = "DataStore";

  /** Helper enum for data store setting names */
  export enum Names {
    accessLogVisibleColumns = "visible_access_columns",
    recycleVisibleColumns = "visible_recycle_columns",
    searchVisibleColumns = "visible_search_columns",
  }

  /** Helper enum for data store setting titles */
  export enum HelpfulTitles {
    accessLogVisibleColumns = "Columns Displayed on the Access Log Table",
    recycleVisibleColumns = "Columns Displayed on the Recycle Bin Table",
    searchVisibleColumns = "Columns Displayed on the Search Table",
  }

  /** Helper enum for data store setting descriptions */
  export enum HelpfulDescriptions {
    accessLogVisibleColumns = "Allows you to specify what columns should be displayed within the access log table.",
    recycleVisibleColumns = "Allows you to specify what columns should be displayed within the recycle bin table.",
    searchVisibleColumns = "Allows you to specify what columns should be displayed within the search table.",
  }
}
