import { instanceToPlain, plainToInstance } from "class-transformer";
import { cloneDeep, isEqual } from "lodash";
import { CustomTypes } from "./custom.types";

/**
 * This class provides base functionality that is reusable across all TDMS objects.
 */
export class TDMSBase {
  /**
   * Given a string in JSON format, converts it to the type defined by `this`.
   * @param val The string to convert into an object. Expected to be JSON format.
   * @returns An instance of `this` with the given objects data
   */
  static fromPlainString<T extends TDMSBase>(this: CustomTypes.ConstructorFunction<T>, val: string) {
    return (this as any as typeof TDMSBase).fromPlain(JSON.parse(val)) as T;
  }

  /**
   * Given an array of objects, converts it to the type defined by `this`.
   * @param obj The objects to convert.
   * @returns The instances of `this` with the given objects data
   */
  static fromPlainArray<
    T extends TDMSBase,
    Z extends T[],
    DataTyping extends CustomTypes.OmitType<CustomTypes.OnlyAllowKnown<T>, Function>
  >(this: CustomTypes.ConstructorFunction<T>, obj: Partial<DataTyping>[]) {
    // I was unable to condense `fromPlainArray` into `fromPlain` because utilizing spread operators (...) inside an object
    //  made this function think it was an array when it was truly just a single object so it would attempt to return
    //  an array which was incorrect.
    return plainToInstance(this, obj) as Z;
  }

  /**
   * Given an object, converts it to the type defined by `this`.
   * @param obj The object to convert.
   * @returns An instance of `this` with the given objects data
   */
  static fromPlain<T extends TDMSBase, Z extends T>(this: CustomTypes.ConstructorFunction<T>, obj: Partial<Z> | Z) {
    return plainToInstance(this, obj) as Z;
  }

  /**
   * Similar to `fromPlain` but removes some type restrictions
   * @param obj The object to convert.
   * @returns An instance of `this` with the given objects data
   */
  static fromPlainExtended<T extends TDMSBase, Z extends T, DataTyping extends CustomTypes.OnlyAllowKnown<Z>>(
    this: CustomTypes.ConstructorFunction<T>,
    obj: Partial<DataTyping>
  ) {
    return plainToInstance(this, obj) as Z;
  }

  /**
   * Returns `this` as a plain javascript object
   */
  toPlain() {
    return instanceToPlain(this, { enableCircularCheck: true });
  }

  /**
   * Returns `this` as a plain ole JSON object
   */
  toJSONString() {
    return JSON.stringify(this.toPlain());
  }

  /**
   * Clones the currently instance of `this` (deeply) and returns it
   */
  clone() {
    return cloneDeep(this);
  }

  /**
   * Given a comparison value, returns if that value and the instance of `this` are equal
   * @returns A boolean. True being equal, false being not equal.
   */
  equals(compareTo: this | undefined) {
    return isEqual(this.toJSONString(), compareTo?.toJSONString());
  }
}
