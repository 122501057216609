import { Type } from "class-transformer";
import { TDMSBase } from "./base";
import { RolePermission, RolePermissionType } from "./permission";
import { CommonSetting } from "./settings/settings";

/** Available icon options for users */
export const UserIconOptions = [
  "bear",
  "butterfly",
  "deer",
  "elephant",
  "koala",
  "lion",
  "monkey",
  "owl",
  "turtle",
  "wolf",
] as const;
export type UserIconOptionsType = (typeof UserIconOptions)[number];

/**
 * This class defines what a user will look like in the TDMS ecosystem
 */
export class User extends TDMSBase {
  /**
   * The primary key for this user
   */
  id: number;

  username: string;

  /**
   * The first name of this user
   */
  firstName?: string;

  /**
   * The last name of this user
   */
  lastName?: string;

  /**
   * The DOD ID pulled from the users certificate
   */
  dodId?: string;

  /**
   * The date time this user was created
   */
  @Type(() => Date)
  created: Date = new Date();

  /**
   * The user id that created this user
   */
  createdById: number | undefined;

  /**
   * Controls if this user should have admin capabilities across the application
   * @deprecated This will be removed in support of RBAC in the future. Don't rely too heavily on this.
   */
  admin: boolean = false;

  /**
   * The icon for this user when available.
   */
  icon: UserIconOptionsType = "koala";

  /**
   * Tracks when the last time this user logged in.
   */
  @Type(() => Date)
  lastLoginTime: Date | undefined = undefined;

  /**
   * How many times the user has failed to input their password in succession
   */
  failedPasswordCount: number = 0;

  /**
   * Tracks if the user is currently locked out and what time they were locked out on.
   */
  @Type(() => Date)
  lockedOutOn: Date | undefined = undefined;

  /**
   * Returns if this user is currently locked out or not.
   */
  get lockedOut() {
    return this.lockedOutOn != null;
  }

  /**
   * Returns the user's full name
   */
  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  constructor(id: number, username: string, firstName?: string, lastName?: string, dodId?: string) {
    super();
    this.id = id;
    this.username = username;
    this.firstName = firstName;
    this.lastName = lastName;
    this.dodId = dodId;
  }

  /**
   * Checks if the user has the given requested permissions. Throws an error if they do not.
   * @param permissions The list of permissions you wish to validate the current user having
   * @param throwError If we should throw an error instead of returning false if a user is missing a permission. Default is true.
   * @returns True if a user has all permissions, false if they are missing any. If throwError = true, an error will be thrown instead
   *  of returning false.
   */
  hasPermissions(permissions: RolePermissionType[] | undefined, throwError = true) {
    const defaultPermissions = new RolePermission();
    // If no actual permissions, that has to be true
    if (permissions == null) return true;
    // This will need improved upon to actually check their roles but since we have no roles, we just check their admin state.
    for (let _permission of permissions) {
      if (defaultPermissions[_permission] === true) continue; // If we have this by default, continue processing
      if (!this.admin)
        if (throwError) throw new Error("You do not have the required permissions for this action");
        else return false;
    }
    return true;
  }
}

/**
 * Request for logging in with a username and password
 */
export class UserLoginRequestPassword extends TDMSBase {
  username: string;
  password: string;

  constructor(username: string, password: string) {
    super();
    this.username = username;
    this.password = password;
  }
}

/**
 * Request for logging in with a JWT
 */
export class UserLoginRequestJWT extends TDMSBase {
  jwt: string;

  constructor(jwt: string) {
    super();
    this.jwt = jwt;
  }
}

export class UserLookupRequest extends TDMSBase {
  userId: number;

  constructor(userId: number) {
    super();
    this.userId = userId;
  }
}

/**
 * Request for registering a new user account. While we accept an entire user object, some data
 *  you pass will be ignored and overridden on the backend during creation.
 */
export class UserRegisterRequest extends TDMSBase {
  user: User;

  constructor(user: User) {
    super();
    this.user = user;
  }
}

/**
 * Request for changing settings tied to the currently logged in user.
 */
export class UserChangeSettingRequest extends TDMSBase {
  plugin: string;
  setting: CommonSetting;

  constructor(plugin: string, setting: CommonSetting) {
    super();
    this.plugin = plugin;
    this.setting = setting;
  }
}

/**
 * Request for changing password to the currently logged in user.
 */
export class UserChangePasswordRequest extends TDMSBase {
  targetUser: User;
  newPassword: string;
  currentPassword?: string;

  constructor(targetUser: User, newPassword: string, currentPassword?: string) {
    super();
    this.targetUser = targetUser;
    this.newPassword = newPassword;
    this.currentPassword = currentPassword;
  }
}

/**
 * The base class for replies of user functionality
 */
export class UserReplyBase extends TDMSBase {
  success: boolean = false;
  message: string;

  // Non required fields
  user?: User;

  constructor(success: boolean, message: string, user?: User) {
    super();
    this.success = success;
    this.message = message;
    this.user = user;
  }
}

/**
 * This class handles replying to login requests to let them know if it succeeded or not.
 */
export class UserLoginReply extends UserReplyBase {
  /**
   * The JSON Web Token for login responses. This will only be filled in during
   *  login with password requests
   */
  jwt?: string;

  /**
   * If this is a JWT login or not
   */
  isJWTLogin;

  constructor(success: boolean, message: string, user?: User, jwt?: string, isJWTLogin = false) {
    super(success, message, user);
    this.jwt = jwt;
    this.isJWTLogin = isJWTLogin;
  }
}

/**
 * A class to define logout requests to the backend
 */
export class UserLogoutRequest extends TDMSBase {
  /**
   * The type of logout request this was:
   * - `normal`: The normal logout request type
   * - `forceful`: This logout request was forcefully logged out by the backend.
   * - `sessionExpired`: This logout was required because the session expired.
   */
  type: "normal" | "forceful" | "sessionExpired" = "normal";

  constructor(type: "normal" | "forceful" | "sessionExpired" = "normal") {
    super();
    this.type = type;
  }
}

/**
 * This class informs the clients if their logout request was successful
 */
export class UserLogoutReply extends UserReplyBase {
  type: UserLogoutRequest["type"];

  constructor(success: boolean, message: string, type: UserLogoutReply["type"] = "normal", user?: User | undefined) {
    super(success, message, user);
    this.type = type;
  }
}

/**
 * Response to the register command, indicating success and/or error details.
 */
export class UserRegisterReply extends UserReplyBase {}

/**
 * Response to the change password command, indicating success and/or error details.
 */
export class UserChangePasswordReply extends UserReplyBase {}

/**
 * Response to the change setting command, indicating success and/or error details.
 */
export class UserChangeSettingReply extends UserReplyBase {
  plugin: string;
  setting: CommonSetting;

  constructor(success: boolean, message: string, plugin: string, setting: CommonSetting, user?: User) {
    super(success, message, user);
    this.plugin = plugin;
    this.setting = setting;
  }
}

export class UserLookupReply extends TDMSBase {
  id: number;
  username?: string;

  constructor(id: number, username?: string) {
    super();

    this.id = id;
    this.username = username;
  }
}

/** This request is used to allow for unlock/locking of user accounts */
export class UserLockUnlockRequest extends TDMSBase {
  id: number;

  constructor(id: number) {
    super();
    this.id = id;
  }
}

/** Used for updating users via the backend assuming you have the permissions */
export class UserUpdateRequest extends TDMSBase {
  user: User;

  constructor(user: User) {
    super();
    this.user = user;
  }
}

/** Used for responses involving user updates */
export class UserUpdateReply extends UserReplyBase {}
