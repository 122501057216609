import { GenericTableColumn } from "./generic.table.column";

/**
 * Defines a table column that is not backed by and data in the table but instead used for a superfluous component display (i.e. buttons for actions).
 */
export class DatalessColumn extends GenericTableColumn {
  constructor(name: string, title: string, tooltip?: string) {
    super(name, title, tooltip, false, undefined, false);
  }

  override getDataValue(_: any) {
    return undefined;
  }
}
