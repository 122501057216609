import { AfterViewInit, Component, Inject, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import {
  AudioSettings,
  CommunicationTableConfiguration,
  CustomTypes,
  DataStoreUploadStatus,
  Transcription,
  Utility,
} from "@tdms/common";
import {
  BackgroundAudioComponent,
  BG_AUDIO_INJECTION,
} from "@tdms/frontend/modules/audio/components/background-audio/background-audio.component";
import {
  SpeakerIdentityComponent,
  SpeakerIdentityDialogProperties,
} from "@tdms/frontend/modules/audio/components/speaker-identity/speaker-identity.component";
import AudioService from "@tdms/frontend/modules/audio/services/audio.service";
import { selectAllTranscriptionsFromState } from "@tdms/frontend/modules/audio/store/transcription.selector";
import { TranscriptionState } from "@tdms/frontend/modules/audio/store/transcription.state";
import { SessionService } from "@tdms/frontend/modules/session/services/session.service";
import { ConfigService } from "@tdms/frontend/modules/settings/services/config.service";
import { PluginSettingsService } from "@tdms/frontend/modules/settings/services/settings.service";
import { SettingValue } from "@tdms/frontend/modules/settings/store/value/setting.value.model";
import {
  ConfirmationDialogComponent,
  ConfirmationDialogProperties,
  DialogWrapperComponent,
  EditableTableComponent,
  ImprovedSelectComponent,
} from "@tdms/frontend/modules/shared/components";
import { DataColumn } from "@tdms/frontend/modules/shared/components/tables/models";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import autoBind from "auto-bind";
import { addMilliseconds, hoursToSeconds, minutesToSeconds, secondsToMilliseconds } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { cloneDeep } from "lodash-es";
import { firstValueFrom } from "rxjs";

/**
 * The main dashboard display for audio and displays
 */
@Component({
  selector: "communication-dashboard",
  templateUrl: "./audio.dashboard.component.html",
  styleUrls: ["./audio.dashboard.component.scss"],
})
export class AudioDashboardComponent extends SubscribingComponent implements AfterViewInit {
  /** A reference to the transcription table */
  @ViewChild(EditableTableComponent) table: EditableTableComponent<Transcription> | undefined;

  /** If the users has the editing button clicked */
  isEditing = false;

  inputsValid = false;

  /** Tracks whether a change has actually been made */
  hasChanged = false;

  /** Holds our batch adjusted transcriptions */
  adjustedTranscriptions = new Map<number, Transcription>();

  /** Holds our unedited transcriptions so we can tell if they have been changed or not */
  originalAdjustedTranscriptions = new Map<number, Transcription>();

  /** Columns that should be displayed for the table within this component */
  displayedColumns = [
    DataColumn.fromColumnConfig(CommunicationTableConfiguration.Columns.Speaker),
    DataColumn.fromColumnConfig(CommunicationTableConfiguration.Columns.Start, this.formatTime.bind(this)),
    DataColumn.fromColumnConfig(CommunicationTableConfiguration.Columns.End, this.formatTime.bind(this)),
    DataColumn.fromColumnConfig(CommunicationTableConfiguration.Columns.CLC),
    DataColumn.fromColumnConfig(
      CommunicationTableConfiguration.Columns.ASRConfidence,
      this.confidenceToString.bind(this),
      "This column identifies how confident the Automatic Speech Recognition was with the output of the text"
    ),
    DataColumn.fromColumnConfig(
      CommunicationTableConfiguration.Columns.ClassificationConfidence,
      this.confidenceToString.bind(this),
      "This column identifies how confident the analysis model was in it's classification"
    ),
    DataColumn.fromColumnConfig(
      CommunicationTableConfiguration.Columns.Classification,
      (val) => val?.join(", ") ?? "" // Join the multiple potential options together
    ),
    DataColumn.fromColumnConfig(CommunicationTableConfiguration.Columns.Text),
  ];

  /** Defines the setting name and plugin for column visibility persistence within the table of this component */
  readonly columnVisibilitySetting = CommunicationTableConfiguration.SettingConfig;

  /**
   * Current transcriptions to display on the table
   */
  transcriptions: Transcription[] = [];
  validationState: Map<number, Map<string, boolean>> = new Map();

  uniqueSpeakers: string[] = [];

  /** The status related to the data store upload in the event we are awaiting a refresh */
  refreshStatus: DataStoreUploadStatus | undefined;

  /** A list of rows that are currently active according to playback time */
  activeRows: Transcription[] = [];

  /** Controls if we should auto scroll whenever playback is occurring. */
  autoScrollSetting: SettingValue = { value: true } as SettingValue;

  constructor(
    @Inject(BG_AUDIO_INJECTION) public bgAudioPlayer: BackgroundAudioComponent,
    private store: Store<TranscriptionState>,
    public audioService: AudioService,
    public configService: ConfigService,
    public sessionService: SessionService,
    private dialog: MatDialog,
    private settingService: PluginSettingsService
  ) {
    super();
    this.addSubscription(
      this.store.select(selectAllTranscriptionsFromState).subscribe((transcriptions) => {
        this.transcriptions = Transcription.fromPlainArray(transcriptions);
        this.assignValidationData();
        this.setUniqueSpeakers();
      })
    );
    this.addSubscription(this.audioService.refreshStatus.subscribe((x) => (this.refreshStatus = x)));
    autoBind(this);
    this.assignValidationData();
    this.addSubscription(
      this.bgAudioPlayer.currentPlayerTime.subscribe((time) => {
        const activeRows = this.populateActiveRows(time);
        // The transcription to align to
        const transcription = time === 0 ? undefined : activeRows[0];
        this.alignTableToTranscription(transcription);
      })
    );
    this.addSubscription(
      this.settingService
        .observeSetting(AudioSettings.PLUGIN_NAME, AudioSettings.Names.autoScroll)
        .subscribe((x) => (this.autoScrollSetting = x))
    );
  }

  ngAfterViewInit(): void {
    // Whenever the user switches to this page, align the table to the transcription at current player time
    const transcription = this.getClosestTranscription(this.bgAudioPlayer.currentPlayerTime.value);
    this.alignTableToTranscription(transcription);
  }

  setUniqueSpeakers() {
    this.uniqueSpeakers = Utility.getUniqueValues(
      this.transcriptions.map((item) => item.speaker!).filter((x) => x != null)
    );
  }

  /**
   * Setup the initial validation data for every transcription object.
   */
  assignValidationData() {
    this.validationState.clear();

    for (const transcription of this.transcriptions) {
      this.validationState.set(transcription.id, new Map());

      for (const key of Object.keys(transcription)) {
        this.validationState.get(transcription.id)!.set(key, true);
      }
    }
  }

  /** Given the current playback time, populates active rows to know what rows are active in this time frame */
  protected populateActiveRows(time: number) {
    this.activeRows = this.transcriptions.filter((x) => x.start <= time && x.end >= time);
    return this.activeRows;
  }

  /**
   * Asks the audio service to refresh our current transcription
   */
  async refreshTranscriptions() {
    const ref = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        header: "Transcription Refresh",
        description:
          "Are you sure you want to refresh the transcription and diarization in this table? This may take a moment and cannot be undone!",
        confirmButtonText: "Confirm",
        confirmButtonColor: "primary",
        confirmClickCallback: () => this.audioService.refreshAnalysis(),
        cancelClickCallback: () => ref.close(),
      } as Partial<ConfirmationDialogProperties>,
      ...DialogWrapperComponent.getDefaultOptions(),
    });
  }

  /**
   * Controls look for editing vs viewing
   */
  batchEdit() {
    this.isEditing ? (this.isEditing = false) : (this.isEditing = true);
  }

  /**
   * Clears any adjustments made while editing
   */
  cancelChanges() {
    this.hasChanged = false;
    this.adjustedTranscriptions.clear();
    this.isEditing ? (this.isEditing = false) : (this.isEditing = true);
    // Reset unique speakers
    this.setUniqueSpeakers();
  }

  /** Opens a dialog to create a new speaker identity */
  openNewSpeakerIdentity(element: Transcription, select: ImprovedSelectComponent<any, any>) {
    this.dialog.open(SpeakerIdentityComponent, {
      data: {
        callback: (speaker) => {
          if (!this.uniqueSpeakers.includes(speaker)) this.uniqueSpeakers = [...this.uniqueSpeakers, speaker];
          this.transcriptionUpdate(element, "speaker", { target: { value: speaker } });
          select.control?.setValue(speaker);
          // element.speaker = speaker;
        },
      } as Partial<SpeakerIdentityDialogProperties>,
      ...DialogWrapperComponent.getDefaultOptions(),
    });
  }

  /** Handles performing updates based on the given transcription to the field with the given content */
  transcriptionUpdate<FieldType extends any>(
    element: Transcription,
    field: CustomTypes.PropertyNames<Transcription, FieldType>,
    val: { target: { value: any } }
  ) {
    if (this.isEditing) {
      if (!this.adjustedTranscriptions.has(element.id)) {
        const tempTranscription = element.clone();
        tempTranscription[field] = val.target.value; // Update val
        this.originalAdjustedTranscriptions.set(tempTranscription.id, element);
        this.adjustedTranscriptions.set(tempTranscription.id, tempTranscription!);
        this.hasChanged = this.changeDetector();
      } else {
        this.adjustedTranscriptions.get(element.id)![field] = val.target.value;
        this.hasChanged = this.changeDetector();
      }
      this.validateInputs();
    }
  }

  transcriptionTimeUpdate<FieldType extends any>(
    element: Transcription,
    field: CustomTypes.PropertyNames<Transcription, FieldType>,
    val: any
  ) {
    let input = val.target.value;
    const valid = Transcription.TIME_PATTERN.test(input);

    this.validationState.get(element.id)!.set(field, valid);

    if (valid) {
      const validated = element.clone();
      const pieces = input.split(":");
      input = hoursToSeconds(Number(pieces[0])) + minutesToSeconds(Number(pieces[1])) + Number(pieces[2]);
      const index = this.transcriptions.findIndex((transcription) => element.id == transcription.id);
      this.transcriptions[index] = validated;
    }

    this.validateInputs();

    return this.transcriptionUpdate(element, field, { target: { value: input } });
  }

  /**
   * Saves changes to transcription state and writes to DB
   */
  async saveChanges() {
    if (this.hasChanged) {
      var arr: Transcription[] = [];
      this.adjustedTranscriptions.forEach((adjustedTranscription, id) => {
        const index = this.transcriptions.findIndex((t) => t.id === id);
        if (index !== -1) {
          this.transcriptions[index].speaker = adjustedTranscription.speaker;
          this.transcriptions[index].text = adjustedTranscription.text;
        }
        arr.push(adjustedTranscription);
      });
      // Reset display to be inline with store
      this.transcriptions = Transcription.fromPlainArray(
        await firstValueFrom(this.store.select(selectAllTranscriptionsFromState))
      ) as Transcription[];
      this.isEditing = false;
      // Send only the updated transcription
      this.audioService.manuallyChangeTranscriptions(arr);
      this.adjustedTranscriptions.clear();
      this.hasChanged = false;
    }
  }

  /** Used to detect if our editing transcriptions have changed in comparison to the original values */
  changeDetector() {
    let hasChanged = false;
    this.adjustedTranscriptions.forEach((value, key) => {
      if (!value.equals(this.originalAdjustedTranscriptions.get(key)!)) {
        hasChanged = true;
      }
    });
    return hasChanged;
  }

  inputValid(element: Transcription, field: any): boolean {
    return this.validationState.get(element.id)!.get(field)!;
  }

  validateInputs() {
    for (const transcription of this.transcriptions) {
      const id = transcription.id;
      const validationData = this.validationState.get(id)!;
      const startValid = validationData.get("start");
      const endValid = validationData.get("end");

      if (!startValid || !endValid) {
        this.inputsValid = false;
        return;
      }
    }

    this.inputsValid = true;
  }

  /**
   * Seeks to a specific start time in the audio file
   * @param data The data row to seek to
   */
  seekTo(data: { start?: number }) {
    this.bgAudioPlayer.seek(data.start!);
  }

  /** Updates the speaker of the given params  */
  speakerUpdate(element: Transcription, val: string | undefined) {
    if (val) this.transcriptionUpdate(element, "speaker", { target: { value: val } });
  }

  /** Given N number of seconds from start of file, formats it into a more human readable display. {@link Transcription.CUSTOM_TIME_FORMAT} */
  private formatTime(value: number) {
    return formatInTimeZone(
      addMilliseconds(new Date(0), secondsToMilliseconds(value)),
      "UTC",
      Transcription.CUSTOM_TIME_FORMAT
    );
  }

  /** Given a confidence value, converts it to a whole percentage and returns a string representation */
  confidenceToString(value: number) {
    return `${Math.round(value * 100)}%`; // Convert confidence to a whole percentage
  }

  /** Given a time value, finds the closest transcription to that time */
  private getClosestTranscription(time: number, data = this.table?.data) {
    if (data == null || data.length === 0) return undefined;
    return data.reduce(function (prev, curr) {
      const prevStartDiff = Math.abs(prev.start - time);
      const prevEndDiff = Math.abs(prev.end - time);
      const currStartDiff = Math.abs(curr.start - time);
      const currEndDiff = Math.abs(curr.end - time);
      const prevDiff = Math.min(prevStartDiff, prevEndDiff);
      const currDiff = Math.min(currStartDiff, currEndDiff);
      return currDiff < prevDiff ? curr : prev;
    });
  }

  /** Given a transcription, aligns the table to show that transcription to the user */
  private alignTableToTranscription(data?: Transcription) {
    if (this.table?.genericTable && data && this.autoScrollSetting.value) {
      // Switch to the correct page
      const pageIndex = this.table.genericTable.getPageIndex(data);
      this.table.genericTable.goToPage(pageIndex);
      // Scroll to it if available
      const rowId = this.table.genericTable.getTableRowId(data);
      const matchingElement = document.getElementById(rowId);
      matchingElement?.scrollIntoView({ block: "center" });
    }
  }

  /** Updates the auto scroll setting value based on the given boolean */
  updateAutoScrollSetting(value: boolean) {
    if (value !== this.autoScrollSetting.value) {
      const setting = cloneDeep(this.autoScrollSetting);
      setting.value = value;
      this.settingService.updateSpecificSetting(setting, AudioSettings.PLUGIN_NAME);
    }
  }
}
