<div class="audio-track-container">
  <!-- Error display in the event we can't load the audio track -->
  <div class="error-display" *ngIf="audioService.loadFailureMessage">
    <span class="text">{{ audioService.loadFailureMessage }}</span>
    <div class="background"></div>
    <mat-icon
      *ngIf="audioService.extendedFailureMessage"
      class="icon"
      [matTooltip]="audioService.extendedFailureMessage"
      >info</mat-icon
    >
  </div>

  <!-- Audio player, loading from the background player -->
  <shared-audio-player [audioControllerExternal]="player" displayType="expanded"></shared-audio-player>

  <!-- Error display if no waveform is available. Could be due to disabled or missing metric. -->
  <div class="error-display waveform-disabled" *ngIf="!metricGrid">
    <span class="text">Audio Waveform Metric Not Available</span>
    <div class="background">
      <mat-icon class="icon" matTooltip="Metric is disabled or missing">info</mat-icon>
    </div>
  </div>
  <!-- Waveform metrics -->
  <metric-grid class="audio-waveform" *ngIf="metricGrid" [dataStore]="metricGrid"></metric-grid>
</div>
