import { createActionGroup, props } from "@ngrx/store";
import { Notification } from "@tdms/common";

/**
 * Supported NGRX store **notification** actions
 */
export const NotificationActions = createActionGroup({
  source: "notification",
  events: {
    add: props<{ data: Notification[] }>(),
    updateMany: props<{ data: Notification[] }>(),
    update: props<{ data: Notification }>(),
    empty: props<any>(),
    delete: props<{ id: number }>(),
  },
});
