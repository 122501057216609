import { CustomTypes, TableColumnConfiguration, TableColumnSupportedColumnTypes } from "@tdms/common";
import { GenericTableColumn } from "./generic.table.column";

/** This class defines a data column that should be displayed within the generic table */
export class DataColumn<
  RowDataType extends Object,
  ColumnType extends TableColumnSupportedColumnTypes
> extends GenericTableColumn {
  /**
   * This is the field on each row that contains the value associated with this column.
   * Since parent components can provide a custom rendering template,
   * we no longer type check this as string | bool | Date | number and let it be anything.
   */
  displayField: CustomTypes.PropertyNames<RowDataType, ColumnType>;

  /**
   * An optional formatter for the cell view that will be applied to the data before being displayed.
   */
  cellViewFormatter?: (value: ColumnType, orgObject: RowDataType) => string;

  constructor(
    displayValue: DataColumn<RowDataType, ColumnType>["displayField"],
    title: string,
    cellViewFormatter?: DataColumn<RowDataType, ColumnType>["cellViewFormatter"],
    name?: string,
    onlyShowInExport = false,
    tooltip?: string,
    canEditColumnVisibility?: boolean
  ) {
    super(name ?? displayValue, title, tooltip, true, onlyShowInExport, canEditColumnVisibility);
    this.displayField = displayValue;
    this.cellViewFormatter = cellViewFormatter;
    this.onlyShowInExport = onlyShowInExport;
  }

  /**
   * Using the configuration of a {@link TableColumnConfiguration}, creates an instance of the `DataColumn` with the given information.
   * @param config The configuration to define column name and display text
   * @param cellViewFormatter A potential callback to adjust the displayed text
   * @param tooltip A tooltip to display with this column at the top of the table
   * @param canEditColumnVisibility If the given column should be able to be toggled on and off for display
   */
  static fromColumnConfig<RowDataType extends Object, ColumnType extends TableColumnSupportedColumnTypes>(
    config: TableColumnConfiguration<RowDataType, ColumnType>,
    cellViewFormatter?: DataColumn<RowDataType, ColumnType>["cellViewFormatter"],
    tooltip?: string,
    canEditColumnVisibility = true
  ) {
    return new DataColumn(
      config.propertyValue as DataColumn<RowDataType, ColumnType>["displayField"],
      config.displayName,
      cellViewFormatter,
      undefined,
      undefined,
      tooltip,
      canEditColumnVisibility
    );
  }

  /**
   * Given a row in the table, fetch the data associated to this column, format it and return the value.
   * @cellViewFormatter will be applied with the raw data from the row before returning.
   * @param rowData The row in the table to fetch cell value from.
   * @returns The fetched and formatted cell value.
   */
  override getDataValue(rowData: RowDataType) {
    let data = rowData[this.displayField] as any;
    if (this.cellViewFormatter != null) data = this.cellViewFormatter(data, rowData);
    // Don't display undefined values
    if (data == null) data = "";
    return data;
  }
}
