import { animate, state, style, transition, trigger } from "@angular/animations";
import { AfterViewInit, ChangeDetectorRef, Component, Input } from "@angular/core";
import { Notification } from "@tdms/common";
import { ColorThemeService } from "@tdms/frontend/modules/material/services/themes.service";
import { NotificationService } from "@tdms/frontend/modules/notification/services/notification.service";

/** This toast component is what renders the box with content in it to alert the user of info as it occurs within the app. */
@Component({
  selector: "notification-toast[toast]",
  templateUrl: "./toast.component.html",
  styleUrls: ["./toast.component.scss"],
  animations: [
    trigger("heightAnimation", [
      state("in", style({ height: "*", opacity: 1 })),
      state("out", style({ height: 0, opacity: 0 })),
      transition("in => out", animate("300ms ease-in-out")), // Collapse
      transition("out => in", animate("300ms ease-in-out")), // Expand
    ]),
  ],
})
export class ToastComponent implements AfterViewInit {
  /** The toast we wish to render */
  @Input() toast!: Notification;
  /** Current animation state for this */
  animationState: "in" | "out" = "out";
  /** If we should be able to dismiss the toast */
  @Input() canDismiss = true;
  /** If animations should be used to apply pretty rendering */
  @Input() shouldAnimate = true;
  /** If our last interaction was a click to dismiss this call */
  @Input() wasClickedDismissed = false;
  /** This defines the mode of display so we know if we should compress the text if it gets too long. */
  @Input() mode: "compress" | undefined = undefined;

  constructor(
    public notificationService: NotificationService,
    private cdr: ChangeDetectorRef,
    public themeService: ColorThemeService
  ) {}

  /** Returns CSS class for toast-container. Needs separated into the component so we can get more advanced than the template. */
  get classProperty() {
    return `${this.toast.type} ${this.themeService.applicationLevelTheme.value === "light" ? "light" : ""}`;
  }

  ngAfterViewInit(): void {
    if (this.shouldAnimate) {
      this.animationState = "in";
      this.cdr.detectChanges();
    }
  }

  /** Whenever the animation completes from the wrapper, this will be called */
  animateDone() {
    if (this.shouldAnimate && this.animationState === "out" && this.wasClickedDismissed)
      this.notificationService.acknowledge(this.toast, "user");
  }

  /** Acknowledges the toast so it only shows in the notification panel. Handles animating or not based on {@link shouldAnimate} */
  acknowledge(wasClicked = true) {
    this.wasClickedDismissed = wasClicked;
    if (this.shouldAnimate) this.animationState = "out";
    else if (this.wasClickedDismissed) this.notificationService.acknowledge(this.toast, "user");
  }
}
