import { EventEmitter, Injectable } from "@angular/core";
import {
  AvailableMetricNamesType,
  AvailablePluginNamesType,
  ConfigMessage,
  CoreTopics,
  ServerConnectionInit,
  TDMSWebSocketMessage,
  Utility,
  WebSocketCommunication,
} from "@tdms/common";
import { firstValueFrom } from "rxjs";
import { WebSocketService } from "../../communication/services/websocket.service";
import { Service } from "../../shared/services/base.service";

/**
 * This service provides some basic configuration data associated to the current connection
 *  and or user information.
 */
@Injectable({
  providedIn: "root",
})
export class ConfigService extends Service {
  /** The current backend version from when we connected with the server status message */
  backendVersion: string = "UNKNOWN";

  /** Current version of the frontend */
  get frontendVersion() {
    return this.webpackData.version;
  }

  /** Data that is corresponded from the webpack define plugin so this is all set at build time. */
  webpackData = {
    version: $VERSIONING.VERSION,
    commitHash: $VERSIONING.COMMITHASH,
    branch: $VERSIONING.BRANCH,
    lastCommitDate: $VERSIONING.LASTCOMMITDATETIME,
    buildDate: Utility.getDateAsZulu(new Date($BUILD_DATE)),
  };

  /**
   * Configuration data to control different features according to how the backend
   *    things they should work.
   */
  configData: ConfigMessage | undefined;

  /**
   * A subscription to watch when config data arrives
   */
  configDataWatcher = new EventEmitter();

  constructor(private wsService: WebSocketService) {
    super();
  }

  override async onBackendConnected(): Promise<void> {
    const response: TDMSWebSocketMessage<ConfigMessage> = await this.wsService.sendAndReceive(
      new TDMSWebSocketMessage(CoreTopics.getConfig)
    );
    this.configData = ConfigMessage.fromPlain(response.payload);
    this.configDataWatcher.next(this.configData);
  }

  async waitForConfigData(): Promise<ConfigMessage> {
    if (this.configData != null) return this.configData;
    return firstValueFrom(this.configDataWatcher);
  }

  /** Listen for initial connection status messages as we receive them */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<ServerConnectionInit>>(CoreTopics.initialConnectionStatus)
  protected async initConnectStatusReceived(data: TDMSWebSocketMessage<ServerConnectionInit>) {
    this.backendVersion = data.payload.backendVersion;
  }

  /** Returns if the given plugin name is enabled or not. */
  pluginIsEnabled(name: AvailableMetricNamesType | AvailablePluginNamesType) {
    return this.configData?.pluginStatus.get(name)?.enabled ?? false;
  }
}
