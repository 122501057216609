import { Component, Input, OnChanges } from "@angular/core";
import { DataStoreUploadStatus } from "@tdms/common";
import { TrackedFile } from "@tdms/frontend/modules/data-store/components/uploader/file-tree/models/tracked.file";
import { UploadService } from "@tdms/frontend/modules/data-store/services/upload.service";
import { NotificationService } from "@tdms/frontend/modules/notification/services/notification.service";
import { AngularCustomTypes } from "@tdms/frontend/modules/shared/models/angular.custom.types";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import { UserService } from "@tdms/frontend/modules/user/services/user.service";
import { formatDistance } from "date-fns";
import { startCase } from "lodash-es";

/**
 * This component is intended to be abstracted to display the progress of uploads as they occur within the frontend. It also supports
 *  displaying a more involved progress indicator for how the upload is going.
 *
 * This component is intended to be extended into the component that intends to use the uploading progress like:
 *
 * ```ts
 * export class SessionCreationComponent extends UploaderProgressComponent
 * ```
 *
 * Once it is extended into your component, you can then call
 *
 * ```ts
 * await submitUploadRequest(...)
 * ```
 *
 * With your expected upload requests.
 *
 * Finally, you should wrap your components template with an instance of this template like:
 *
 * ```html
 * <data-store-uploader-progress [status]="status" [shouldDisplayStatus]="shouldDisplayStatus" [filesUploading]="filesUploading">
 *  ...
 * </data-store-uploader-progress>
 *
 * Which will give it the ability to display the progress indicators as they occur.
 * ```
 */
@Component({
  selector: "data-store-uploader-progress[shouldDisplayStatus][status]",
  templateUrl: "./uploader-progress.component.html",
  styleUrls: ["./uploader-progress.component.scss"],
})
export class UploaderProgressComponent extends SubscribingComponent implements OnChanges {
  /** Tracks if this element should display the status message */
  @Input() shouldDisplayStatus = false;

  /** This status object can provide a much more advanced display of the uploader progress. */
  @Input() status: DataStoreUploadStatus | undefined;

  /** The array of files currently being uploaded */
  @Input() filesUploading?: TrackedFile[] = undefined;

  /** The total number of steps for this upload progress based on status. */
  totalSteps = 0;

  /** Tracks if we should render an advanced display with progress steps or the basic spinner. If true, render advanced display. */
  shouldRenderAdvanced = false;

  /** An indication of how much time is remaining in a pretty string format for the entire process. */
  totalTimeRemaining: string | undefined;

  /** An indication of how much time is remaining in a pretty string format for this current stage. */
  remainingStageTime: string | undefined;

  /** Tracks the current spinner progress. Undefined can be used to make the spinner indeterminate. */
  spinnerProgress: number | undefined;

  /** The text to display within the spinner based on current stage/step and progress */
  spinnerText: string | undefined;

  /** This text display the processing text in the header to identify the source of our processing and progress callback information. */
  processingText: string | undefined;

  /** This dictionary tracks **steps** and their completed status */
  stepStatus = new Map<string, boolean>();

  /** This dictionary tracks **stages** and their completed status */
  stageStatus = new Map<string, boolean>();

  constructor(
    public userService: UserService,
    public uploadService: UploadService,
    public notificationService: NotificationService
  ) {
    super();
  }

  ngOnChanges(changes: AngularCustomTypes.BaseChangeTracker<UploaderProgressComponent>): void {
    const status = changes.status?.currentValue;
    // Populate total steps
    this.totalSteps = status?.totalSteps ?? 0;
    // Determine if we should render advanced steps
    this.shouldRenderAdvanced = status != null && status.expectedStages.length > 0 && this.totalSteps > 0;
    // Determine remaining times
    this.totalTimeRemaining = this.getTimeByProp(status, "estimatedTotalTimeRemaining");
    this.remainingStageTime = this.getTimeByProp(status, "estimatedTimeRemaining");
    // Set spinner progress. Basically 0, null, or -1 should be indeterminate. Every other value should display progress.
    this.spinnerProgress =
      status == null || status.progress === 0 || status.progress === -1 ? undefined : status.progress;
    // Set spinner text
    this.spinnerText = this.getSpinnerText(status);
    // Set header processing text
    this.processingText = this.getProcessingText(status);
    // Fill out completed information based on stages and steps
    this.stepStatus = new Map([]);
    this.stageStatus = new Map([]);
    if (status) {
      // Steps
      status.orderedExpectedSteps.forEach((data, stageIndex) => {
        const stage = data[0];
        const steps = data[1];
        steps.forEach((step, stepIndex) => {
          this.stepStatus.set(`${stage}-${step}`, status.stepIsComplete(stageIndex, stepIndex));
        });
      });
      // Stages
      const stages = status.expectedStages;
      stages.forEach((stage, i) => this.stageStatus.set(stage, status.stageIsCompleted(i)));
    }
  }

  /** Returns the text to display for the spinners current progress */
  private getSpinnerText(status: DataStoreUploadStatus | undefined) {
    if (!status || !this.shouldRenderAdvanced) return "Processing...";
    else if (status && !this.shouldRenderAdvanced) return status.step;
    else if (status.progress === -1 || status.progress == null) return `${startCase(status.step)}`;
    else if (status.progress === 100)
      if (status.step.toLowerCase() === "complete") return `${startCase(status.step)}`;
      else return `${startCase(status.step)}: Complete!`;
    else return `${startCase(status.step)}: ${Math.round(status.progress)}%`;
  }

  /** Returns the time of the status based on the given property. Returns it in a pretty string that is more human friendly. */
  private getTimeByProp(
    status: DataStoreUploadStatus | undefined,
    key: "estimatedTimeRemaining" | "estimatedTotalTimeRemaining"
  ) {
    if (!status || !status[key]) return "";
    const time = Math.ceil(status[key] as number);
    if (time === 0) return "";
    return formatDistance(0, time * 1000, { includeSeconds: true });
  }

  /** Returns the processing text of of what we should show within the header. */
  private getProcessingText(status: DataStoreUploadStatus | undefined) {
    let returnVal = "";
    if (!this.filesUploading) returnVal = "Processing";
    else if (this.filesUploading.length > 1) returnVal = `Processing ${this.filesUploading.length} files`;
    else returnVal = `Processing ${this.filesUploading[0].fileDisplayName}`;
    // Append source if needed
    if (status?.source) returnVal += ` on ${status.source}`;
    return returnVal;
  }

  /**
   * This function is intended to submit the upload request with the files to the server. This will handle updating the status displays as necessary.
   *
   * @param files The files to perform the upload of
   * @param sessionId The session ID to associate these uploads to
   * @param sessionName The session name prefix for the upload request
   */
  async submitUploadRequest(...params: Parameters<UploadService["uploadToDataStore"]>) {
    // Make sure the JWT is valid and they haven't had their authentication session timed out
    if (!this.userService.checkUserJwtValidation()) {
      this.userService.logout();
      return;
    }
    try {
      this.shouldDisplayStatus = true;
      this.filesUploading = Array.isArray(params[0]) ? params[0] : [params[0]];
      // Perform the upload request and get the listener subject
      const uploadResult = this.uploadService.uploadToDataStore(...params);
      // Using the subject from the upload, listen for updates to update this display
      this.addSubscription(uploadResult?.totalProgress.subscribe((status) => (this.status = status)));
      // Await the completed upload resolving from the server and return the result
      const result = await uploadResult?.promise;
      this.shouldDisplayStatus = false;
      this.status = undefined;
      this.filesUploading = undefined;
      return result;
    } catch (e) {
      this.shouldDisplayStatus = false;
      this.status = undefined;
      // Rethrow error so children can break out of request
      throw e;
    }
  }
}
