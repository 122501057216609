import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import {
  DataStoreTopics,
  DataStoreUploadStatus,
  Session,
  TDMSWebSocketMessage,
  UploadOption,
  UploadRequest,
  UploadResponse,
  Utility,
  WebSocketCommunication,
} from "@tdms/common";
import { WebSocketService } from "@tdms/frontend/modules/communication/services/websocket.service";
import { TrackedFile } from "@tdms/frontend/modules/data-store/components/uploader/file-tree/models/tracked.file";

import { DataStoreState } from "@tdms/frontend/modules/data-store/models/data.store.state";
import { ConfigService } from "@tdms/frontend/modules/settings/services/config.service";
import { Service } from "@tdms/frontend/modules/shared/services/base.service";
import { UserService } from "@tdms/frontend/modules/user/services/user.service";
import { groupBy } from "lodash";
import { startCase } from "lodash-es";
import { BehaviorSubject, combineLatest, map } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { DataStoreActions } from "../models/store/data.store.action";
import DataStoreService from "./data.store.service";

/** A type to help isolate the overall progress of a total upload as it occurs */
type FileUploadProgressType<FileType extends TrackedFile> = {
  /** The specific uuid of this request that was given to the backend */
  id: string;
  files: FileType[];
  /** The current status of the upload */
  status: BehaviorSubject<DataStoreUploadStatus>;
};

/**
 * A service to provide data store uploading capabilities
 */
@Injectable({
  providedIn: "root",
})
export class UploadService extends Service {
  /**
   * Keeps track of if we are currently uploading anything
   */
  currentlyUploadingRequests: FileUploadProgressType<any>[] = [];

  constructor(
    private store: Store<DataStoreState>,
    private wsService: WebSocketService,
    private userService: UserService,
    private configService: ConfigService,
    private dataStoreService: DataStoreService,
    private matDialog: MatDialog
  ) {
    super();
  }

  override async onBackendDisconnected() {
    this.currentlyUploadingRequests = [];
  }

  override async onUserChanged(): Promise<void> {
    if (this.configService.pluginIsEnabled("DataStore")) {
      const response = await this.wsService.sendAndReceive<UploadOption[]>(
        new TDMSWebSocketMessage(DataStoreTopics.getTypes)
      );
      this.store.dispatch(
        DataStoreActions.addUploadOptions({ options: UploadOption.fromPlainArray(response.payload) })
      );
    }
  }

  /**
   * Given some files, uploads them to the data store endpoints
   * @param files The files to process one by one to the data store http endpoint
   * @param sessionId The session Id to upload to the data store as. Not passing a session Id will result in an attempt to auto create a session from the **each** file being uploaded. if
   *  the type states it supports it.
   * @param sessionName The session name to use as the base in new sessions. This will only be utilized if the given sessionId is blank.
   */
  uploadToDataStore<FileType extends TrackedFile>(
    files: FileType | FileType[],
    sessionId?: number,
    sessionName?: string,
    specialUploadType?: UploadRequest["specialUploadType"]
  ) {
    // Fake session object to later use
    const fakeSession = Session.fromPlain({ id: sessionId, name: sessionName });
    //Handle user authentication. Check user's jwt, log them out if jwt is expired.
    if (!this.userService.checkUserJwtValidation()) {
      this.userService.logout();
      this.matDialog.closeAll();
      return undefined;
    }
    // Verify even singular files are an array
    files = !Array.isArray(files) ? [files] : files;
    // Condense files so types that support multiple files are uploaded as one request
    const filesThatCanGroup = Object.values(
      groupBy(
        files.filter((z) => z.fileType.canContainMoreThanOne),
        (x) => x.fileType.name
      )
    );
    const filesThatCantGroup = files.filter((x) => !x.fileType.canContainMoreThanOne);
    const totalFiles = filesThatCanGroup.concat(filesThatCantGroup) as Array<FileType> | Array<Array<FileType>>;
    // Create multiple promises for all resolved files
    const uploadProcess = totalFiles.map((fileData) =>
      this.buildUploadPromise(Array.isArray(fileData) ? fileData : [fileData], fakeSession, specialUploadType)
    );
    const promises = uploadProcess.flatMap((x) => x.uploadPromise);
    const progress = uploadProcess.flatMap((x) => x.status);
    const promise = Promise.all(promises);
    // Make a subscribable of total progress across all files
    const totalProgress = combineLatest(progress).pipe(
      map((progressValues) => {
        const status = progressValues[0];
        if (status.progress === -1) status.progress = undefined as any;
        return status;
      })
    );
    return {
      /** This promise contains the actual async function that you can await for when the upload is done. */
      promise,
      /** This observable takes all the files uploaded into a single progress and returns the one that has updated most recently. */
      totalProgress,
    };
  }

  /** Builds an upload promise with XMLHttp requests and returns it */
  private buildUploadPromise<FileType extends TrackedFile>(
    files: FileType[],
    session: Session,
    specialUploadType?: UploadRequest["specialUploadType"]
  ) {
    const fileType = files[0].fileType;
    const isDelayedSessionCreation = files[0].isDelayedSessionCreation;
    // Create handlers for status updates
    const uploadRequestId = uuidv4();
    const currentUploadStatus = DataStoreUploadStatus.fromPlain({
      uploadRequestId,
      progress: -1,
      expectedStages: ["Upload"],
      expectedSteps: { ["Upload"]: ["Submitting To Server", "Processing"] },
      stage: "Upload",
      step: "Submitting To Server",
    });
    const status = new BehaviorSubject(currentUploadStatus);
    const progress = { id: uploadRequestId, files, status } as FileUploadProgressType<FileType>;
    const uploadPromise = new Promise<UploadResponse>((resolve, reject) => {
      this.currentlyUploadingRequests.push(progress);
      // Function to remove the uploading status from the request array
      const removeUploadProgress = () => {
        this.currentlyUploadingRequests.splice(
          this.currentlyUploadingRequests.findIndex((x) => x.id === uploadRequestId),
          1
        );
        status.complete();
      };
      const xhttp = new XMLHttpRequest();
      xhttp.open("POST", this.dataStoreService.getDataStoreEndpoint("upload"));
      xhttp.setRequestHeader("Authorization", `Bearer ${this.userService.getJWT()}`);
      xhttp.setRequestHeader("client-identifier", this.wsService.clientIdentifier);
      const formData = new FormData();
      // Append all files
      for (let file of files) formData.append("files", file.file);
      // Append request information
      formData.append(
        "request",
        // You technically only need the upload option name as it's unique
        UploadRequest.fromPlain({
          type: fileType,
          sessionId: session.id,
          sessionName: session.name,
          isDelayedSessionCreation: isDelayedSessionCreation,
          specialUploadType,
          requestId: uploadRequestId,
        }).toJSONString()
      );
      // Callback to fire as progress of this upload occurs
      const updateProgress = (ev: ProgressEvent) => {
        currentUploadStatus.time = Date.now();
        currentUploadStatus.estimatedTimeRemaining = undefined;
        currentUploadStatus.progress = (ev.loaded / ev.total) * 100;
        if (currentUploadStatus.progress === 100) {
          currentUploadStatus.step = "Processing";
          currentUploadStatus.progress = -1;
        }
        currentUploadStatus.setEstimatedTime(status.value);
        // Clone the update to force a reference change in listeners
        status.next(currentUploadStatus.clone());
      };
      xhttp.upload.onloadstart = updateProgress;
      xhttp.upload.onprogress = updateProgress;
      xhttp.onerror = (e) => {
        removeUploadProgress();
        reject(e);
      };
      // Track ready state changes
      xhttp.onreadystatechange = () => {
        if (xhttp.readyState === 4) {
          removeUploadProgress();
          if (!xhttp.response) reject("Invalid response message");
          else {
            const result = UploadResponse.fromPlainString(xhttp.response);
            if (!result.success) reject(result);
            else resolve(result);
          }
        }
      };
      // Send the data
      xhttp.send(formData);
    });
    return { uploadPromise, status };
  }

  /**
   * Given a file, turns it into a "pretty" session name
   */
  getSessionNameFromFile(tracked: TrackedFile | undefined) {
    if (!tracked) return "";
    const fileName = tracked.file.name;
    return startCase(fileName.replace(Utility.getFileExtensionFromString(fileName) || "", ""));
  }

  /** Returns the current background progress of any uploading statuses. Useful for displaying an overarching progress indicator when the dialogs are not open. */
  getBackgroundProgress() {
    const overallProgress =
      this.currentlyUploadingRequests.reduce((curr, prev) => (curr += prev.status.value.progress), 0) /
      this.currentlyUploadingRequests.length;
    if (overallProgress === 0 || isNaN(overallProgress)) return undefined;
    else return overallProgress;
  }

  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<DataStoreUploadStatus>>(DataStoreTopics.uploadStatus)
  async analyzeStatusReceived(data: TDMSWebSocketMessage<DataStoreUploadStatus>) {
    const processedData = DataStoreUploadStatus.fromPlain(data.payload);
    // Find a matching upload status and append the last update to it
    const matchingUploadRequest = this.currentlyUploadingRequests.find((x) => x.id === processedData.uploadRequestId);

    if (matchingUploadRequest) {
      processedData.mergeStagesAndSteps(matchingUploadRequest.status.value);
      processedData.setEstimatedTime(matchingUploadRequest.status.value);
      matchingUploadRequest.status.next(processedData);
    }
  }
}
