import { TableColumnConfiguration, TableConfiguration } from "../../../../settings/table/settings";
import { Transcription } from "../../transcription";
import { AudioSettings } from "../settings";

/** This namespace contains settings related to rendering the communication table within the audio plugin */
export class CommunicationTableConfiguration extends TableConfiguration {
  static override readonly SettingConfig = {
    pluginName: AudioSettings.PLUGIN_NAME,
    settingName: AudioSettings.Names.visibleColumns,
  };

  static override readonly Columns = {
    Speaker: new TableColumnConfiguration<Transcription, string>("prettySpeaker", "Speaker"),
    Start: new TableColumnConfiguration<Transcription, number>("start"),
    End: new TableColumnConfiguration<Transcription, number>("end", undefined, false),
    CLC: new TableColumnConfiguration<Transcription, boolean>("isCLC"),
    ASRConfidence: new TableColumnConfiguration<Transcription, number>("confidence", "ASR Confidence", false),
    ClassificationConfidence: new TableColumnConfiguration<Transcription, number>(
      "classificationConfidence",
      "Classification Confidence",
      false
    ),
    Classification: new TableColumnConfiguration<Transcription, Array<string>>("classification"),
    Text: new TableColumnConfiguration<Transcription, string>("text"),
  };
}
