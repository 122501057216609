import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Session, SessionTopics, WebSocketCommunication } from "@tdms/common";
import AudioService from "@tdms/frontend/modules/audio/services/audio.service";
import { BookmarkTypeService } from "@tdms/frontend/modules/bookmark/services/bookmark.type.service";
import DataStoreMetricService from "@tdms/frontend/modules/data-store/services/data.store.metrics.service";
import DataStoreService from "@tdms/frontend/modules/data-store/services/data.store.service";
import { FileEditService } from "@tdms/frontend/modules/data-store/services/edit.service";
import RecycleBinService from "@tdms/frontend/modules/data-store/services/recycle-bin.service";
import { SearchService } from "@tdms/frontend/modules/data-store/services/search.service";
import { UploadService } from "@tdms/frontend/modules/data-store/services/upload.service";
import { MetricGridService } from "@tdms/frontend/modules/metrics/services/metric.grid.service";
import { MetricService } from "@tdms/frontend/modules/metrics/services/metric.service";
import { NotificationService } from "@tdms/frontend/modules/notification/services/notification.service";
import SessionSummaryService from "@tdms/frontend/modules/session-summary/services/session.summary.service";
import { SessionComparisonService } from "@tdms/frontend/modules/session/services/session.comparison.service";
import { SessionService } from "@tdms/frontend/modules/session/services/session.service";
import { selectCurrentSession } from "@tdms/frontend/modules/session/store/session.selector";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import { TagService } from "@tdms/frontend/modules/tag/services/tag.service";
import { UserService } from "@tdms/frontend/modules/user/services/user.service";
import { BehaviorSubject, filter, firstValueFrom, Subject } from "rxjs";
import { BookmarkService } from "../../bookmark/services/bookmark.service";
import { WebSocketService } from "../../communication/services/websocket.service";
import { ColorThemeService } from "../../material/services/themes.service";
import { RouterService } from "../../routing/services/router.service";
import { ConfigService } from "../../settings/services/config.service";
import { PluginSettingsService } from "../../settings/services/settings.service";
import { Service } from "./base.service";

/**
 * Manage all services available for frontend consumption, coordinating initialization of service data and backend requests.
 */
@Injectable({ providedIn: "root" })
export class ServiceManager extends SubscribingComponent {
  /**
   * Array the stores the order in which all of the frontend services should be initialized.
   * This also controls the order that service events such as onUserChanged, onSessionChanged
   * will be propagated to the available services.
   */
  initializationOrder: Service[];

  /**
   * This subject will emit when all services have finished initialization.
   * Behavior subjects will emit the latest value immediately whenever a new subscriber is added, which is needed for the suspension implementation used in the service manager.
   */
  onServicesLoaded = new BehaviorSubject(false);

  /**
   * This subject will emit when all services have finished initializing after successful connection to backend is established.
   * Behavior subjects will emit the latest value immediately whenever a new subscriber is added, which is needed for the suspension implementation used in the service manager.
   */
  onServicesLoadedBackend = new BehaviorSubject(false);

  /**
   * This subject will emit when all services have finished handling a connection loss to the backend.
   * Behavior subjects will emit the latest value immediately whenever a new subscriber is added, which is needed for the suspension implementation used in the service manager.
   */
  onServicesHandledBackendDisconnect = new BehaviorSubject(false);

  /**
   * This subject will emit when all services have finished updating due to a user status change (login/logout).
   * This is useful for frontend components that want to wait for all service data tied to a user to be ready when that session changes.
   * Behavior subjects will emit the latest value immediately whenever a new subscriber is added, which is needed for the suspension implementation used in the service manager.
   */
  onServicesLoadedUser = new BehaviorSubject(false);

  /**
   * This subject will emit when all services have finished updating due to a session status change (session loaded or changed).
   * This is useful for frontend components that want to wait for all service data tied to a session to be ready when that session changes.
   * Behavior subjects will emit the latest value immediately whenever a new subscriber is added, which is needed for the suspension implementation used in the service manager.
   */
  onServicesLoadedSession = new BehaviorSubject(false);

  constructor(
    private wsService: WebSocketService,
    private routerService: RouterService,
    private configService: ConfigService,
    private userService: UserService,
    private settingsService: PluginSettingsService,
    private sessionService: SessionService,
    private metricService: MetricService,
    private audioService: AudioService,
    private bookmarkService: BookmarkService,
    private bookmarkTypeService: BookmarkTypeService,
    private themeService: ColorThemeService,
    private sessionComparisonService: SessionComparisonService,
    private uploadService: UploadService,
    private metricGridService: MetricGridService,
    private dataStoreService: DataStoreService,
    private dataStoreMetricService: DataStoreMetricService,
    private recycleBinService: RecycleBinService,
    private searchService: SearchService,
    private editService: FileEditService,
    private sessionSummaryService: SessionSummaryService,
    private tagService: TagService,
    private store: Store<any>,
    private notificationService: NotificationService
  ) {
    super();
    // Whenever a service is added or updated in a way that changes it's cross-service dependencies,
    // make sure this list is updated to reflect that change.
    this.initializationOrder = [
      this.notificationService,
      this.wsService,
      this.routerService,
      this.userService,
      this.configService,
      this.settingsService,
      this.sessionService,
      this.metricService,
      this.audioService,
      this.bookmarkTypeService,
      this.bookmarkService,
      this.themeService,
      this.sessionComparisonService,
      this.uploadService,
      this.metricGridService,
      this.dataStoreService,
      this.dataStoreMetricService,
      this.recycleBinService,
      this.editService,
      this.searchService,
      this.sessionSummaryService,
      this.tagService,
    ];
  }

  /**
   * Given a boolean subject and a handler, iterate through the available services, calling the handler with each.
   * The subject will emit false immediately and true when the operation is done.
   * @param subject The subject to emit progress on.
   * @param handler The handler to call for each service.
   * @param startup An optional async function to call before running handler on each service.
   * @param cleanup An optional async function to call after running handler on each service.
   */
  private async waitForEvents(
    subject: Subject<boolean> | undefined,
    handler: (service: Service) => Promise<void>,
    startup?: () => Promise<void>,
    cleanup?: () => Promise<void>
  ): Promise<void> {
    if (subject) subject.next(false);
    if (startup) await startup();
    for (let service of this.initializationOrder) {
      await handler(service);
    }
    if (cleanup) await cleanup();
    if (subject) subject.next(true);
  }

  /**
   * Iterate through the services in the configured order, initializing them and configuring event subscriptions for user and session changed events.
   */
  public async initialize() {
    // When initialize is called, emit the services loaded subject and call initialize on every service in the initialization order.
    this.waitForEvents(
      this.onServicesLoaded,
      (service) => service.initialize(),
      undefined,
      async () => {
        await this.initializeBackendConnectHandler();
        await this.initializeUserChangeHandler();
        await this.initializeSessionHandler();
        await this.initializeDataRefreshHandler();
      }
    );
  }

  private async initializeBackendConnectHandler() {
    this.wsService.connectedStatusChange.subscribe(async (status) => {
      // Hold off on giving services a backend connection changed event until they have fully initialized.
      await firstValueFrom(this.onServicesLoaded.pipe(filter((loaded) => loaded)));
      if (status) this.waitForEvents(this.onServicesLoadedBackend, (service) => service.onBackendConnected());
      else this.waitForEvents(this.onServicesHandledBackendDisconnect, (service) => service.onBackendDisconnected());
    });
  }

  private async initializeUserChangeHandler() {
    this.userService.onClientConnectAuthenticate.subscribe(async ({ user, wasJWTLogin }) => {
      // If the backend connection has been established, we need to hold off on giving services a user changed event until they have reacted to the backend connection event.
      await firstValueFrom(this.onServicesLoadedBackend.pipe(filter((loaded) => loaded)));
      if (user == undefined) {
        this.waitForEvents(this.onServicesLoadedUser, (service) => service.onUserLoggedOut());
      } else {
        this.waitForEvents(this.onServicesLoadedUser, (service) => service.onUserChanged(user, wasJWTLogin));
      }
    });
  }

  private async initializeSessionHandler() {
    // Track what our last selected session is to easily determine changes
    let lastSelectedSession: Session | undefined = undefined;
    this.onServicesHandledBackendDisconnect.subscribe(() => (lastSelectedSession = undefined));
    this.store.select(selectCurrentSession).subscribe(async (currentSession) => {
      const session = currentSession != null ? Session.fromPlain({ ...currentSession }) : currentSession;
      // Only handle updates on nullables
      if (session != null) {
        // If the user has changed and services are reacting to that event, we need to hold off on giving them session change handlers.
        await firstValueFrom(this.onServicesLoadedUser.pipe(filter((loaded) => loaded)));
        // Dispatch session has updated but not changed
        if (session.id === lastSelectedSession?.id)
          this.waitForEvents(this.onServicesLoadedSession, (service) => service.onSessionUpdated(session));
      }
      // Dispatch session has changed only if it has. We want this to even include nullables,
      if (session == null || session?.id !== lastSelectedSession?.id) {
        // Tell the services tracker that we are loading the next session
        this.onServicesLoadedSession.next(false);
        this.waitForEvents(
          this.onServicesLoadedSession,
          (service) => service.onSessionChanged(session),
          undefined,
          () => this.waitForEvents(undefined, (service) => service.onSessionChangedPost(session))
        );
      }
      lastSelectedSession = session;
    });
  }

  private async initializeDataRefreshHandler() {
    this.addSubscription(
      WebSocketCommunication.WebSocketMessageReceived.pipe(
        filter((x) => x.message.topic === SessionTopics.dataRefresh)
      ).subscribe(async (data) => {
        const session = await firstValueFrom(this.store.select(selectCurrentSession));
        if (session != null && data.message.sessionId === session.id)
          for (let service of this.initializationOrder) {
            await service.onSessionDataRefresh(session);
          }
      })
    );
  }
}
