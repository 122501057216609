import { Type } from "class-transformer";
import "reflect-metadata";
import { TDMSBase } from "../base";
import { TimeSeriesBarChartValues } from "../charts/time.series.bar.chart.data";
import { Configuration } from "../config";
import { SpeakingInstance } from "./speaking.instance";

/**
 * This class separates the speaking data from the role to prevent bogging down the system while updating a role or even a session
 */
export class SpeakingData extends TDMSBase {
  /**
   * The primary key related to this speaking data overarching
   */
  id: number;

  /**
   * The role this data is associated to
   */
  roleId: number;

  /**
   * The actual data for that role
   */
  @Type(() => SpeakingInstance)
  data: SpeakingInstance[];

  constructor(id: number | undefined, roleId: number, data: SpeakingInstance[]) {
    super();
    this.id = id!;
    this.roleId = roleId;
    this.data = data;
  }

  /**
   * The total amount of time this role was speaking during a session
   * @units Seconds
   * @deprecated Refer to `this.getTotalSpeakingTime`
   */
  get totalSpeakingTime() {
    return this.getTotalSpeakingTime(this.data);
  }

  /**
   * The total amount of time this role was speaking during the given data array
   * @units Seconds
   */
  getTotalSpeakingTime(
    data: SpeakingInstance[] = this.data,
    speakingSeparationTime = Configuration.SPEAKER_SEPARATION_SPACING
  ) {
    const isSpeakingAmount = data.filter((x) => x.isSpeaking).length;
    return (isSpeakingAmount * speakingSeparationTime) / 1000;
  }

  /**
   * Given a min and max date, returns speaking instance data between those dates
   */
  getSpeakingInstanceBetweenDates(minDate: Date, maxDate: Date) {
    return this.data.filter((z) => z.time.getTime() >= minDate.getTime() && z.time.getTime() <= maxDate.getTime());
  }

  /**
   * Converts the given speaking data into groupings that consist of time frames that someone was talking.
   * @param removeSmallInstances If small instances (Configuration.SPEAKER_SEPARATION_SPACING) should be auto removed as these may signify poor recording
   *  or even obsolete background noise.
   */
  getTimeSeriesTalkingTime(speakingInstances = this.data, removeSmallInstances: boolean = false) {
    let returnData: TimeSeriesBarChartValues[] = [];
    for (let data of speakingInstances) {
      const returnDataLocation = returnData[returnData.length - 1];
      // If this role is speaking...
      if (data.isSpeaking) {
        // If we are still navigating to find the end, continue
        if (returnDataLocation != null && returnDataLocation.endTime == null) continue;
        // If the end has already been found on the last one, create a new one
        else returnData.push(new TimeSeriesBarChartValues(data.time, null as any));
      } else {
        // If the role is not speaking anymore, see if the end needs updated
        if (returnDataLocation != null && returnDataLocation.endTime == null) returnDataLocation.endTime = data.time;
        // The previous has already ended and they haven't started talking yet. Continue.
        else continue;
      }
    }
    // If we have ended with an undefined final end time, set it
    if (returnData[returnData.length - 1] && returnData[returnData.length - 1].endTime == null)
      returnData[returnData.length - 1].endTime = speakingInstances[speakingInstances.length - 1].time;
    // Clean small instances if requested
    if (removeSmallInstances) {
      returnData = returnData.filter((timeSeriesData) => {
        if (
          timeSeriesData.endTime.getTime() - timeSeriesData.startTime.getTime() <=
          Configuration.SPEAKER_SEPARATION_SPACING
        )
          return false;
        return true;
      });
    }
    return returnData;
  }
}
