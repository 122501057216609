import "reflect-metadata";
import { filter, firstValueFrom, Subject, timeout } from "rxjs";
import ws from "ws";
import { CustomTypes } from "../../models/custom.types";
import { User } from "../../models/user";
import { PayloadType, TDMSWebSocketMessage, WebSocketResponse } from "./message";
import { CoreTopics } from "./topics";

/**
 * Types that are supported for returning from WebSocket listener functions
 */
type WebSocketListenerReturnTypes = void | WebSocketResponse | WebSocketResponse[];

/**
 * An extension of the standard WebSocket with some of our own handling
 */
export type TDMSWebSocket = ws.WebSocket & {
  /**
   * Authentication information related to this WebSocket.
   *
   * **Only Available for Backend**
   */
  auth:
    | {
        userId: number;
        /** Background task that will logout the user when their JWT expires */
        logoutTask: any;
      }
    | undefined;

  /** A unique client identifier that makes it easy to locate a specific client */
  identifier: string;

  /**
   * The amount of messages that have occurred since the last rate limit check.
   *
   * **Only Available for Backend**
   */
  messageCount: number;

  /**
   * Event listener for a callback to fire when a rate limit is hit.
   *
   * **Only Available for Backend**
   */
  on(event: "limited", cb: () => void): TDMSWebSocket;

  /**
   * Event listener so when errors occur within client handling, this will be called.
   *
   * **Only Available for Backend**
   */
  on(event: "internalError", cb: (e: Error) => void): TDMSWebSocket;

  /**
   * Returns the user defined by the authentication {@link auth} as it's object.
   *
   * **Only Available for Backend**
   */
  getUser<UserTyping extends User>(): Promise<UserTyping | undefined>;
};

/**
 * The formatted function type we expect for any function utilizing the websocket listeners
 */
type WebSocketListener<ReturnType extends WebSocketListenerReturnTypes, DataType extends TDMSWebSocketMessage> = (
  data: DataType
) => Promise<ReturnType>;

/**
 * Formatted function typing to include the client in your listener function so you can have
 *  direct access to who gave you that request.
 *
 * **You should not use this to send messages directly to it, use the WebSocketResponse messages**
 */
type WebSocketListenerWithClient<
  ReturnType extends WebSocketListenerReturnTypes,
  DataType extends TDMSWebSocketMessage
> = (data: DataType, client: TDMSWebSocket) => Promise<ReturnType>;

/**
 * Similar to {@link WebSocketListenerWithClient} but includes all clients related to the websocket server
 */
type WebSocketListenerWithTotalClients<
  ReturnType extends WebSocketListenerReturnTypes,
  DataType extends TDMSWebSocketMessage
> = (data: DataType, client: TDMSWebSocket, totalClients: TDMSWebSocket[]) => Promise<ReturnType>;

type WebsocketTypes = WebSocket | ws.WebSocket | TDMSWebSocket;

/**
 * Centralized WebSocket tracking to define listeners directly to classes
 */
export class WebSocketCommunication {
  /**
   * The metadata key for websocket decorators
   */
  private static readonly METADATA_KEY = "comm:ws";

  /**
   * The websocket subject that new messages will run through
   */
  public static WebSocketMessageReceived = new Subject<{
    message: TDMSWebSocketMessage;
    client: WebsocketTypes;
    /** Only used on the backend to provide a reference to all clients in the websocket server */
    totalClients?: TDMSWebSocket[];
  }>();

  /**
   * This subject should be tracked if you want to grab a message that is going to be sent out on the websocket
   *  externally from this app.
   */
  public static WebSocketMessageSending = new Subject<{ message: WebSocketResponse; client?: WebsocketTypes }>();

  /**
   * Given a client and a payload, handles sending out messages
   * @param client The ws client to send to
   * @param payload The payload of data to send
   */
  public static sendToClient(client: WebsocketTypes | undefined, payload: TDMSWebSocketMessage) {
    (client as ws.WebSocket)?.send(payload.toJSONString());
  }

  /**
   * Sends the given payload to all clients connected to our websocket
   */
  public static sendToAllClients(payload: TDMSWebSocketMessage, clients: Set<WebsocketTypes> | WebsocketTypes[]) {
    for (let client of clients) WebSocketCommunication.sendToClient(client, payload);
  }

  /**
   * Send the given request to the destination client, and await for a response to return as this function finalizes.
   * @param payload The payload of data to send
   * @param timeoutMills How long to wait to timeout. Default is 30 minutes.
   */
  public static async sendAndReceive<
    DataType extends PayloadType,
    MessageType extends TDMSWebSocketMessage<DataType> = TDMSWebSocketMessage<DataType>
  >(client: WebsocketTypes, payload: TDMSWebSocketMessage, timeoutMills: number = 1800000) {
    this.sendToClient(client, payload);
    try {
      const result = await firstValueFrom(
        WebSocketCommunication.WebSocketMessageReceived.pipe(
          filter(
            ({ message }) =>
              message.topic == payload.topic &&
              message.sessionId == payload.sessionId &&
              message.appID == payload.appID &&
              message.messageId == payload.messageId
          )
        ).pipe(timeout(timeoutMills))
      );
      return result.message as MessageType;
    } catch (e) {
      if ((e as Error).name === "TimeoutError")
        throw new Error(`A timeout occurred during sendAndReceive on queue: ${payload.topic}`);
      else throw e;
    }
  }

  /**
   * Given params initializes this singular websocket queue listener
   * @param topic The topic to listen on
   * @param functionCall A function to call when the topic is received
   * @param sendResponsePayload If an error occurs, this will cause a response payload to be sent
   *  with the original request but will fill out the `failureMessage` field to easily identify the failure. Default is true.
   */
  static handleSingularSubscription<T extends TDMSWebSocketMessage = TDMSWebSocketMessage>(
    topic: string,
    functionCall: {
      (x: T, client: TDMSWebSocket, totalClients?: TDMSWebSocket[]): Promise<WebSocketListenerReturnTypes>;
    },
    sendResponsePayload = true
  ) {
    // Subscribe and call the function while these exist
    WebSocketCommunication.WebSocketMessageReceived.pipe(filter((x) => x.message.topic === topic)).subscribe(
      async (x) => {
        const client = x.client as TDMSWebSocket;
        try {
          // Call the actual wrapped function
          const returnValue = await functionCall(x.message as T, client, x.totalClients);
          // Handle any callback if it exists
          if (returnValue) {
            // Flatten to everything being an array
            const dataToHandle = Array.isArray(returnValue) ? returnValue : [returnValue];
            // Handle sending each message
            for (let message of dataToHandle)
              WebSocketCommunication.WebSocketMessageSending.next({ message: message, client });
          }
        } catch (e) {
          // Catch any given errors
          const errorAsObject = e instanceof Error ? e : new Error(e as string);
          const errorString = errorAsObject.message;
          // Send errors out if requested
          if (sendResponsePayload) {
            // Clone the request
            const message = x.message.clone();
            message.failureMessage = errorString;
            WebSocketCommunication.sendToClient(client, message);
          }
          client.emit("internalError", errorAsObject);
        }
      }
    );
  }

  /**
   * Base level function that does the leg work of `processClass` by defining the listeners and adding them to the
   *  subscription model.
   * @param target The target class so we can find the properties that have subscriptions
   * @param instance The instance of the class we are using to apply as our callbacks
   */
  static assignSubscriptions<T>(target: CustomTypes.ConstructorFunction<T>, instance: T) {
    // Locate all properties
    for (let fnc of Object.getOwnPropertyNames(target.prototype)) {
      const key = fnc;
      const topic = Reflect.getMetadata(WebSocketCommunication.METADATA_KEY, target.prototype, key);
      // If this property is a function that has a listener applied to it, try and call it
      if (topic) {
        // Locate the function to call
        const fncToCall = (
          (instance as any)[fnc] as {
            (x: TDMSWebSocketMessage, client: TDMSWebSocket): Promise<WebSocketListenerReturnTypes>;
          }
        ).bind(instance);
        WebSocketCommunication.handleSingularSubscription(topic, fncToCall);
      }
    }
  }

  /**
   * Installs the class this decorator wraps into the websocket listening so functions will automatically apply listeners
   *  to their respective queues.
   * @param target The target class we should process for websocket listening requests
   * @param instanceOverride We can either allow it to grab upon the first constructor that makes an instance,
   *  auto create it's own instance, or we can pass one ourselves.
   */
  public static processClass(instanceOverride: "waitForConstructor" | "autoCreate" | Object = "autoCreate") {
    return <T>(target: CustomTypes.ConstructorFunction<T>) => {
      // If we wait for the constructor, we override the constructor and extend upon it
      if (instanceOverride === "waitForConstructor") {
        return class WebsocketBase extends (target as any) {
          constructor(...args: any[]) {
            super(...args);
            WebSocketCommunication.assignSubscriptions(target, this as any);
          }
        } as CustomTypes.ConstructorFunction<T>;
      } else {
        // Else go ahead and assign subscriptions directly to our own instance we create
        WebSocketCommunication.assignSubscriptions(
          target,
          instanceOverride === "autoCreate" ? new target() : instanceOverride
        );
      }
    };
  }

  /**
   * This decorator is used to wrap a function so we will listen to websocket messages as they come through
   *    and can be more easily dispersed to specific functions with the ease of a decorator
   *
   * @param topic The topic queue to listen to messages on.
   */
  public static listen<
    T extends WebSocketListenerReturnTypes = void,
    DataType extends TDMSWebSocketMessage = TDMSWebSocketMessage
  >(topic: string | CoreTopics) {
    return (
      target: any,
      propertyKey: string,
      _descriptor:
        | TypedPropertyDescriptor<WebSocketListener<T, DataType>>
        | TypedPropertyDescriptor<WebSocketListenerWithClient<T, DataType>>
        | TypedPropertyDescriptor<WebSocketListenerWithTotalClients<T, DataType>>
    ) => {
      Reflect.defineMetadata(WebSocketCommunication.METADATA_KEY, topic, target, propertyKey);
    };
  }
}

/**
 * Similar to `WebSocketCommunication.processClass`, this class provides an extendability version that can do it automatically
 *  based on having a base constructor. This is more helpful when other languages become picky like angular
 *
 * You would want to use it like
 *
 * ```ts
 *  export class FooBar extends InjectableWebsockets {}
 * ```
 */
export class InjectableWebsockets {
  /**
   * Initializes the websocket listeners associated to the functions of this class
   */
  constructor() {
    WebSocketCommunication.assignSubscriptions((this as any).constructor, this as any);
  }
}
