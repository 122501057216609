<shared-generic-table
  [data]="accessLog"
  tableName="Access Log"
  [displayedColumns]="displayedColumns"
  [clickCallback]="accessLogClicked"
  [defaultSortHeader]="'accessedOn'"
  [columnVisibilitySettingConfig]="columnVisibilitySetting"
>
  <ng-template #buttonRow>
    <!-- Filter button for internal access requests -->
    <button mat-raised-button color="accent" class="filter-button checkbox-white">
      <mat-checkbox
        (change)="this.loadAccessLog()"
        [checked]="this.filterAccessLog"
        matTooltip="Filters chart data to hide internal requests made by the TDMS"
        matTooltipPosition="below"
        >Filter Log</mat-checkbox
      >
    </button>
  </ng-template>
</shared-generic-table>
