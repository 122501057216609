import { Type } from "class-transformer";
import { kebabCase } from "lodash";
import { TDMSBase } from "../../base";
import { Session } from "../../session-data/session";
import { User } from "../../user";
import { Utility } from "../../utility";
import { DataStoreFileTypes } from "./file.types";

export type UpdateType = "add" | "restore" | "recycle" | "delete";

/**
 * This is used for client data store synchronization.
 * It is sent from backend->client to indicate that a data store file was added or removed from a specific collection.
 * E.G. The recycle bin.
 */
export class DataStoreFileUpdate extends TDMSBase {
  type: UpdateType;
  file: DataStoreFile;

  constructor(type: UpdateType, file: DataStoreFile) {
    super();

    this.type = type;
    this.file = file;
  }
}

/** This class defines the basic outline for a data store file, not considering any pathing information as common and the backend handle that separately. */
export class DataStoreFile extends TDMSBase {
  /**
   * The extension to apply if our file is compressed. We currently use gzip.
   */
  static readonly COMPRESSION_EXTENSION = ".gz";

  /**
   * The file's db id needed to delete or restore this file on the backend.
   */
  id!: number;

  /**
   * The plugin that belongs to this file
   */
  matchingPlugin!: string;

  /**
   * The upload option type from the matching plugin this file belongs to
   */
  pluginType!: string;

  /** This path is the url at which this file exists when requesting via http request from the data store. */
  httpPath!: string;

  /**
   * The hash of the file to validate it with.
   *
   * **This hash will be the value before compression!**
   */
  hash!: string;

  /**
   * How large the file is, in bytes.
   */
  fileSize!: number;

  /**
   * The session at which this data store file relates to
   */
  @Type(() => Session)
  session!: Session;

  /**
   * FK to the user ID who uploaded this file
   */
  uploadedBy!: number;

  /**
   * The date at which this file is scheduled for deletion.
   */
  @Type(() => Date)
  deletionSchedule: Date | undefined;

  /**
   * The user who this file was requested to be deleted by
   */
  @Type(() => User)
  deleteRequestedUser: User | undefined;

  /**
   * If this file is compressed or not.
   */
  compressed: boolean = false;

  /**
   * If the file is ready to be downloaded/deleted. This is an indication if the file is in the middle of being compressed or not.
   */
  isReady: boolean = true;

  /**
   * If this file created the session or not. This is used during deletion tracking so we can know
   *  to delete a session and all corresponding data if this file was the source of the session.
   */
  createdSession: boolean = false;

  /**
   * Some files within this software can be created from other files. For example,
   *  if we create a new session based on an audio file, we normally generate a csv file
   *  to generate metrics off of. We need a way to track when a file creates another file in the event
   *  we want to regenerate the data in the future in the event algorithms or post processing improves/changes.
   *
   * We can track what file created the session it's associated to with {@link createdSession}, but this just provides different metadata.
   */
  @Type(() => DataStoreFile)
  parentFile?: DataStoreFile;

  /** Given a filename and a supported extension list, returns if this file path is supported (true) or if it's not supported (false) */
  static verifyFileExtension(fileName: string, supportedExtensions?: DataStoreFileTypes.SupportedFileUploadTypes[]) {
    if (supportedExtensions == null) return false; // No extensions given? Then it can't match
    // If we have the dynamic matcher (*) don't even bother checking
    if (supportedExtensions.includes("*")) return true;
    const extension = Utility.getFileExtensionFromString(fileName);
    if (!extension) return false;
    return supportedExtensions.includes(extension as any);
  }

  /**
   * Returns a file name based on the given content in the preferred format. This formatting does the following:
   *  1. Removes any pathing to just get the file name.
   *  2. Verifies an extension exists.
   *  3. Converts it to a kebab cased version of the name using lodash so there are no spaces within the path.
   */
  static getPreferredFileName(
    originalFileName: string,
    originalFileExtension = Utility.getFileExtensionFromString(originalFileName)
  ) {
    originalFileName = Utility.getFileName(originalFileName); // Remove any pathing if found
    if (originalFileExtension == null) throw new Error("No file extension found");
    return kebabCase(originalFileName.replace(originalFileExtension, "")) + originalFileExtension;
  }

  /** Returns {@link httpPath} without any compression extensions on the file name. */
  get uncompressedHttpPath() {
    return this.httpPath.replace(DataStoreFile.COMPRESSION_EXTENSION, "");
  }

  /** Strips the filename out of the http path so we only get the file name */
  get fileName() {
    return Utility.getFileName(this.httpPath);
  }

  /** Returns {@link fileName} without any compression extensions. */
  get uncompressedFileName() {
    return this.fileName.replace(DataStoreFile.COMPRESSION_EXTENSION, "");
  }

  /**
   * Returns this files extension. If this file is compressed, returns the entire extension including compression.
   */
  get extension() {
    const firstExtension = Utility.getFileExtensionFromString(this.fileName)!;
    if (firstExtension === DataStoreFile.COMPRESSION_EXTENSION)
      return (
        Utility.getFileExtensionFromString(this.fileName.replace(DataStoreFile.COMPRESSION_EXTENSION, "")) +
        firstExtension
      );
    else return firstExtension;
  }

  /** Returns the extension, stripping the compression extension if necessary. */
  get uncompressedExtension() {
    return this.extension.replace(DataStoreFile.COMPRESSION_EXTENSION, "");
  }
}
