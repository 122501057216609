import { TDMSBase } from "../../base";

/**
 * A configuration class to allow the frontend to know some configuration values that the backend
 *  has set for the data store
 */
export class DataStoreConfig extends TDMSBase {
  uploadEnabled: boolean = false;
  deleteEnabled: boolean = false;
  downloadEnabled: boolean = false;
  editEnabled: boolean = false;
  /** How many days till a recycled file will be deleted */
  recycleBinTimeout: number = 3;
}
