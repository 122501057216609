import { TableColumnConfiguration, TableConfiguration } from "../../../../settings/table/settings";
import { DataStoreAccessLog } from "../../access.log";
import { DataStoreSettings } from "../settings";

/** This configuration defines table options related to the Access Log table within the data store */
export class AccessLogTableConfiguration extends TableConfiguration {
  static override readonly SettingConfig = {
    pluginName: DataStoreSettings.PLUGIN_NAME,
    settingName: DataStoreSettings.Names.accessLogVisibleColumns,
  };

  static override readonly Columns = {
    ID: new TableColumnConfiguration<DataStoreAccessLog, number>("id", "ID"),
    Username: new TableColumnConfiguration<DataStoreAccessLog, string>("username"),
    FileName: new TableColumnConfiguration<DataStoreAccessLog, string>("fileName" as any, "File Name"),
    Plugin: new TableColumnConfiguration<DataStoreAccessLog, string>("plugin" as any, "Matching Plugin"),
    Reason: new TableColumnConfiguration<DataStoreAccessLog, string>("reason", "Access Reason"),
    AccessedOn: new TableColumnConfiguration<DataStoreAccessLog, Date>("accessedOn", "Accessed On"),
  };
}
