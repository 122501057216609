import { Component, QueryList, ViewChildren } from "@angular/core";
import { Store } from "@ngrx/store";
import { Notification, Utility } from "@tdms/common";
import { ToastComponent } from "@tdms/frontend/modules/notification/components/toast/toast.component";
import { NotificationState } from "@tdms/frontend/modules/notification/models/notification.state";
import { selectAllNotificationsFromState } from "@tdms/frontend/modules/notification/models/store/notification.selector";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import { map } from "rxjs";

/** This overlay component is placed overtop of the app so we can render notifications as they appear. */
@Component({
  selector: "notification-toast-overlay",
  templateUrl: "./toast-overlay.component.html",
  styleUrls: ["./toast-overlay.component.scss"],
})
export class ToastOverlayComponent extends SubscribingComponent {
  /** Notifications that should be rendered */
  notifications: Notification[] = [];

  /** Rendered toasts */
  @ViewChildren(ToastComponent) toasts!: QueryList<ToastComponent>;

  constructor(private store: Store<NotificationState>) {
    super();
    this.addSubscription(
      this.store
        .select(selectAllNotificationsFromState)
        // Filter out acknowledged notifications
        .pipe(map((x) => x.filter((z) => !z.acknowledgement)))
        .subscribe(async (x) => {
          // Determine what notifications are being removed.
          const notificationsToBeRemoved = this.notifications.filter((z) => !x.includes(z));
          // Mark each notification to be removed to trigger animations
          if (this.toasts)
            notificationsToBeRemoved.forEach((z) => {
              this.toasts.find((x) => x.toast.id === z.id)?.acknowledge(false);
            });
          if (notificationsToBeRemoved.length > 0) await Utility.delay(300);
          this.notifications = x;
        })
    );
  }
}
