import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import { Bookmark, BookmarkType } from "@tdms/common";
import { BookmarkService } from "@tdms/frontend/modules/bookmark/services/bookmark.service";
import { selectAllBookmarkTypesFromState } from "@tdms/frontend/modules/bookmark/store/bookmark.type.selector";
import { ZoomDomainUpdateEmitter } from "@tdms/frontend/modules/charts/shared/timeline/timeline.selection.base";
import { ColorThemeService } from "@tdms/frontend/modules/material/services/themes.service";
import { MetricService } from "@tdms/frontend/modules/metrics/services/metric.service";
import { MetricState } from "@tdms/frontend/modules/metrics/store/metric.state";
import { TDMSTheme } from "@tdms/frontend/modules/shared/components";
import { AngularCustomTypes } from "@tdms/frontend/modules/shared/models/angular.custom.types";
import { ScrollTrackingComponent } from "@tdms/frontend/modules/shared/utils/scroll.tracking.component";
import { BehaviorSubject, Subscription } from "rxjs";
import { MetricCardDataStore } from "../metric-card/models/metric.configuration";
import { MetricGridDataStore } from "./models/metric-grid.configuration";

/**
 * A grid that will render each of the metrics in a card to display in your viewer
 */
@Component({
  selector: "metric-grid[dataStore]",
  templateUrl: "./metric-grid.component.html",
  styleUrls: ["./metric-grid.component.scss"],
})
export class MetricGridComponent extends ScrollTrackingComponent implements OnInit, OnChanges {
  @ViewChild("chartInnerContainer") containerElement: ElementRef<HTMLElement> | undefined;
  @Input() dataStore!: MetricGridDataStore;

  @Input() getExportPrefix!: (card: MetricCardDataStore) => string;

  /**
   * An event emitter to help inform charts if a bookmark is being drawn on another chart so they can't
   *  draw multiple at a time.
   */
  @Input() bookmarkDrawingStatus = new BehaviorSubject<boolean>(false);

  @Input() zoomDomainUpdater!: ZoomDomainUpdateEmitter;

  /**  The card that is used for displaying the timeline, if available*/
  timelineCard?: MetricCardDataStore;
  currentCards: MetricCardDataStore[] = [];

  gridLevelBookmarks: Bookmark[] = [];

  /** If we should render the timeline at the top of the grid */
  shouldDisplayTimeline: boolean = true;

  /**
   * The theme that the application is currently set to use so we can tell each
   *  chart what to display as.
   */
  currentApplicationTheme: TDMSTheme = "dark";

  /**
   * An array to track only data store subscriptions so we can more easily unsubscribe from them
   *  as they change.
   */
  currentDataStoreSubscriptions: Subscription[] = [];

  /**
   * Bookmark types available from the backend
   */
  bookmarkTypes: BookmarkType[] = [];

  /*
   * Tracks if this grid is rendered in the session comparison or not
   */
  @Input() isSessionComparison: boolean = false;

  gridStartDate = new Date();
  gridEndDate = new Date();

  constructor(
    private store: Store<MetricState>,
    public bookmarkService: BookmarkService,
    public metricService: MetricService,
    private themeService: ColorThemeService
  ) {
    super();
    this.onCardUpdated = this.onCardUpdated.bind(this);
    this.onAddBookmarkClicked = this.onAddBookmarkClicked.bind(this);
    this.onUpdateBookmarkClicked = this.onUpdateBookmarkClicked.bind(this);
    this.addSubscription(this.store.select(selectAllBookmarkTypesFromState).subscribe((x) => (this.bookmarkTypes = x)));
  }

  ngOnChanges(changes: AngularCustomTypes.BaseChangeTracker<MetricGridComponent>): void {
    // If the data store changes, we need to resubscribe to our data sets
    if (changes.dataStore && !changes.dataStore.firstChange)
      this.addDataStoreSubscriptions(changes.dataStore.currentValue);
  }

  ngOnInit(): void {
    this.addDataStoreSubscriptions();
    // Application theme subscription
    this.addSubscription(
      this.themeService.applicationLevelTheme.subscribe((x) => {
        this.currentApplicationTheme = x;
      })
    );
  }

  /**
   * Listen to data store observables so we can detect when data is changing.
   *
   * We maintain all of our card observables at the grid level as this gives us higher level control over the individual card observables.
   *  Also, we need access to all the card data at the grid level because we use it to figure out how to layout the cards in the grid (see @getCardSizing)
   */
  addDataStoreSubscriptions(dataStore = this.dataStore) {
    if (!dataStore) return;
    // Times
    this.addSubscription(dataStore.startDate.subscribe((x) => (this.gridStartDate = x)));
    this.addSubscription(dataStore.endDate.subscribe((x) => (this.gridEndDate = x)));
    // Clean up previous set of subscriptions
    this.cleanupSubscriptions(this.currentDataStoreSubscriptions);
    // Add all our subscriptions
    this.currentDataStoreSubscriptions = this.addSubscription(
      // Cards
      ...dataStore.cards.map((card) =>
        card.subscribe((data) => {
          this.onCardUpdated(data);
          // Track the last enabled status locally
          this.currentDataStoreSubscriptions.push(
            ...this.addSubscription(data.enabled.subscribe((x) => (data.lastEnabledStatus = x)))
          );
        })
      ),
      // Timeline Card
      dataStore.timelineCard?.subscribe((card) => (this.timelineCard = card)),
      // Grid Bookmarks
      dataStore.bookmarks.subscribe((bookmarks) => (this.gridLevelBookmarks = bookmarks)),
      // If the timeline should be displayed
      dataStore.displayTimeline.subscribe((display) => (this.shouldDisplayTimeline = display))
    );
  }

  /**
   * Handle an update to a single card in our list of card observables.
   * The main thing we do here is push the card into our component's list of cards.
   * @param card The card that changed
   */
  onCardUpdated(card: MetricCardDataStore) {
    const existing = this.currentCards.findIndex(
      (value) => card.configuration.metricName == value.configuration.metricName
    );

    // Since this card doesn't exist in our current cache of cards, push it to the end.
    if (existing == -1) {
      this.currentCards.push(card);
      return;
    }
    // A card for this metric is already in our current card list, change it.
    this.currentCards[existing] = card;
  }

  /**
   * We use this to prevent re-renders due to the function reference changing.
   * @param bookmark
   */
  onAddBookmarkClicked(bookmark: Bookmark) {
    this.bookmarkService.addBookmark(bookmark);
  }

  /**
   * We use this to prevent re-renders due to the function reference changing.
   * @param bookmark
   */
  onUpdateBookmarkClicked(bookmark: Bookmark) {
    this.bookmarkService.updateBookmark(bookmark);
  }

  /**
   * Helper function to return the given card's observable.
   * @param i the index to return.
   * @returns the observable at the given index.
   */
  getCardAt(i: number): MetricCardDataStore | undefined {
    if (i >= this.currentCards.length) return undefined;
    return this.currentCards[i];
  }

  /**
   * Determine if this cards next card is a half card. Helps positioning and padding capabilities
   */
  hasNextHalf(i: number) {
    const nextCard = this.getCardAt(i + 1);
    const previousCard = this.getCardAt(i - 1);
    return (
      nextCard &&
      nextCard.lastEnabledStatus &&
      (previousCard == null || previousCard.configuration.sizing !== "half") &&
      nextCard.configuration.sizing === "half"
    );
  }

  /**
   * Given the items index and the item itself, returns the value that should be used
   *  for the trackBy of an ngFor statement to help prevent cards from unnecessarily recreating themselves.
   */
  getElementTracker(_index: number, item: MetricCardDataStore) {
    return item.configuration.metricName;
  }
}
