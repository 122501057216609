import { TDMSBase } from "@tdms/common";
import { ChartJSDrawingHelper, DEFAULT_LABEL_PADDING, LabelObject } from "./drawing.helper";

import { Plugin as ChartJSPlugin } from "chart.js";
import { cloneDeep } from "lodash-es";
import { PluginBase, PluginBaseExternalOpts, PluginBaseInternalOpts } from "./plugin.base";

/** An object that helps determine what to display in the bookmark legend */
export class BookmarkLegendObject extends TDMSBase {
  /** The header to display for this subsection */
  header?: string;
  /** The elements to render */
  legendElements?: LabelObject[];
}

/**
 * This plugin allows us to render a "legend" style in the corner of the graph to easily display labels on it. We routinely use this
 *  for the horizontal bookmarks to allow multiple horizontal bars to be rendered per graph.
 */
export namespace BookmarkLegendPlugin {
  /** External supported options */
  export class ExternalOptions extends PluginBaseExternalOpts {
    /** The data to display inside the legend. You can also pass subset arrays here. */
    data?: BookmarkLegendObject[];
  }

  /** Options for internal use of this plugin only */
  export class InternalOptions extends PluginBaseInternalOpts {}

  export class Plugin extends PluginBase<ExternalOptions, InternalOptions> implements ChartJSPlugin<any> {
    constructor(pluginCanvas: HTMLCanvasElement) {
      super(pluginCanvas, "bookmarkLegend", ExternalOptions, InternalOptions);
    }

    /** Draws the legend content */
    drawLegend() {
      if (!this.chart?.chartArea) return;
      // Verify options
      const options = this.externalOpts.data?.map((x) => {
        x.legendElements?.forEach((z) => {
          if (!z.color) z.color = ChartJSDrawingHelper.defaultColor;
          return z;
        });
        return x;
      });
      let yStart = this.chart.chartArea?.top + DEFAULT_LABEL_PADDING;
      // Draw the labels
      if (options)
        for (let labelSet of options)
          if (labelSet.legendElements && labelSet.legendElements?.length !== 0) {
            const contentToRender = cloneDeep(labelSet.legendElements);
            // Add the header if defined
            if (labelSet.header)
              contentToRender.unshift(
                LabelObject.fromPlain({
                  text: labelSet.header,
                  color: "#FFFFFF",
                  textAlign: "center",
                  renderBorder: true,
                })
              );
            yStart = this.drawingHelper.drawLabelToPos(yStart, "right", LabelObject.fromPlainArray(contentToRender));
          }
    }

    override render(): void {
      if (this.shouldExecuteFunctionality()) this.drawLegend();
    }
  }
}
