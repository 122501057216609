import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { User, UserIconOptions, UserManagementTableConfiguration, Utility } from "@tdms/common";
import { DialogWrapperComponent } from "@tdms/frontend/modules/shared/components";
import { DataColumn, DatalessColumn } from "@tdms/frontend/modules/shared/components/tables/models";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import { UserService } from "@tdms/frontend/modules/user/services/user.service";
import { selectAllUsersFromState } from "@tdms/frontend/modules/user/store/user.selector";
import { UserState } from "@tdms/frontend/modules/user/store/user.state";
import { ChangePasswordComponent, ChangePasswordDialogProps } from "../change-password/change-password.component";
import { UserEditComponent, UserToEditDialogProperties } from "../user-edit/user-edit.component";

@Component({
  selector: "user-management",
  templateUrl: "./user-management.component.html",
  styleUrls: ["./user-management.component.scss"],
})
export class UserManagementComponent extends SubscribingComponent {
  /** All available users for this display */
  users: User[] = [];

  /** Columns that should be displayed for the table within this component */
  displayedColumns = [
    DataColumn.fromColumnConfig(UserManagementTableConfiguration.Columns.Name, (_value, obj) => {
      // Customize the searching capability
      let returnString = obj.username;
      if (obj.firstName && obj.lastName) returnString += `, ${obj.fullName}`;
      return returnString;
    }),
    DataColumn.fromColumnConfig(UserManagementTableConfiguration.Columns.LastLogin, Utility.getDateAsZulu.bind(this)),
    DataColumn.fromColumnConfig(UserManagementTableConfiguration.Columns.Admin),
    DataColumn.fromColumnConfig(UserManagementTableConfiguration.Columns.LockedOut),
    new DatalessColumn("actions", "Actions"),
  ];

  /** Defines the setting name and plugin for column visibility persistence within the table of this component */
  readonly columnVisibilitySetting = UserManagementTableConfiguration.SettingConfig;

  constructor(private store: Store<UserState>, private dialog: MatDialog, public userService: UserService) {
    super();
    this.addSubscription(this.store.select(selectAllUsersFromState).subscribe((users) => (this.users = users)));
  }

  /**
   * Opens the user edit form with some basic data and allows you to register a new user manually
   */
  createUser() {
    this.dialog.open(UserEditComponent, {
      data: {
        userToEdit: User.fromPlain({ icon: Utility.randomValueFromArray(UserIconOptions) }),
        mode: "add",
      } as UserToEditDialogProperties,
      ...DialogWrapperComponent.getDefaultOptions(),
    });
  }

  /**
   * Toggles the locking state on the given user account
   */
  lockUnlockAccount(user: User) {
    if (user.lockedOut) this.userService.unlockAccount(user);
    else this.userService.lockAccount(user);
  }

  /**
   * Opens the user edit dialog with the given user to update their information
   */
  editUserAccountInfo(user: User) {
    this.dialog.open(UserEditComponent, {
      data: {
        userToEdit: user,
        mode: "edit",
      } as UserToEditDialogProperties,
      ...DialogWrapperComponent.getDefaultOptions(),
    });
  }

  /**
   * Given a user, opens a dialog for password reset
   */
  updateUserPassword(user: User) {
    this.dialog.open(ChangePasswordComponent, {
      data: {
        user,
        adminMode: this.userService.currentAuthenticatedUser?.hasPermissions(["userManagement"], false),
      } as ChangePasswordDialogProps,
      ...DialogWrapperComponent.getDefaultOptions(),
    });
  }
}
