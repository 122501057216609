<div class="timeline-container mat-card">
  <!-- Render our timeline chart -->
  <div class="chart-container" #waveformContainer>
    <metric-card
      *ngIf="card"
      class="timeline-card"
      [ngClass]="{ 'audio-available': shouldDisplayTranscription && audioService.audioFile != null }"
      [dataStore]="card"
      [graphTitleStart]="getPrettySessionStart()"
      [graphTitleEnd]="getPrettySessionStop()"
      [zoomDomainUpdater]="zoomDomainUpdater"
      [applicationTheme]="theme"
      [isSessionComparison]="isSessionComparison"
      [totalChartTimeFrame]="[gridStartDate, gridEndDate]"
      [bookmarkTypes]="bookmarkTypes"
      [bookmarkDrawingStatus]="bookmarkDrawingStatus"
      [onAddBookmarkClicked]="onAddBookmarkClicked"
      [onUpdateBookmarkClicked]="onUpdateBookmarkClicked"
      [gridLevelBookmarks]="gridBookmarks"
    ></metric-card>
  </div>
  <!-- Render the transcription playback information -->
  <shared-div-progress-component
    *ngIf="shouldDisplayTranscription"
    [ready]="!loadingTranscriptionData"
    notReadyMessage="Loading transcriptions..."
  >
    <transcription-playback
      *ngIf="bgAudioPlayer"
      [ngStyle]="{ visibility: loadingTranscriptionData ? 'hidden' : 'inherit' }"
      [theme]="theme"
      [safeSpaceObservable]="safeSpace"
      [timeDomain]="timeDomain"
      [transcriptions]="transcriptions"
      class="transcription-playback"
      [currentPlaybackObservable]="bgAudioPlayer.currentPlayerTime"
      [speakers]="speakers"
      [colorLookup]="colorLookup"
    ></transcription-playback>
  </shared-div-progress-component>
  <!-- Render the audio player and the legend -->
  <div class="bottom-row-container" *ngIf="(shouldDisplayTranscription && transcriptions?.length) || card?.legend">
    <!-- Audio player in container so the element will always exist -->
    <div class="audio-player">
      <!-- Audio player, using bg audio handler -->
      <shared-audio-player
        *ngIf="shouldDisplayTranscription && transcriptions?.length"
        [audioControllerExternal]="player"
        displayType="small"
        class="audio-player"
        [displayPlaybackState]="true"
      ></shared-audio-player>
    </div>
    <!-- Render our overall session legend -->
    <div class="legend-container">
      <charts-custom-legend
        *ngIf="card?.legend"
        [values]="card!.legend!.value"
        (clickCallback)="card?.onLegendClicked($event)"
        [colorGetter]="getColor.bind(this)"
      ></charts-custom-legend>
    </div>
    <!-- Flex placeholder -->
    <div class="placeholder"></div>
  </div>
</div>
