import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { CommonSetting, TableConfiguration } from "@tdms/common";
import { PluginSettingsService } from "@tdms/frontend/modules/settings/services/settings.service";
import { DataColumn, GenericTableColumn } from "@tdms/frontend/modules/shared/components/tables/models";
import { AngularCustomTypes } from "@tdms/frontend/modules/shared/models/angular.custom.types";
import { cloneDeep } from "lodash-es";
import { TableBaseComponent, TableRequiredObject } from "../table-base/table-base.component";

/**
 * This class adds support to the injected table to turn on and off certain columns so they are not immediately visible to the user.
 */
@Component({ template: "" })
export class TableColumnVisibility<T extends TableRequiredObject>
  extends TableBaseComponent<T>
  implements OnChanges, OnInit
{
  /** Holds the columns that should be rendered based on displayed columns + internal columns */
  renderedColumns: GenericTableColumn[] = [];

  /**
   * This configuration value controls if we should allow the columns to have their visibility edited by the user.
   *
   * By default, all columns will be displayed unless a setting is given ({@link columnVisibilitySetting}). If the setting
   *  is not provided, the visibility will not persist on refresh.
   */
  @Input() allowColumnVisibilityInteraction = true;

  /**
   * This configuration specifies the setting information so we can persist column visibility across
   * sessions. We only require the names here as subscriptions will be handled internally.
   */
  @Input() columnVisibilitySettingConfig?: (typeof TableConfiguration)["SettingConfig"];

  /** The column visibility setting as loaded by {@link columnVisibilitySettingConfig} */
  private columnVisibilitySetting?: CommonSetting;

  constructor(public settingService: PluginSettingsService) {
    super();
  }

  ngOnInit(): void {
    this.handleSetting();
  }

  /** Handles the column visibility setting and sets up necessary handlers to handle the data persistence */
  private async handleSetting() {
    const settingConfig = this.columnVisibilitySettingConfig;
    if (settingConfig == null) return; // No setting, then nothing to handle
    // If we do have the setting, we need to setup a subscription to get it's data
    this.addSubscription(
      this.settingService.observeSetting(settingConfig.pluginName, settingConfig.settingName).subscribe((x) => {
        this.columnVisibilitySetting = cloneDeep(x); // Update ref
        this.selectedColumnsChanged(x.value); // Make sure we stay in sync
      })
    );
  }

  /** Given the new columns to display, updates the setting if we have a configuration */
  private updateSetting(cols: string[]) {
    if (!this.columnVisibilitySetting || !this.columnVisibilitySettingConfig) return;
    // Update our internal setting
    this.columnVisibilitySetting.value = cols;
    // Inform the backend of our new setting update
    this.settingService.updateSpecificSetting(
      this.columnVisibilitySetting as any,
      this.columnVisibilitySettingConfig.pluginName
    );
  }

  ngOnChanges(changes: AngularCustomTypes.BaseChangeTracker<TableColumnVisibility<any>>): void {
    // Update column status in the event internal ones are later added
    this.setRenderedColumns(
      (changes.selectionUpdate?.currentValue || this.selectionUpdate) != null,
      changes.displayedColumns?.currentValue || this.displayedColumns
    );
  }

  /** Returns all titles of column options that can have their visibility edited */
  get visibilityColumnTitles() {
    return this.displayedColumns.filter((z) => z.canEditColumnVisibility).map((x) => x.title);
  }

  /** Returns all titles of column options that are currently being rendered that can have their visibility edited. */
  get visibilityColumnRenderedTitles() {
    return this.renderedColumns.filter((z) => z.canEditColumnVisibility).map((x) => x.title);
  }

  /**
   * Returns all header names for the current rendered set of columns
   * @see {@link renderedColumns}
   */
  get renderedHeaderNames() {
    return this.renderedColumns.map((x) => x.name);
  }

  /** Sets the rendered columns to consider additional internal columns */
  private setRenderedColumns(shouldDisplaySelection = this.selectionUpdate != null, columns = this.displayedColumns) {
    const renderCols = this.getRenderedColsConsideringSetting(shouldDisplaySelection, columns);
    // Filter out certain columns
    const filteredRenderCols = renderCols.filter((x) => {
      if (x instanceof DataColumn) return !x.onlyShowInExport;
      else return true;
    });
    this.renderedColumns = filteredRenderCols;
  }

  /**
   * Returns the render columns considering the current user setting if available
   *
   * @param shouldDisplaySelection If the selection row should be displayed
   * @param columns The columns to actually try and render
   */
  private getRenderedColsConsideringSetting(shouldDisplaySelection: boolean, columns: GenericTableColumn[]) {
    let renderCols = cloneDeep(columns); // Clone to not break the original
    // Prepend selection col if selected
    if (shouldDisplaySelection) renderCols.unshift(this.selectionColumn);
    // If we have user column settings, consider those to reduce available render columns
    if (this.columnVisibilitySetting) {
      const settingTitles = this.columnVisibilitySetting.value as Array<string>;
      renderCols = renderCols.filter((x) => settingTitles.includes(x.title) || !x.canEditColumnVisibility);
    }
    return renderCols;
  }

  /**
   * This function is used to control when a user toggles a column to be displayed. Will
   *  inform of the updated setting which will trigger the {@link selectedColumnsChanged}
   *  to perform the update
   */
  protected userUpdatedVisibleColumns(event: string[] | undefined) {
    // If we have no selected, don't do anything as we at least have to display one column.
    if (event == null || event.length === 0) return;
    if (this.columnVisibilitySettingConfig == null)
      this.selectedColumnsChanged(event); // If we have no config, just update it directly
    else this.updateSetting(event); // If we have configuration, update the setting and allow the websocket loop to be what updates the column
  }

  /**
   * This function is used to control when a user toggles a column to be displayed
   * @param event Should be an array of **titles** of the headers to be rendered. Order does not matter
   *  as order will be assumed from {@link displayedColumns}.
   */
  private selectedColumnsChanged(event: string[]) {
    // Order matters to keep things consistent so create the new mapping based on displayed columns and what fields we have
    const mappedCols: GenericTableColumn[] = [];
    for (let col of this.displayedColumns) {
      const shouldDisplay = event.includes(col.title);
      if (shouldDisplay || !col.canEditColumnVisibility) mappedCols.push(col);
    }
    this.setRenderedColumns(undefined, mappedCols);
  }
}
