import { TDMSBase } from "@tdms/common";
import { merge } from "lodash-es";
import { ExtendedChartType } from "./plugin.typing";

/**
 * The default vertical line width to use
 */
export const DEFAULT_VERTICAL_LINE_WIDTH = 4;

/**
 * Default width for rendering horizontal lines
 */
export const DEFAULT_HORIZONTAL_LINE_WIDTH = 4;

/**
 * The default segment for the line dash
 */
export const DEFAULT_LINE_DASH = [12, 4];

/** Default padding around label boxes */
export const DEFAULT_LABEL_PADDING = 10;

/**
 * Default rect fill coloring without opacity trailing
 */
export const DEFAULT_RECT_FILL_NO_OPACITY = "#808080";

/**
 * Default rect fill coloring with opacity trailing
 */
export const DEFAULT_RECT_FILL = DEFAULT_RECT_FILL_NO_OPACITY + "40";

/**
 * Default rect stroke coloring
 */
export const DEFAULT_RECT_STROKE = "#888888";

/**
 * The default background color and opacity in RGBA for the label background
 */
export const DEFAULT_LABEL_BG = "rgba(0,0,0,0.8)";

export class LabelDrawingOptions {
  /** Rectangle type for the label */
  rectType: "round" | "standard" = "round";
  font = '14px Roboto, "Helvetica Neue", monospace';
  /** Rect background color */
  backgroundColor = DEFAULT_LABEL_BG;
  /** How much padding to place around the text */
  padding = DEFAULT_LABEL_PADDING;
  /** How far to pad the text vertically */
  textSeparationPadding = 4;
  textAlign: CanvasTextAlign = "start";
  /** How far to pad the rect from the actual x position */
  rectPaddingFromX = 10;
  /** Border radius for the tooltip rectangle */
  borderRadius = 5;
  /**
   * In the event we don't have enough vertical space to render all of our labels, we will render as
   *  many as we can and then call {@link additionalLabelsText} to render the last label for N additional labels.
   *
   * This property allows you to customize if we should just call this instead of rendering any other labels.
   *
   * - `true` Means to render as many as we can and then call the additional display.
   * - `false` Means don't render any of the labels and just display whatever the {@link additionalLabelsText} wants.
   * @default true
   */
  cutOffCallAdditionalLabel = true;
  /**
   * This additional labels text can customize what to render in the event we have more labels than we have room to display. We will still render
   *  as much as we can and only fall back to this for the additional.
   * @param cutData The data that is cut down but will fit in the label display.
   * @param completeOptions The complete data options (non cut down to fit).
   */
  additionalLabelsText?: { (cutData: LabelObject[], completeOptions: LabelObject[]): LabelObject[] };
}

/**
 * A type specifying the required properties to render a label
 */
export class LabelObject extends TDMSBase {
  /** Text to render for this legend object */
  text: string | { text: string; color: string }[] = "";
  /** Color to render for this text. Will be set to default if not given. Should be a hex code. */
  color?: string;
  /** Where to align the label */
  textAlign: "start" | "center" = "start";
  /** If we want a border under this label */
  renderBorder = false;
  /** If the text should be trimmed */
  trim = true;

  /** In the event the text is using the array type to specify colors, this returns just the joined text how it'll be rendered */
  get fullText() {
    return Array.isArray(this.text) ? this.text.map((x) => x.text).join(", ") : this.text;
  }
}

/**
 * This class provides some generic functionality for drawing elements onto a ChartJS graph with given options. You should make an instance
 *  of this class once per chart when attempting to draw.
 */
export class ChartJSDrawingHelper {
  /**
   * Creates a new instance of the chart drawing helper
   * @param chart The chart associated to this drawing
   * @param context The context we wish to draw against for our canvas. This can either be the main chart canvas or the plugin canvas.
   */
  constructor(private chart: ExtendedChartType, private context = chart.ctx) {}

  /**
   * Returns a default color to use if an option color is not provided
   */
  static get defaultColor() {
    return getComputedStyle(document.body).getPropertyValue("--accent-color") || "gray";
  }

  /**
   * Given some values, validates the given value is within the charts area and returns it. If it is not, it will return
   *  the max for that chart area.
   * @param chart The chart to check against
   * @param propToCheckAgainst The property name to validate
   * @param valueToCheck The value to check and return if it is within spec
   */
  enforceChartArea(propToCheckAgainst: "left" | "bottom" | "right" | "top", valueToCheck: number) {
    const { left, right, top, bottom } = this.chart.chartArea;
    if (propToCheckAgainst === "left" && valueToCheck < left) return left;
    else if (propToCheckAgainst === "bottom" && valueToCheck < bottom) return bottom;
    else if (propToCheckAgainst === "right" && valueToCheck > right) return right;
    else if (propToCheckAgainst === "top" && valueToCheck > top) return top;
    else return valueToCheck;
  }

  /**
   * Given the chart and the X position, draws a vertical line on the chart with the given options
   * @param x The x position to start the line. Width is determined in options
   * @param options Contains useful configuration values
   */
  drawVerticalLine(
    x: number,
    options: {
      color?: string | null;
      width?: number;
      /**
       * If the vertical line should be dashed
       * @default false
       */
      dashed?: boolean;
    }
  ) {
    // Enforce X Pos within chart area bounds
    x = this.enforceChartArea("left", x);
    x = this.enforceChartArea("right", x);
    const { top, bottom } = this.chart.chartArea;
    const ctx = this.context;
    // Set color
    ctx.strokeStyle = options.color || ChartJSDrawingHelper.defaultColor;
    // Apply dashing
    if (options.dashed) ctx.setLineDash(DEFAULT_LINE_DASH);
    else ctx.setLineDash([]);
    ctx.lineWidth = options.width || DEFAULT_VERTICAL_LINE_WIDTH;
    ctx.beginPath();
    ctx.moveTo(x, bottom);
    ctx.lineTo(x, top);
    ctx.stroke();
  }

  /**
   * Given the chart and Y value position, draws a horizontal line with the given options
   * @param y The y value to display this on
   * @param options Contains useful configuration values
   */
  drawHorizontalLine(
    y: number,
    options: {
      color?: string | null;
      /**
       * An optional start date to draw the bookmark at. Defaults to the chart start.
       */
      start?: Date;
      /**
       * An optional end date to draw the bookmark at. Defaults to the chart end.
       */
      end?: Date;
    }
  ) {
    const chart = this.chart;
    const ctx = this.context;
    let { left, right } = chart.chartArea;
    // Override start/end
    if (options.start) left = chart.scales.x.getPixelForValue(options.start.getTime());
    if (options.end) right = chart.scales.x.getPixelForValue(options.end.getTime());
    // Validate zone is within chart
    left = this.enforceChartArea("left", left);
    right = this.enforceChartArea("right", right);
    ctx.strokeStyle = options.color || ChartJSDrawingHelper.defaultColor;
    // Use a line stroke so we can do dashes
    ctx.beginPath();
    ctx.lineWidth = DEFAULT_HORIZONTAL_LINE_WIDTH;
    ctx.setLineDash(DEFAULT_LINE_DASH);
    ctx.moveTo(left, y);
    ctx.lineTo(right, y);
    ctx.stroke();
  }

  /** Calculates label dimensions and info based on the given options */
  private calculateLabelInfo(
    data: Array<LabelObject>,
    yStart: number,
    options: LabelDrawingOptions,
    ctx = this.context
  ) {
    let totalHeight = 0; // Overarching height for all elements
    const labels = data.map((label, i) => {
      if (label.trim && typeof label.text === "string") label.text = label.text.trim();
      const dimensions = ctx.measureText(label.fullText);
      let height = dimensions.actualBoundingBoxAscent + dimensions.actualBoundingBoxDescent;
      // Determine where our text starts based on heights
      const yStartPos = yStart + totalHeight;
      // Handle bottom border
      let borderHeight = 1;
      let borderPadding = options.padding / 2;
      if (label.renderBorder)
        // Add border padding sizing for above and below border
        height += borderPadding;
      // Add padding to bottom of text if not last index
      if (data.length - 1 !== i) height += options.textSeparationPadding;
      totalHeight += height; // Update overarching height
      return { label, height, yStartPos, width: dimensions.width, borderHeight, borderPadding };
    });
    const widestText = Math.max(...labels.map((x) => x.width));
    return { labels, widestText, totalHeight };
  }

  /**
   * Draws the label grouping in a box to the specific x/y coordinate system
   * @param chart The chart to draw onto
   * @param yStart The specific y position to draw to
   * @param xStart The specific x position to draw to or a float position to come up with based on label
   * @param data The labels to draw
   * @param options Additional configuration for this label
   * @returns the Y position where the label set stopped
   */
  drawLabelToPos(
    yStart: number,
    xStart: number | "right" | "left",
    data: Array<LabelObject>,
    partialOpts: Partial<LabelDrawingOptions> = new LabelDrawingOptions()
  ) {
    const chart = this.chart;
    const ctx = this.context;
    const options = merge(new LabelDrawingOptions(), partialOpts);
    // Ignore empty data lengths
    if (data.length === 0) return yStart;

    // Set some options
    ctx.font = options.font;
    ctx.fillStyle = options.backgroundColor;
    ctx.textBaseline = "top";

    let { totalHeight, labels, widestText } = this.calculateLabelInfo(data, yStart, options);

    // Height handling for drawing positions
    let rectYStart = yStart - options.padding;
    let rectHeight = totalHeight + options.padding * 2; // * 2 to offset the rectYStart being padded

    // If our height positioning is larger than the canvas, we have an issue as the label display will be cutoff
    if (rectHeight > ctx.canvas.height) {
      // Try to shift the start position of the label to counter balance
      const totalAmountOver = rectHeight - ctx.canvas.height;
      const potentialNewStart = rectYStart - totalAmountOver;
      // If we are less than 0, we'd be off the chart. We're going to need to reduce the amount of labels displayed.
      if (potentialNewStart < 0) {
        // Find the minimum number of labels possible to render within the bounds
        let minLabelLength = data.length;
        // Until the data fits, or we run out of data, keep looping
        while (minLabelLength > 0 && rectHeight > ctx.canvas.height) {
          minLabelLength--;
          let cutData: LabelObject[] = [];
          if (options.cutOffCallAdditionalLabel ?? true) cutData = data.slice(0, minLabelLength);
          // Add our +N label so it's included in the calc
          const overflowCount = data.length - minLabelLength;
          if (options.additionalLabelsText) cutData.push(...options.additionalLabelsText(cutData, data));
          else cutData.push(LabelObject.fromPlain({ text: `+ ${overflowCount} labels` }));
          // Re calculate new dimensions
          const newCalc = this.calculateLabelInfo(cutData, yStart, options);
          labels = newCalc.labels;
          widestText = newCalc.widestText;
          rectHeight = newCalc.totalHeight + options.padding * 2;
        }
      } else {
        // Else, we have enough room on the chart so display it normally with the height adjustment
        rectYStart = potentialNewStart;
      }
    }

    // Determine x positional data as well as width
    const width = widestText + options.padding * 2; // * 2 For the left and right side padding
    // Determine xStart based on factors
    let xStartCalculated = 0;
    if (xStart === "left") xStartCalculated = this.enforceChartArea("left", chart.chartArea.left - width);
    else if (xStart === "right") xStartCalculated = this.enforceChartArea("right", chart.chartArea.right + width);
    else xStartCalculated = xStart;
    let rectXStart = xStartCalculated! + options.rectPaddingFromX;
    // Move the start if the width will extend past the chart start
    if (rectXStart + width > chart.chartArea.right) rectXStart -= width + options.rectPaddingFromX * 2;
    const textXStart = rectXStart + options.padding;
    const textCenter = textXStart + widestText / 2;

    // Draw rect
    if (options.rectType === "standard") ctx.rect(rectXStart, rectYStart, width, rectHeight);
    else this.roundRect(rectXStart, rectYStart, width, rectHeight, options.borderRadius);
    // Fill in the rect
    ctx.fill();

    // Render text inside rect
    for (let labelProps of labels) {
      ctx.textAlign = labelProps.label.textAlign;
      // Set current color
      ctx.fillStyle = labelProps.label.color || ChartJSDrawingHelper.defaultColor;
      // Render text. Render custom colors if supported
      if (Array.isArray(labelProps.label.text)) {
        for (let i = 0; i < labelProps.label.text.length; i++) {
          const subLabel = labelProps.label.text[i];
          const prevContent = labelProps.label.text.slice(0, i);
          const prevText = prevContent
            .map((x, i) => x.text + (i !== labelProps.label.text.length - 1 ? ", " : ""))
            .join("");
          const xPos = textXStart + ctx.measureText(prevText).width;
          ctx.fillStyle = subLabel.color;
          const hasNextText = labelProps.label.text.length - 1 !== i;
          const text = subLabel.text + (hasNextText ? ", " : "");
          ctx.fillText(text, xPos, labelProps.yStartPos, labelProps.width);
        }
      } else
        ctx.fillText(
          labelProps.label.text,
          labelProps.label.textAlign === "center" ? textCenter : textXStart,
          labelProps.yStartPos,
          labelProps.width
        );
      // Render extras as requested
      if (labelProps.label.renderBorder)
        ctx.fillRect(
          rectXStart,
          labelProps.yStartPos + labelProps.height - labelProps.borderPadding,
          width,
          labelProps.borderHeight
        );
      // Set text align back to default
      ctx.textAlign = "start";
    }
    return yStart + rectHeight + options.padding;
  }

  /**
   * Get's full encompassing rectangle dimensions for the given chart based on start/end
   */
  getRectDimensions(startX: number, endX: number) {
    const chart = this.chart;
    let left = Math.min(startX, endX);
    let right = Math.max(startX, endX);
    // Keep left/right within range
    left = this.enforceChartArea("left", left);
    right = this.enforceChartArea("right", right);
    return {
      left: left,
      right: right,
      top: chart.chartArea.top,
      bottom: chart.chartArea.bottom,
      width: right - left,
      height: chart.chartArea.bottom - chart.chartArea.top,
    };
  }

  /**
   * This function handles drawing a rectangle between the given points for vertical lines on the given chart
   * @param startX The X value start
   * @param endX The x value end
   * @param options Some generic options that can apply to this rectangle being drawn
   */
  drawRect(
    startX: number,
    endX: number,
    options: {
      color?: string | null;
      strokeColor?: string;
      /**
       * If we should outline the rect
       */
      strokeEnabled?: boolean;
      /**
       * Only stroke the start and stop and not the top/bottom
       */
      strokeStartStopOnly?: boolean;
      /**
       * If the stroke around the rect should be dashed.
       * @default false
       */
      strokeDash?: boolean;
    }
  ) {
    const ctx = this.context;
    // Apply rectangle colors
    ctx.fillStyle = options.color || DEFAULT_RECT_FILL;
    if (options.strokeEnabled) ctx.strokeStyle = options.strokeColor || DEFAULT_RECT_STROKE;
    ctx.lineWidth = 2;
    const dim = this.getRectDimensions(startX, endX);
    ctx.fillRect(dim.left, dim.top, dim.width, dim.height);
    if (options.strokeEnabled && !options.strokeDash) ctx.setLineDash([]);
    if (options.strokeEnabled)
      if (options.strokeStartStopOnly) {
        const opts = { color: ctx.strokeStyle as string, dashed: options.strokeDash };
        this.drawVerticalLine(dim.left, opts);
        this.drawVerticalLine(dim.right, opts);
      } else ctx.strokeRect(dim.left, dim.top, dim.width, dim.height);
    return { x: dim.left, y: dim.top, width: dim.width, height: dim.height };
  }

  /**
   * Given the chart and dimensions clears the rectangle from existing
   */
  clearRect(startX: number, endX: number) {
    const ctx = this.context;
    const dim = this.getRectDimensions(startX, endX);
    ctx.clearRect(dim.left, dim.top, dim.width, dim.height);
  }

  /**
   * Allows drawing round rect's in canvas. Not currently supported by our typescript version and will be in future typescript updates.
   * @deprecated Scheduled for removal with angular upgrade
   */
  private roundRect(x: number, y: number, w: number, h: number, r: number) {
    const ctx = this.context;
    if (w < 2 * r) r = w / 2;
    if (h < 2 * r) r = h / 2;
    ctx.beginPath();
    ctx.moveTo(x + r, y);
    ctx.arcTo(x + w, y, x + w, y + h, r);
    ctx.arcTo(x + w, y + h, x, y + h, r);
    ctx.arcTo(x, y + h, x, y, r);
    ctx.arcTo(x, y, x + w, y, r);
    ctx.closePath();
  }
}
