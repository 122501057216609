import { DialogRef } from "@angular/cdk/dialog";
import { Component } from "@angular/core";
import { CustomTypes } from "@tdms/common";
import { TrackedFile } from "@tdms/frontend/modules/data-store/components/uploader/file-tree/models/tracked.file";
import { UploaderProgressComponent } from "@tdms/frontend/modules/data-store/components/uploader/uploader-progress/uploader-progress.component";
import { UploadService } from "@tdms/frontend/modules/data-store/services/upload.service";
import { NotificationService } from "@tdms/frontend/modules/notification/services/notification.service";
import { UserService } from "@tdms/frontend/modules/user/services/user.service";
import { formatDistance } from "date-fns";
import { cloneDeep } from "lodash-es";

/**
 * A component that will provide default options when creating new sessions
 */
@Component({
  selector: "data-store-session-creation",
  templateUrl: "./session-creation.component.html",
  styleUrls: ["./session-creation.component.scss"],
})
export class SessionCreationComponent extends UploaderProgressComponent {
  /**
   * Extra files to be uploaded alongside the session
   */
  currentExtraFiles: TrackedFile[] = [];

  /** Files that should be displayed in extra files. This will include `sessionCreationFiles` */
  displayExtraFiles: TrackedFile[] = [];

  /**
   * The file that is being treated as a session creation
   */
  sessionCreationFiles: TrackedFile[] = [];

  /**
   * The session name or prefix to use when uploading a session
   */
  sessionNameOrPrefix: string = "";

  constructor(
    public override uploadService: UploadService,
    public override notificationService: NotificationService,
    private dialogRef: DialogRef,
    public override userService: UserService
  ) {
    super(userService, uploadService, notificationService);
  }

  /**
   * Updates the extra files defined by the property name to be displayed in the file tree
   */
  updateExtraFiles(property: CustomTypes.PropertyNames<SessionCreationComponent, TrackedFile[]>, files: TrackedFile[]) {
    /**
     * Auto set the name in two cases
     * 1. The name is = the previous file name
     * 2. The name is not already filled in. We don't want to override if they type the name then select the file.
     */
    if (property === "sessionCreationFiles") {
      files.map((x) => {
        x.isSessionCreation = true;
        x.removable = false;
      });
      const lastFileName = this.uploadService.getSessionNameFromFile(this.sessionCreationFiles[0]);
      // If the current name is the last file name, feel free to update
      if (this.sessionNameOrPrefix === lastFileName || !this.sessionNameOrPrefix)
        this.sessionNameOrPrefix = this.uploadService.getSessionNameFromFile(files[0]);
    }
    // Clean extra files to not display the session creation files
    if (property === "currentExtraFiles") files = files.filter((x) => !x.isSessionCreation);
    // Clone deep to forcibly change the reference
    this[property] = cloneDeep(files);
    // Set custom display files to include session creation content
    if (property === "currentExtraFiles" || property === "sessionCreationFiles")
      this.displayExtraFiles = this.getExtraFiles();
  }

  /** Returns the extra files to display in the extra files component */
  getExtraFiles() {
    const sessionCreationFiles = cloneDeep(this.sessionCreationFiles);
    return sessionCreationFiles.concat(this.currentExtraFiles);
  }

  /**
   * Returns if the submit button should be clickable or not
   */
  get submitButtonAvailable() {
    return (
      this.sessionCreationFiles.length !== 0 && this.sessionNameOrPrefix != null && this.sessionNameOrPrefix !== ""
    );
  }

  /**
   * Returns the validation message to display in the event we cannot create a session
   */
  get validationMessage() {
    if (!this.sessionNameOrPrefix) return "Session name is required";
    else if (this.sessionCreationFiles.length === 0) return "A session file must be uploaded";
    return undefined;
  }

  /**
   * Fires a callback to attempt to create a session with the extra files
   */
  async submitCallback() {
    if (!this.submitButtonAvailable) return; // Make sure we can submit
    try {
      // Call central upload
      const sessionFileResult = await this.submitUploadRequest(
        this.sessionCreationFiles,
        undefined,
        this.sessionNameOrPrefix
      );
      // We assume that we only had one response and only want the first response session
      if (sessionFileResult) {
        const sessionId = sessionFileResult[0].sessionIds?.at(0);
        if (sessionId == null) throw new Error("Failed to process adding additional files to session.");
        // Upload remaining extra files
        await this.submitUploadRequest(this.currentExtraFiles, sessionId);
        this.dialogRef.close();
        // Display a pretty success message in the event all goes well
        const duration = formatDistance(0, sessionFileResult[0].timeElapsed, { includeSeconds: true });
        const successMessage =
          sessionFileResult[0].sessionIds != null && sessionFileResult[0].sessionIds?.length > 1
            ? `Successfully created ${sessionFileResult[0].sessionIds.length} sessions in ${duration}`
            : `Successfully created a new session in ${duration} ${
                this.currentExtraFiles.length > 0
                  ? `with ${this.currentExtraFiles.length} extra ${
                      this.currentExtraFiles.length > 1 ? "files" : "file"
                    }`
                  : ""
              }`;
        this.notificationService.open(successMessage, "success");
      }
    } catch {}
  }
}
