import { BreakpointObserver } from "@angular/cdk/layout";
import { Component, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatDrawer, MatDrawerMode } from "@angular/material/sidenav";
import {
  BackgroundAudioComponent,
  BG_AUDIO_INJECTION,
} from "@tdms/frontend/modules/audio/components/background-audio/background-audio.component";
import { startCase } from "lodash-es";
import "reflect-metadata";
import { WebSocketService } from "../communication/services/websocket.service";
import { Route_URLs } from "../routing/models/url";
import { RouterService } from "../routing/services/router.service";
import { ConfigService } from "../settings/services/config.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  providers: [
    {
      provide: BG_AUDIO_INJECTION,
      useFactory: (core: AppComponent) => core.bgAudioPlayer,
      deps: [AppComponent],
    },
  ],
})
export class AppComponent {
  /** Grab the background audio component so we can provide it as an injectable capability */
  @ViewChild(BackgroundAudioComponent) bgAudioPlayer!: BackgroundAudioComponent;

  @ViewChild("drawer")
  sidenav!: MatDrawer;

  menuButtonShown!: boolean;
  drawerShown!: boolean;
  drawerMode!: MatDrawerMode;

  constructor(
    public routerService: RouterService,
    public wsService: WebSocketService,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    public configService: ConfigService
  ) {
    // Auto close modals when connection status changes
    this.wsService.connectedStatusChange.subscribe((x) => {
      if (!x) this.dialog.closeAll();
    });

    this.onBreakpointChanged = this.onBreakpointChanged.bind(this);
    this.onTabletMode = this.onTabletMode.bind(this);
    this.onDesktopMode = this.onDesktopMode.bind(this);
    this.onMenuButtonClicked = this.onMenuButtonClicked.bind(this);

    this.breakpointObserver.observe(["(max-width: 1025px)", "(min-width: 1026px)"]).subscribe(this.onBreakpointChanged);
    this.onBreakpointChanged();
  }

  onBreakpointChanged() {
    if (this.breakpointObserver.isMatched("(min-width: 1026px)")) {
      this.onDesktopMode();
    } else {
      this.onTabletMode();
    }
  }

  onTabletMode() {
    this.drawerMode = "over";
    this.menuButtonShown = true;
    this.drawerShown = false;
  }

  onDesktopMode() {
    this.drawerMode = "side";
    this.menuButtonShown = false;
    this.drawerShown = true;
  }

  onMenuButtonClicked() {
    this.sidenav.toggle();
  }

  /**
   * Returns our current route to display
   */
  get currentRoute() {
    return startCase(this.routerService.currentRouteBaseUrl);
  }

  /**
   * Returns if the navigation menus should be rendered or not
   */
  get shouldRenderNav() {
    return !([Route_URLs[Route_URLs.login]] as string[]).includes(this.routerService.baseUrlFromWindow || "");
  }
}
