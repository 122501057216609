import { SelectionModel } from "@angular/cdk/collections";
import {
  Component,
  ContentChild,
  ContentChildren,
  Directive,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
} from "@angular/core";
import { SortDirection } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { CustomTypes } from "@tdms/common";
import { DatalessColumn, GenericTableColumn } from "@tdms/frontend/modules/shared/components/tables/models";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";

/** This type defines the required information we wish to display in the table. It adds additional fields that could be populated for more info. */
export type TableRequiredObject = Object & {
  /** Used to display a spinning wheel overtop of the table row in the event the value isn't ready to be selected */
  isProcessing?: boolean;
};

@Directive({ selector: "cell-display[columns]" })
export class CellDisplay {
  @Input() columns!: Array<string>;
  @ContentChild("display") displayTemplateRef!: TemplateRef<any> | null;
}

/**
 * This is a dummy base component that all generic tables should override from.
 * It exposes all the shared input elements that these tables use/need, and reduces code duplication/verbosity.
 * It does nothing on its own.
 * We need this since the editable-table does not want to extend from the generic-table directly, since it overrides several of it's input
 * fields and thus does not want them exposed to consumers.
 */
@Component({ template: "" })
export class TableBaseComponent<T extends TableRequiredObject> extends SubscribingComponent {
  /**
   * The name of this table. Used primarily for exporting and other identification.
   * @default "Generic Table"
   */
  @Input() tableName = "Generic Table";

  /**
   * This is the supported data that should be displayed in this table.
   */
  @Input() data!: T[] | undefined | null;

  /**
   * Our local table data source for more control over the given data
   */
  dataSource = new MatTableDataSource<T>();

  /** The columns to display on our table that the developer has chosen */
  @Input() displayedColumns!: GenericTableColumn[];

  /**
   * If the filter box should appear in this table or not
   */
  @Input() filterable: boolean = true;

  /**
   * If given, will add an add button to the table to add new rows
   */
  @Input() addRowCallback: { (): void } | undefined;

  /**
   * If given, allows the rows to be clicked and fire this callback
   */
  @Input() clickCallback: { (data: T): void } | undefined;

  /**
   * The value in the filter input
   */
  filterValue: string = "";

  /**
   * The field name that we are currently filtering on from filterableFields
   */
  filterOn!: CustomTypes.PropertyNames<T, any>;

  /**
   * Fields that are searchable for the dropdown.
   *
   * Takes displayed columns by default and turns them into this object
   */
  @Input() filterableFields: { value: string; viewValue: string }[] | undefined = undefined;

  /**
   * Reference to the cell template so the developer can override it
   */
  @ContentChildren(CellDisplay) cellDisplayRefs!: QueryList<CellDisplay>;

  /**
   * The sort header to start with
   */
  @Input() defaultSortHeader: CustomTypes.PropertyNames<T, any> | undefined;

  /**
   * The default direction to perform our sorting
   */
  @Input() defaultHeaderSortDirection: SortDirection = "desc";

  /**
   * The paginator page size to use
   * @default 10
   */
  @Input() paginatorPageSize: number = 10;

  /**
   * This option allows you to customize your own page size options for the user to select.
   *
   * Note: If you use this, you should probably turn off {@link shouldGuessPaginatorPageSize} or these values will not work.
   * @default undefined Which means we use {@link paginatorPageSize} by default.
   */
  @Input() paginatorPageSizeOptions: number[] | undefined = undefined;

  /**
   * If we should attempt to guess how many rows we can fit per page to max out the size of
   *  it's current container. Disabling this may result in a larger table than it's container
   * @default true
   */
  @Input() shouldGuessPaginatorPageSize = true;

  /**
   * If the first last buttons should be shown on the paginator, if enabled.
   * @default true
   */
  @Input() showFirstLastButtons = true;

  /**
   * Controls if the export button should be displayed in the table so the user can export this data to
   *  a .csv format.
   * @default true
   */
  @Input() exportEnabled = true;

  /**
   * The height of each mat row used to guess paginator sizing for table. In pixels.
   */
  @Input() matRowHeight = 50;

  /**
   * How wide the table is in pixels
   */
  protected tableWidth: number = 0;

  /** How tall the total container is in pixels */
  protected containerHeight: number = 0;

  /**
   * This input allows you to add selection boxes to tables for executing mass functionality against. Passing a function here
   *  will cause the selection boxes to appear.
   */
  @Input() selectionUpdate: { (currSelection: T[]): void } | undefined;

  /** Selection model for use with the selection boxes */
  selection = new SelectionModel<T>(true, []);

  /** The column information for displaying the selection boxes */
  selectionColumn = new DatalessColumn("select", "");

  /** Tracks what rows should be considered "active". This is not a required value */
  @Input() activeRows: T[] | undefined;

  /** If the pagination buttons are enabled. */
  @Input() paginationButtonsEnabled = true;

  /** In the event {@link paginationButtonsEnabled} is false, this tooltip will be displayed as to why. */
  @Input() paginationDisabledReasoning: string | undefined;

  /** Whenever a user attempts to scroll when the table is scrollable, this callback will be called. This will only fire on mouse wheel. */
  @Output() onScroll = new EventEmitter();
}
