<shared-div-progress-component
  [ready]="!isSearching"
  [notReadyMessage]="
    'Searching in progress, please wait... You will be automatically redirected when the search completes.'
  "
>
  <div class="home-page-container">
    <!-- Overall info card -->
    <mat-card class="total-info">
      <mat-card-content class="content">
        <!-- Logo -->
        <img class="str-logo" />
        <!-- File Info -->
        <div class="file-info">
          <span [matTooltip]="numberWithCommas(totalSpaceUsed) + ' MB'">
            <span class="title">Total Used Space:</span>
            {{ getSizeString() }}
          </span>
          <span><span class="title">Total Files:</span>{{ numberWithCommas(totalFiles, 0) }}</span>
        </div>
      </mat-card-content>
    </mat-card>
    <!-- Actual metric pie charts for total data -->
    <div class="metric-display">
      <metric-grid
        *ngIf="currentMetricDataStore"
        class="pie-chart-grid"
        [dataStore]="currentMetricDataStore"
      ></metric-grid>
    </div>
    <!-- Display querying capability -->
    <mat-card class="query-info">
      <mat-card-content class="content">
        <div class="instructions">Get started by running your query below</div>
        <query-bar [onSearchStarted]="onSearchStarted" [isSearching]="isSearching"></query-bar>
      </mat-card-content>
    </mat-card>
  </div>
</shared-div-progress-component>
